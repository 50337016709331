import moment from 'moment';
import { useContext } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import SelectButton from '../../components/Buttons/SelectButton';
import TerminierungContext, { IAvailableTimeslot } from '../../context/terminierungContext';
import { useAppointmentSelection } from '../../hooks/useAppointmentSelection';
import { setReplacedRoomForResource, setReplacedStartEndForResource } from '../../utils/appointmentUtils';
import { getGermanDateFormatFromString } from '../../utils/pcpUtils';

interface Props {
    selectedDay: string;
}

export const isTimeslotDateSet = (date: any) => {
    /* the default init date is a date type with 'new Date(0)' */
    if (date) {
        if (typeof date.start === 'string' && typeof date.end === 'string') {
            return true;
        }
    }

    return false;
};

const TimeSlots = (props: Props) => {
    const { tmstate } = useContext(TerminierungContext);
    const {
        getSelectedResource,
        setSelectedResource,
        setSelectedTimeslot,
        getAvailableTimeslots,
        getSelectedTimeslot,
    } = useAppointmentSelection();

    const getTime = (date: Date) => {
        return moment(date).format('HH:mm');
    };

    const clickedTimeslot = (timeslot: IAvailableTimeslot, supressDuration: boolean) => {
        // console.log('clicked timeslot: start: ', dateStart, ' end: ', dateEnd);
        const selectedTimeslot = {
            start: timeslot.timeslot.start,
            end: timeslot.timeslot.end,
            supressDuration: supressDuration,
        };

        // if (tmstate.workOnAppointmentNr > 1) {
        //     let updatedResource = setReplacedRoomForResource(tmstate.selectedResource_2, timeslot.replacedRoom);
        //     updatedResource = setReplacedStartEndForResource(updatedResource, selectedTimeslot);

        //     tmdispatch({
        //         type: 'SELECTTIMESLOT_2',
        //         selectedTimeslot_2: selectedTimeslot,
        //     });
        //     tmdispatch({
        //         type: 'SETRESOURCE_2',
        //         selectedResource_2: updatedResource,
        //     });
        // } else {
        //     let updatedResource = setReplacedRoomForResource(tmstate.selectedResource, timeslot.replacedRoom);
        //     updatedResource = setReplacedStartEndForResource(updatedResource, selectedTimeslot);

        //     tmdispatch({
        //         type: 'SELECTTIMESLOT',
        //         selectedTimeslot: selectedTimeslot,
        //     });
        //     tmdispatch({
        //         type: 'SETRESOURCE',
        //         selectedResource: updatedResource,
        //     });
        // }

        let updatedResource = setReplacedRoomForResource(
            getSelectedResource(tmstate.workOnAppointmentNr),
            timeslot.replacedRoom,
        );
        updatedResource = setReplacedStartEndForResource(updatedResource, selectedTimeslot);

        setSelectedTimeslot(tmstate.workOnAppointmentNr, selectedTimeslot);
        setSelectedResource(tmstate.workOnAppointmentNr, updatedResource);
    };

    const getTimeRange = (slot: IAvailableTimeslot, supressDuration: boolean) => {
        if (supressDuration) {
            return getTime(slot.timeslot.start);
        }
        return getTime(slot.timeslot.start) + ' - ' + getTime(slot.timeslot.end);
    };
    const displayAllTimeslots = () => {
        const availableTimeslots = getAvailableTimeslots(tmstate.workOnAppointmentNr);
        const selectedTimeslot = getSelectedTimeslot(tmstate.workOnAppointmentNr);
        if (availableTimeslots) {
            const allTimeslots = availableTimeslots.availableTimeslot.map((data: IAvailableTimeslot, index: number) => (
                <Col key={index}>
                    <SelectButton
                        className={['ml-3', 'mt-3']}
                        selected={selectedTimeslot?.start === data.timeslot.start}
                        height="80%"
                        width="100%"
                        variant="outline-primary"
                        block="true"
                        onClick={() => clickedTimeslot(data, availableTimeslots.supressDuration)}
                    >
                        {getTimeRange(data, availableTimeslots.supressDuration)}
                    </SelectButton>
                </Col>
            ));
            return allTimeslots;
        }

        return <></>;
    };

    // return <StyledButton>TimeSlot</StyledButton>;
    return (
        <div>
            <p>
                Verfügbare Zeiten für den{' '}
                {props.selectedDay === '' ? '' : <strong>{getGermanDateFormatFromString(props.selectedDay)}</strong>}
            </p>
            <Row xs={1} sm={1} md={2} lg={3} xl={3}>
                {displayAllTimeslots()}
            </Row>
        </div>
    );
};

export default TimeSlots;
