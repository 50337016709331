import { useContext, useEffect, useState } from 'react';
import { ThemeContext } from 'styled-components';
import { CategoryBadgeSelected } from '../../components/Badges/StyledBadge';
import { LeftDiv } from '../../components/Div/StyledDiv';
import PhysicianSelectionCard from '../../components/PhysicianSelectionCard/PhysicianSelectionCard';
import { ParagraphFontSize } from '../../components/StyledParagraph/StyledParagraph';
import { IDepartmentReasons } from '../../context/loginContext';
import TerminierungContext, { ISelectedPhysician } from '../../context/terminierungContext';
import { getPhysiciansFor } from '../../services/RestServices';

interface IAppointmentCategoryPhysician {
    department: string;
    reason: IDepartmentReasons;
    handlePhysicianClick(currentActiveStep: number): void;
    currentActiveStep: number;
}

const AppointmentCategoryPhysician = (props: IAppointmentCategoryPhysician) => {
    const themeContext = useContext(ThemeContext);
    const { tmstate, tmdispatch } = useContext(TerminierungContext);
    const [allPhysicians, setAllPhysicians] = useState<ISelectedPhysician[]>();

    useEffect(() => {
        const getPhysicians = async () => {
            const physiciansData = await getPhysiciansFor(tmstate);
            if (physiciansData?.physicians) {
                setAllPhysicians(physiciansData.physicians);
            } else {
                /* only earliest appointment possible, no physicians, so auto jump to next step */
                handleSetPhysicianClick(0, '', '', []);
            }
        };

        tmdispatch({
            type: 'RESETTIMESLOT',
        });

        getPhysicians();
    }, []);

    const handleSetPhysicianClick = (id: number, name: string, pict?: string, description?: string[]) => {
        tmdispatch({
            type: 'SELECTPHYSICIAN',
            selectedPhysician: {
                lines: description ? description : [],
                id: id ? id : 0,
                name: name ? name : '',
                pict: pict ? pict : '',
            },
        });
        props.handlePhysicianClick(props.currentActiveStep);
    };

    const allPhysicianCards = () => {
        const allCards: any[] = [];

        allCards.push(
            <div key="defaultPhyscianCard" onClick={() => handleSetPhysicianClick(0, '', '', [])}>
                <PhysicianSelectionCard name="Frühester Termin" showPicture={false} pict="" description={[]} />
            </div>,
        );

        allPhysicians?.forEach((e: ISelectedPhysician) => {
            let descrList: string[] = [];
            if (e.lines && typeof e.lines === 'string') {
                descrList.push(e.lines);
            } else if (e.lines) {
                descrList = e.lines;
            }
            allCards.push(
                <div key={e.id} onClick={() => handleSetPhysicianClick(e.id, e.name, e.pict, e.lines)}>
                    <PhysicianSelectionCard
                        name={e.name}
                        showPicture={true}
                        pict={e.pict ? e.pict : ''}
                        description={descrList}
                    />
                </div>,
            );
        });

        return allCards;
    };

    return (
        <LeftDiv marginLeft="100px">
            <h3>Behandler wählen</h3>
            <br />
            <div>
                <h4>
                    Fachrichtung
                    <CategoryBadgeSelected
                        $backgroundColor={themeContext.mainColor}
                        color="white"
                        $marginLeft="20px"
                        fontSize={ParagraphFontSize.Medium}
                    >
                        {props.department}
                    </CategoryBadgeSelected>
                </h4>
                <h4>
                    Besuchsgrund
                    <CategoryBadgeSelected
                        $backgroundColor={themeContext.mainColor}
                        color="white"
                        $marginLeft="20px"
                        fontSize={ParagraphFontSize.Medium}
                    >
                        {props.reason.reasonName}
                    </CategoryBadgeSelected>
                </h4>
            </div>
            <br />
            {allPhysicianCards()}
        </LeftDiv>
    );
};

export default AppointmentCategoryPhysician;
