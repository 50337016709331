import { useContext, useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { ThemeContext } from 'styled-components';
import { CategoryBadgeSelected } from '../../components/Badges/StyledBadge';
import { LeftDiv } from '../../components/Div/StyledDiv';
import { ParagraphFontSize } from '../../components/StyledParagraph/StyledParagraph';
import { IDepartments } from '../../context/loginContext';
import TerminierungContext from '../../context/terminierungContext';

interface IAppointmentCategoryReason {
    departments: IDepartments;
    handleReasonClick(): void;
}

const AppointmentCategoryReason = (props: IAppointmentCategoryReason) => {
    const themeContext = useContext(ThemeContext);
    const { tmdispatch } = useContext(TerminierungContext);
    const [reasonSelected, setReasonSelected] = useState<string>();
    const [insuranceSelected, setInsuranceSelected] = useState<string>();
    const [appTypeSelected, setAppTypeSelected] = useState<string>();

    useEffect(() => {
        if (
            reasonSelected &&
            reasonSelected.length > 0 &&
            insuranceSelected &&
            insuranceSelected.length > 0 &&
            appTypeSelected &&
            appTypeSelected.length > 0
        ) {
            const selectedReason = props.departments.reasons.find((reason) => reason.reasonName === reasonSelected);
            if (selectedReason) {
                tmdispatch({
                    type: 'SELECTREASON',
                    selectedReason: selectedReason,
                });
                tmdispatch({
                    type: 'SELECTINSURANCE',
                    selectedInsurance: insuranceSelected,
                });
                tmdispatch({
                    type: 'SELECTAPPOINTMENTTYPE',
                    selectedAppointmentType: appTypeSelected,
                });
            }
            props.handleReasonClick();
        }
    }, [reasonSelected, insuranceSelected, appTypeSelected]);

    const handleSetComboboxReason = (e: any) => {
        setReasonSelected(e.target.value.toString());
    };

    const handleSetComboboxInsurance = (e: any) => {
        setInsuranceSelected(e.target.value.toString());
    };

    const handleSetComboboxAppType = (e: any) => {
        setAppTypeSelected(e.target.value.toString());
    };

    const getVersicherungsartOptions = () => {
        if (props.departments.versicherungsArt && props.departments.versicherungsArt.length > 0) {
            return props.departments.versicherungsArt.map((versicherungsArt) => (
                <option key={versicherungsArt} value={versicherungsArt}>
                    {versicherungsArt}
                </option>
            ));
        } else {
            return (
                <>
                    <option key="gesetzlich" value="Gesetzlich">
                        Gesetzlich
                    </option>
                    <option key="privat" value="Privat">
                        Privat
                    </option>
                </>
            );
        }
    };

    const getKontaktartOptions = () => {
        if (props.departments.kontaktArt && props.departments.kontaktArt.length > 0) {
            return props.departments.kontaktArt.map((kontaktArt) => (
                <option key={kontaktArt} value={kontaktArt}>
                    {kontaktArt}
                </option>
            ));
        } else {
            return (
                <>
                    <option key="vorOrt" value="vor Ort">
                        vor Ort
                    </option>
                    <option key="video" value="per Video">
                        per Video
                    </option>
                    <option key="telefon" value="per Telefon">
                        per Telefon
                    </option>
                </>
            );
        }
    };

    const getBesuchsgrundOptions = () => {
        let filterVersicherungsart = '';
        if (insuranceSelected === 'Privat') {
            filterVersicherungsart = 'P';
        } else if (insuranceSelected === 'Gesetzlich') {
            filterVersicherungsart = 'K';
        }

        let filterTerminart = '';
        if (appTypeSelected === 'vor Ort') {
            filterTerminart = 'O';
        } else if (appTypeSelected === 'per Video') {
            filterTerminart = 'V';
        } else if (appTypeSelected === 'per Telefon') {
            filterTerminart = 'T';
        }

        const reasons = props.departments.reasons.map((reason) => {
            if (
                (reason.pFilter && !reason.pFilter.includes(filterVersicherungsart) && reason.pFilter !== '') ||
                (reason.aFilter && !reason.aFilter.includes(filterTerminart) && reason.aFilter !== '')
            ) {
                return null;
            } else {
                return (
                    <>
                        <option key={reason.reasonId} value={reason.reasonName}>
                            {reason.reasonName}
                        </option>
                    </>
                );
            }
        });

        return reasons;
    };

    return (
        <LeftDiv marginLeft="100px">
            <h3>Besuchsgrund wählen</h3>
            <br />
            <div>
                <h4>
                    Fachrichtung
                    <CategoryBadgeSelected
                        $backgroundColor={themeContext.mainColor}
                        color="white"
                        $marginLeft="20px"
                        fontSize={ParagraphFontSize.Medium}
                    >
                        {props.departments.name}
                    </CategoryBadgeSelected>
                </h4>
            </div>

            <br />
            <Row>
                <Col xs={12} sm={12} md={6}>
                    <Form.Row>
                        <Form.Label>
                            <b>Krankenversicherung</b>
                        </Form.Label>
                        <Form.Control
                            as="select"
                            name="insurance"
                            defaultValue=""
                            value={insuranceSelected}
                            onChange={(e) => handleSetComboboxInsurance(e)}
                        >
                            <option disabled key="insuranceDefault" value="">
                                Wählen Sie die Krankenversicherung
                            </option>
                            {getVersicherungsartOptions()}
                        </Form.Control>
                    </Form.Row>
                    <br />
                    <Form.Row>
                        <Form.Label>
                            <b>Terminart</b>
                        </Form.Label>
                        <Form.Control
                            as="select"
                            name="apptype"
                            defaultValue=""
                            value={appTypeSelected}
                            onChange={(e) => handleSetComboboxAppType(e)}
                        >
                            <option disabled key="appTypeDefault" value="">
                                Wählen Sie die Terminart
                            </option>
                            {getKontaktartOptions()}
                        </Form.Control>
                    </Form.Row>
                    <br />
                    <Form.Row>
                        <Form.Label>
                            <b>Besuchsgrund</b>
                        </Form.Label>
                        <Form.Control
                            as="select"
                            name="reason"
                            defaultValue=""
                            value={reasonSelected}
                            onChange={(e) => handleSetComboboxReason(e)}
                        >
                            <option disabled key="reasonDefault" value="">
                                Wählen Sie den Besuchsgrund
                            </option>
                            {getBesuchsgrundOptions()}
                        </Form.Control>
                    </Form.Row>
                </Col>
            </Row>
        </LeftDiv>
    );
};

export default AppointmentCategoryReason;
