import { Button, Card, Col, Image, Row } from 'react-bootstrap';
import { AiOutlineUser } from 'react-icons/ai';
import { StyledProfileCard } from '../Cards/StyledCard';

type IPhyscianSelectionCard = {
    name: string;
    description?: string[];
    showPicture: boolean;
    pict?: string;
};

const PhysicianSelectionCard = (props: IPhyscianSelectionCard) => {
    const descriptionList = () => {
        if (props.description && props.description.length > 0) {
            const allDescrItems: any[] = [];
            props.description?.forEach((e) => {
                allDescrItems.push(<li>{e}</li>);
            });

            return <ul>{allDescrItems}</ul>;
        }

        return null;
    };

    const physicianPhoto = () => {
        if (props.showPicture && props.pict) {
            return <Image src={'data:image/jpg;base64,' + props.pict} rounded width={150} />;
        } else if (props.showPicture && !props.pict) {
            return <AiOutlineUser size={80} />;
        } else {
            return null;
        }
    };

    return (
        <StyledProfileCard
            as={Button}
            style={{
                backgroundColor: 'white',
                color: 'black',
                width: '80%',
                marginBottom: '10px',
                marginTop: '0px',
                marginLeft: '0px',
            }}
        >
            <Card.Body style={{ textAlign: 'left' }}>
                <Card.Title>{props.name}</Card.Title>
                <Row>
                    <Col sm={2}>{physicianPhoto()}</Col>
                    <Col>{descriptionList()}</Col>
                </Row>
            </Card.Body>
        </StyledProfileCard>
    );
};

export default PhysicianSelectionCard;
