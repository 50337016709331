import { createContext } from 'react';
import { IAppointmentChat, IChat, IIncomingMessage } from '../components/Chat/Chat';

export type InitialVideochatType = {
    incomingCall: boolean;
    callAccepted: boolean;
    caller: string;
    remoteSid: string;
    localStream: any;
    remoteStream: any;
    iceServer: Array<string>;
    chatMessages: IIncomingMessage[];
    numberUnreadMessages: number;
    lastIncomingTextChatMsg: string;
    appointmentChat: IAppointmentChat[];
    lastIncomingAppChatMsg: IChat;
    audioMuted: boolean;
};

export const initialVideochatState = {
    incomingCall: false,
    callAccepted: false,
    caller: '',
    remoteSid: '',
    localStream: null,
    remoteStream: null,
    iceServer: [],
    chatMessages: [],
    numberUnreadMessages: 0,
    lastIncomingTextChatMsg: '',
    appointmentChat: [],
    lastIncomingAppChatMsg: {
        id: '',
        incomingMessages: [],
    },
    audioMuted: false,
};

const VideochatContext = createContext<{
    vsstate: InitialVideochatType;
    vsdispatch: React.Dispatch<any>;
}>({
    vsstate: initialVideochatState,
    vsdispatch: () => null,
});

export default VideochatContext;
