import Button from 'react-bootstrap/Button';
import ToggleButton from 'react-bootstrap/ToggleButton';
import styled from 'styled-components';

const SelectButton = styled(Button)`
    margin: 10px;
    padding: ${(props) => (props.padding ? props.padding : '.375rem .50rem')};
    height: ${(props) => (props.height ? props.height : '80px')};
    width: ${(props) => (props.width ? props.width : '300px')};
    color: rgb(36, 94, 173);
    background-color: ${(props) =>
        props.selected ? props.theme.selectButton.backgroundColorActive : props.theme.selectButton.backgroundColor};
    border-color: rgb(36, 94, 173);
    font-weight: normal;
    margin-left: 0;
    box-shadow: ${(props) => (props.theme.button.boxShadow ? props.theme.button.boxShadow : 'none')};
    border-radius: ${(props) => (props.theme.button.borderRadius ? props.theme.button.borderRadius : 'none')};

    &:hover {
        background-color: ${(props) => props.theme.selectButton.backgroundColorHover};
        border-color: ${(props) => props.theme.selectButton.backgroundColorHover};
        color: rgb(36, 94, 173);
    }
    &:active {
        background-color: ${(props) => props.theme.selectButton.backgroundColorActive} !important;
        border-color: ${(props) => props.theme.selectButton.backgroundColorActive} !important;
        color: rgb(36, 94, 173);
    }

    &.btn-outline-primary:not(:disabled):not(.disabled).active,
    .btn-outline-primary:not(:disabled):not(.disabled):active,
    .show > .btn-outline-primary.dropdown-toggle {
        color: black;
        background-color: white;
        border-color: lightblue;
    }
`;

export const SelectRadioToggleButton = styled(ToggleButton)`
    width: 180px;
`;

export default SelectButton;
