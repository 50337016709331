import Col from 'react-bootstrap/Col';
import styled from 'styled-components';

interface ICol {
    borderLeft?: string;
    paddingLeft?: string;
    paddingRight?: string;
    textAlign?: string;
    justifyContent?: string;
}

const StyledCol = styled(Col)<ICol>`
    border-left: ${(prop) => (prop.borderLeft ? prop.borderLeft : 'none')};
    padding-left: ${(prop) => (prop.paddingLeft ? prop.paddingLeft : '15px')};
    padding-right: ${(prop) => (prop.paddingRight ? prop.paddingRight : '15px')};
    text-align: ${(prop) => (prop.textAlign ? prop.textAlign : 'left')};
`;

export const MainHeaderCol = styled(Col)<ICol>`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`;

export const ChartsHeaderCol = styled(Col)<ICol>`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`;

export const MainHeaderButtonCol = styled(Col)<ICol>`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
`;

export const ChartsButtonCol = styled(Col)<ICol>`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: ${(prop) => (prop.justifyContent ? prop.justifyContent : 'flex-end')};
    width: 100%;
`;

export const AppointmentCardHeaderCol = styled(Col)<ICol>`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    width: 100%;
`;

export const AppointmentCardHeaderStatusButtonCol = styled(Col)<ICol>`
    justify-content: flex-end;
`;

export const AppointmentCardDateCol = styled(AppointmentCardHeaderCol)<ICol>`
    justify-content: left;
`;

export const AppointmentCardStatusCol = styled(AppointmentCardHeaderCol)<ICol>`
    padding: 0;
    justify-content: flex-start;
`;

export const AppointmentCardButtonCol = styled(AppointmentCardHeaderCol)<ICol>`
    padding: 0;
    justify-content: flex-end;
`;

export default StyledCol;
