import { useEffect, useRef } from 'react';
import styled from 'styled-components';

const StyledVideo = styled.video`
    width: ${(props) => (props.width ? props.width : '465px')};
    object-fit: contain;
`;

const Video = ({ ...props }) => {
    // const { vsstate } = useContext(VideochatContext);
    const videoRef = useRef<any>(null);
    const width = props.width;

    useEffect(() => {
        if (!videoRef) {
            return;
        }
        if (props.stream !== undefined) {
            videoRef.current.srcObject = props.stream;
            // videoRef.current.muted = vsstate.audioMuted;         /* activate when audioMute handle for videos should be done */
            videoRef.current.play();
        }
    }, [videoRef, props.stream !== undefined]);

    /* activate when audioMute handle for videos should be done */
    // useEffect(() => {
    //     if (videoRef && props.stream !== undefined) {
    //         videoRef.current.defaultMuted = vsstate.audioMuted;
    //         videoRef.current.muted = vsstate.audioMuted;
    //     }
    // }, [vsstate.audioMuted]);

    return (
        <div>
            <StyledVideo {...props} width={width} ref={videoRef} autoPlay playsInline></StyledVideo>
        </div>
    );
};

export default Video;
