import { Slider } from '@material-ui/core';
import moment from 'moment';
import 'moment-timezone';
import * as React from 'react';
import { useContext, useEffect, useRef, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/esm/Button';
import ButtonToolbar from 'react-bootstrap/esm/ButtonToolbar';
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger';
import Popover from 'react-bootstrap/esm/Popover';
import Tooltip from 'react-bootstrap/esm/Tooltip';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import { AiOutlineMinusCircle } from 'react-icons/ai';
import { BiCamera } from 'react-icons/bi';
import { FaRegSave } from 'react-icons/fa';
import { FiUpload } from 'react-icons/fi';
import { GrDocumentPdf } from 'react-icons/gr';
import { RiArrowLeftSLine, RiArrowRightSLine, RiTreasureMapFill } from 'react-icons/ri';
import Lottie from 'react-lottie';
import { useNavigate } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';
import StyledButton from '../../components/Buttons/StyledButton';
import {
    StyledResetButton,
    StyledToggleButton,
    ToggleButtonGroupFlex,
} from '../../components/Buttons/ToggleButtonGroupCst';
import { MainHeaderButtonCol, MainHeaderCol } from '../../components/Cols/StyledCol';
import { ImageDescDiv, ImageDiv, ImageFloatDiv, QImageDiv } from '../../components/Div/ImageDiv';
import {
    CenterDiv,
    LeftDiv,
    MainHeaderButtonIcon,
    RightDiv,
    StickyHeaderTitleDiv,
} from '../../components/Div/StyledDiv';
import { MainH2Title, MainH3Title, MainH4Title } from '../../components/Header/Header';
import IFramePDF from '../../components/IFrame/IFramePDF';
import ForeignQRModal from '../../components/Modals/ForeignQRModal/ForeignQRModal';
import PDFModal from '../../components/Modals/PDFModal/PDFModal';
import WebcamPreviewModal from '../../components/Modals/WebcamModal/WebcamPreviewModal';
import { MainHeaderRow } from '../../components/Rows/StyledRow';
import SignaturePad from '../../components/SignaturePad/SignaturePad';
import { ParagraphFontSize, StyledParagraph } from '../../components/StyledParagraph/StyledParagraph';
import ConfirmationToast from '../../components/Toast/ConfirmationToast';
import ErrorToast from '../../components/Toast/ErrorToast';
import MyTooltip from '../../components/Tooltip/MyTooltip';
import AlertContext, { AlertOnHide, AlertType } from '../../context/alertContext';
import LoginContext from '../../context/loginContext';
import QuestionnaireContext, {
    IQAnswers,
    IQBody,
    IQData,
    IQItem,
    IQItemChoiceOption,
    IQItemEnableWhen,
    IQOptionExtension,
    IQResource,
} from '../../context/questionnaireContext';
import TerminierungContext from '../../context/terminierungContext';
import ScanUtils, { BarcodeScanCallback } from '../../hooks/barcodeKeyHandler';
import { useLoadQuestionnaireAnswers } from '../../hooks/useLoadQuestionnaireAnswers';
import useScreenResolution from '../../hooks/useScreenResolution';
import checkAnimation from '../../lotties/checkAnimation.json';
import { Paths } from '../../Routes';
import { getDiagnosticReport } from '../../services/fhir/FHIRDiagnosticReports';
import {
    getQuestionnaireOTPAuth,
    sendQuestionnaire,
    updateQuestionnaireContent,
} from '../../services/fhir/FHIRQuestionnaire';
import { verifyCovidCert } from '../../services/RestServices';
import { showAlertMessage } from '../../utils/alertHandling';
import { getPDFDataFromDiagnosticReportResponse } from '../../utils/documentUtils';
import { findLongestWord } from '../../utils/generalUtils';
import { IPhoto } from '../../utils/photoUtils';
import {
    extensionSkipKA,
    getAllItems,
    getAndExpressionAsArray,
    getExtensionAndSubExpressions,
    getExtensionAppearanceValue,
    getExtensionClassValue,
    getExtensionDisplayType,
    getExtensionFieldNote,
    getExtensionImageDescAppearance,
    getExtensionImageHeight,
    getExtensionImageWidth,
    getExtensionLabel,
    getExtensionLabelKA,
    getExtensionLabelLeft,
    getExtensionLabelRight,
    getExtensionMaxValue,
    getExtensionMinValue,
    getExtensionPosition,
    getExtensionPossibleFileType,
    getExtensionPrecision,
    getExtensionPresetValue,
    getExtensionSize,
    getExtensionStepWidth,
    getExtensionTooltip,
    getNumberOfDrafts,
    getOptionExtensionSingleSelection,
    getPostStoreDocumentRefId,
    getPostStoreStartAppointmentSearch,
    getQuestionnaireDiagnosticReportFromRequest,
    getQuestionnaireResourceFromRequest,
    getQuestionnaireResponseResourceFromRequest,
    getRequiredItems,
    isDisableTempStorage,
    modifyTimeInput,
    roundDecimalInput,
} from '../../utils/questionnaireUtils';
import { isDesktop, isMobile, isTablet } from '../../utils/screenResolution';
import { IPDFModalShow } from '../Dokumente/Dokumente';
import Mainscreen from '../Mainscreen/Mainscreen';
import './FragebogenDetail.css';
import { IQuestionnaireWL } from './QuestionnaireWL';

export interface IQDiv {
    display?: string;
    float?: string;
}

interface IQCheckboxRow {
    marginLeft?: string;
}

interface IOption {
    code: string;
    display: string;
}

interface IDateTimeTemp {
    linkId: string;
    value: string;
}

const lottieCheck = {
    loop: false,
    autoplay: true,
    animationData: checkAnimation,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
};

const QDiv = styled(LeftDiv)<IQDiv>`
    margin-bottom: ${(prop) => (prop.marginBottom ? prop.marginBottom : '30px')};
    display: ${(prop) => (prop.display ? prop.display : 'block')};
    float: ${(prop) => (prop.float ? prop.float : null)};
`;

const QCheckboxRow = styled(Row)<IQCheckboxRow>`
    /* margin-left: ${(prop) => (prop.marginLeft ? prop.marginLeft : '20px')}; */
    margin-left: ${(prop) => (prop.marginLeft ? prop.marginLeft : '0px')};
`;

const RequiredAsterisk = (label?: string) => {
    return (
        <>
            {label} <span style={{ color: 'red' }}> *</span>
        </>
    );
};

function DeactivatedThumb(props: any) {
    return <span {...props} style={{ opacity: 0 }}></span>;
}

function ActivatedThumb(props: any) {
    return <span {...props}></span>;
}

export const calcInputWidth = (screenSize: { width: number }, maxLength: number) => {
    let width = '100%';

    if (isMobile(screenSize.width)) {
        if (maxLength <= 7) {
            width = '50%';
        } else {
            width = '100%';
        }
    } else if (isTablet(screenSize.width)) {
        if (maxLength <= 7) {
            width = '10%';
        } else if (maxLength <= 8) {
            width = '20%';
        } else if (maxLength <= 19) {
            width = '30%';
        } else if (maxLength <= 29) {
            width = '40%';
        } else if (maxLength <= 39) {
            width = '50%';
        } else if (maxLength <= 41) {
            width = '60%';
        } else if (maxLength <= 51) {
            width = '70%';
        } else if (maxLength <= 69) {
            width = '80%';
        } else if (maxLength <= 79) {
            width = '90%';
        } else {
            width = '100%';
        }
    } else {
        if (maxLength <= 7) {
            width = '10%';
        } else if (maxLength <= 16) {
            width = '20%';
        } else if (maxLength <= 27) {
            width = '30%';
        } else if (maxLength <= 37) {
            width = '40%';
        } else if (maxLength <= 47) {
            width = '50%';
        } else if (maxLength <= 57) {
            width = '60%';
        } else if (maxLength <= 67) {
            width = '70%';
        } else if (maxLength <= 77) {
            width = '80%';
        } else if (maxLength <= 87) {
            width = '90%';
        } else {
            width = '100%';
        }
    }
    return width;
};

const getAllOptionTextsAsArray = (itemOptions: IQItemChoiceOption[] | undefined) => {
    const allOptionTexts: string[] = [];
    if (itemOptions !== undefined) {
        itemOptions?.forEach((option: IQItemChoiceOption) => {
            if (option.valueCoding.display && option.valueCoding.display.length > 0) {
                allOptionTexts.push(option.valueCoding.display);
            }
        });
    }
    return allOptionTexts;
};

const buttonWidth = (screenSize: { width: number }, buttonText: string[]) => {
    let maxWordLength = 0;
    if (buttonText && buttonText.length > 0) {
        buttonText.forEach((text) => {
            const longestWord = findLongestWord(text);
            if (longestWord > maxWordLength) {
                maxWordLength = longestWord;
            }
        });
    }

    let buttonWidth = '120px';
    if (isTablet(screenSize.width)) {
        buttonWidth = '110px';
    }

    if (maxWordLength > 15) {
        buttonWidth = '200px';
    } else if (maxWordLength > 20) {
        buttonWidth = '250px';
    } else if (maxWordLength > 23) {
        buttonWidth = '280px';
    }

    return buttonWidth;
};

const FragebogenDetail = (props: IQuestionnaireWL) => {
    const NOINFO_CBX_VALUE = '-10000';

    interface IStepper {
        page: number;
        text: string;
        linkId: string;
    }

    interface IForeignQRModalShow {
        show: boolean;
        linkId: string | null;
        base64Arr: any[] | null;
        allValues: ICovidQRArray[];
    }

    const navigate = useNavigate();
    const themeContext = useContext(ThemeContext);
    const { alertdispatch } = useContext(AlertContext);
    const { qstate, qdispatch } = useContext(QuestionnaireContext);
    const { state } = useContext(LoginContext);
    const [steps, setSteps] = useState<IStepper[]>([
        {
            page: 0,
            text: '',
            linkId: '',
        },
    ]);
    const { tmdispatch } = useContext(TerminierungContext);
    const [actualStep, setActualStep] = useState(1);

    const [answers, setAnswers] = useState<IQAnswers[]>([]);

    const [clickedSubmit, setClickedSubmit] = useState(false);

    const [clickedSave, setClickedSave] = useState(false);

    const [questionnaireSendResponse, setQuestionnaireSendResponse] = useState('');

    const [showRequiredMessage, setShowRequiredMessage] = useState(false);

    const [scrollToRequiredMessage, setScrollToRequiredMessage] = useState(false);

    const [scrollToTop, setScrollToTop] = useState(false);

    const [isExternalQuest, setIsExternalQuest] = useState(false);

    const screenSize = useScreenResolution();

    const requiredMessageRef = useRef<HTMLDivElement>(null);
    const errorMessageRef = useRef<HTMLDivElement>(null);

    const topRef = useRef<HTMLDivElement>(null);

    const qDisplayBorderPadding = '5px';

    const [showSaveToast, setShowSaveToast] = useState(false);

    const [showErrorToast, setShowErrorToast] = useState(false);

    const [firstSave, setFirstSave] = useState(true);

    const [validate, setValidate] = useState(false);

    const [tempTime, setTempTime] = useState<IDateTimeTemp[]>([]);

    const [targetPatId, setTargetPatId] = useState(0);

    const [tempDateTimeDate, setTempDateTimeDate] = useState<IDateTimeTemp[]>([]);
    const [tempDateTimeTime, setTempDateTimeTime] = useState<IDateTimeTemp[]>([]);

    // const [disableTempStorage, setDisableTempStorage] = useState(false);

    const [postStoreDocumentRefId, setPostStoreDocumentRefId] = useState('');

    const [pdfModalShow, setPdfModalShow] = useState<IPDFModalShow>({
        show: false,
        modalTitle: '',
        pdfData: '',
        isSignable: false,
        diagnosticReportData: '',
    });

    const [showForeignQRModal, setShowForeignQRModal] = useState<IForeignQRModalShow>({
        show: false,
        allValues: [],
        base64Arr: [],
        linkId: '',
    });

    const [
        setSelectedQuestionnaire,
        setQuestionnaireBody,
        loadQuestionnaireAnswers,
        setSelectedQuestionnaireAnswers,
        setQuestionnaireData,
    ] = useLoadQuestionnaireAnswers();

    useEffect(() => {
        const getAnswers = () => {
            const tempAnswers = qstate.selectedAnswers.item;

            /* get all items from the questionnaire with the type "decimal" */
            let allItems: IQItem[] = [];
            allItems = getAllItems<IQItem>(qstate.selectedQuestionnaire.resource.item, allItems);
            const allDecItems = allItems.filter((item) => item.type === 'decimal');

            /* format all decimal answers with the given precision */
            for (let i = 0; i < allDecItems.length; i++) {
                let tempAnswer: IQAnswers;
                for (let j = 0; j < tempAnswers.length; j++) {
                    if (tempAnswers[j].linkId === allDecItems[i].linkId) {
                        tempAnswer = tempAnswers[j];
                        const tempValue = tempAnswer.answer[0].valueDecimal;
                        const precision = getExtensionPrecision(allDecItems[i]);
                        tempAnswers[j].answer[0].valueDecimal = parseFloat(tempValue).toFixed(precision);
                    }
                }
            }
            setAnswers(tempAnswers);
        };
        getAnswers();
    }, [qstate.selectedAnswers]);

    useEffect(() => {
        setTargetPatId(0);
        let bc;
        if (props.type === 'Questionnaire') {
            const otp = 'PCP ' + props.otp;
            const getExternalQuest = async () => {
                let dataLoaded = false;
                if (props.dataLoaded && props.dataLoaded === true) {
                    dataLoaded = true;
                }

                if (!dataLoaded) {
                    const response = await getQuestionnaireOTPAuth(props);
                    if (response) {
                        const rep: IQData = response.data;
                        setQuestionnaireData(rep);

                        const qResource = getQuestionnaireResourceFromRequest(rep);
                        const qResponse = getQuestionnaireResponseResourceFromRequest(rep);
                        const qDiagnosticReport = getQuestionnaireDiagnosticReportFromRequest(rep);

                        if (qDiagnosticReport) {
                            let modalTitle = '';
                            let pdfdata = '';
                            let pdfsrc = '';
                            const date = moment(qDiagnosticReport.effectiveDateTime).format('L LT');
                            const fileNameCoding = qDiagnosticReport.code.coding[0].display;
                            if (qDiagnosticReport.presentedForm) {
                                modalTitle = fileNameCoding + ' / ' + date;
                                pdfdata = qDiagnosticReport.presentedForm[0].data;
                                pdfsrc = 'data:application/pdf;base64,' + pdfdata;
                            } else {
                                modalTitle = 'Kein PDF zum anzeigen vorhanden';
                            }

                            setPdfModalShow({
                                show: true,
                                modalTitle: modalTitle,
                                // pdfData: <iframe src={pdfsrc} title="PDF" width="100%" height="600px"></iframe>,
                                pdfData: <IFramePDF src={pdfsrc} />,
                                diagnosticReportData: qDiagnosticReport,
                            });
                        }

                        if (qResponse !== undefined) {
                            setFirstSave(false);
                        }

                        setSelectedQuestionnaire(qResource);
                        setQuestionnaireBody(qResponse);
                        const answers = loadQuestionnaireAnswers(qResponse);
                        setSelectedQuestionnaireAnswers(answers);

                        qdispatch({
                            type: 'SETEXTERNALREQUESTDATA',
                            isExternalRequest: true,
                            externalUserId: props.userId,
                            externalOTP: otp,
                            externalT0: props.t0,
                            externalBaseURL: props.baseURL,
                        });

                        setIsExternalQuest(true);
                        setAllSteps(qResource);
                        if (props.name == 'VACCINATION_CERTIFICATE') {
                            bc = ScanUtils();
                            const mainDiv = document.getElementById('QuestionnaireMainId');
                            if (mainDiv) {
                                bc.startup(mainDiv, '', barcodeScannerCallback);
                            }
                        }
                    }
                } else {
                    qdispatch({
                        type: 'SETEXTERNALREQUESTDATA',
                        isExternalRequest: true,
                        externalUserId: props.userId,
                        externalOTP: otp,
                        externalT0: props.t0,
                        externalBaseURL: props.baseURL,
                    });

                    setIsExternalQuest(true);
                    setAllSteps();
                }
            };

            getExternalQuest();
        } else {
            setAllSteps();
        }
        return () => {
            if (bc) {
                bc.removeListeners(document.getElementById('QuestionnaireMainId'));
            }
        };
    }, [props]);

    useEffect(() => {
        async function sendQ() {
            if (qstate.selectedAnswers.item.length < 1) {
                /* don't submit empty questionnaire */
                setShowErrorToast(true);
            } else {
                if (
                    firstSave &&
                    // qstate.selectedQuestionnaire.resource.id === qstate.selectedQuestionnaire.resource.name &&
                    qstate.selectedQuestionnaire.resource.status !== 'in-progress'
                ) {
                    /* submit questionnaire without saving before (no unique questionnaire id yet) */
                    const response = await sendQuestionnaire(state, qstate, alertdispatch, 'completed', targetPatId);
                    let respJson: any = undefined;
                    if (response) {
                        let respStatus;
                        if (qstate.isExternalRequest) {
                            respJson = await response.json();
                            respStatus = respJson.status;
                        } else {
                            respJson = response;
                            respStatus = response.data?.status;
                        }
                        if (respStatus !== 'completed') {
                            let responseError = '';
                            if (response.data) {
                                if (response.data.issue) {
                                    response.data.issue.forEach((item: any) => {
                                        responseError += item.diagnostics;
                                    });
                                }
                            } else {
                                responseError = 'response.data == undefined';
                            }

                            setFirstSave(true);
                            setQuestionnaireSendResponse('ERROR: ' + responseError);
                        } else {
                            let success = true;
                            /* additional error check (when responseCode was 201) */
                            if (response.data?.issue) {
                                if (response.data.issue[0].severity) {
                                    if (response.data.issue[0].severity === 'error') {
                                        success = false;
                                        let responseError = '';
                                        if (response.data.issue[0].diagnostics) {
                                            responseError = response.data.issue[0].diagnostics;
                                        }
                                        setFirstSave(true);
                                        setQuestionnaireSendResponse('ERROR: ' + responseError);
                                    }
                                }
                            }

                            if (success) {
                                setFirstSave(false);
                                /* Poststore New Appointment With Makro */
                                const makroData = getPostStoreStartAppointmentSearch(respJson);
                                if (makroData.makroId > 0 && makroData.makroName.length > 0) {
                                    tmdispatch({
                                        type: 'SELECTCATEGORY',
                                        selectedCategory: {
                                            name: makroData.makroName,
                                            id: makroData.makroId,
                                            countAPKs: 1,
                                            oneMacro: true,
                                        },
                                    });
                                    navigate(Paths.TERMINIERUNG_ALTERNATE_DATE);
                                }

                                /* Poststore Doucment */
                                const documentId = getPostStoreDocumentRefId(respJson);
                                setPostStoreDocumentRefId(documentId);

                                setQuestionnaireSendResponse('SUCCESS');
                            }
                        }
                    }
                } else {
                    /* save questionnaire with unique id */
                    qstate.questionnaireBody.data.item = answers; /* update questionnaire with current answers */

                    let id = '';
                    if (qstate.questionnaireBody?.data?.id) {
                        id = qstate.questionnaireBody.data.id;
                    } else {
                        id = qstate.selectedQuestionnaire.resource.id;
                    }

                    qstate.questionnaireBody.data.id = id; /* set (short) unique questionnaire id for refresh */
                    qstate.questionnaireBody.data.status = 'completed';
                    const submitResp = await updateQuestionnaireContent(state, qstate, alertdispatch);
                    let respJson: any = undefined;
                    if (submitResp) {
                        let respStatus;
                        if (qstate.isExternalRequest) {
                            respJson = await submitResp.json();
                            respStatus = respJson.status;
                        } else {
                            respStatus = submitResp.data?.status;
                        }
                        if (respStatus !== 'completed') {
                            let responseError = '';
                            if (submitResp.data.issue) {
                                submitResp.data.issue.forEach((item: any) => {
                                    responseError += item.diagnostics;
                                });
                            }
                            setQuestionnaireSendResponse('ERROR: ' + responseError);
                        } else {
                            let success = true;
                            /* additional error check (when responseCode was 201) */
                            if (submitResp.data?.issue) {
                                if (submitResp.data.issue[0].severity) {
                                    if (submitResp.data.issue[0].severity === 'error') {
                                        success = false;
                                        let responseError = '';
                                        if (submitResp.data.issue[0].diagnostics) {
                                            responseError = submitResp.data.issue[0].diagnostics;
                                        }
                                        setQuestionnaireSendResponse('ERROR: ' + responseError);
                                    }
                                }
                            }

                            if (success) {
                                /* Poststore Doucment */
                                const documentId = getPostStoreDocumentRefId(respJson);
                                setPostStoreDocumentRefId(documentId);

                                setQuestionnaireSendResponse('SUCCESS');
                            }
                        }
                    }
                }
            }
        }

        if (clickedSubmit === true) {
            sendQ();
        }

        setClickedSubmit(false);
    }, [clickedSubmit === true]);

    useEffect(() => {
        async function saveQ() {
            // console.log('answers: ', answers);
            if (qstate.selectedAnswers.item.length < 1) {
                /* don't save empty questionnaire */
                setShowErrorToast(true);
            } else {
                /* checkForDraft (CheckInVariant) */
                let isDraft = false;
                if (qstate.selectedQuestionnaire.resource.extension) {
                    if (
                        qstate.selectedQuestionnaire.resource &&
                        getNumberOfDrafts(qstate.selectedQuestionnaire.resource) > 0
                    ) {
                        isDraft = true;
                    }
                }
                if (
                    firstSave &&
                    // qstate.selectedQuestionnaire.resource.id === qstate.selectedQuestionnaire.resource.name &&       //do we really need this? don't work when id and name are not the same
                    qstate.selectedQuestionnaire.resource.status !== 'in-progress' &&
                    !isDraft
                ) {
                    /* save questionnaire for first time (no unique questionnaire id yet) */
                    const firstSaveResp = await sendQuestionnaire(
                        state,
                        qstate,
                        alertdispatch,
                        'in-progress',
                        targetPatId,
                    );
                    if (firstSaveResp) {
                        if (firstSaveResp.status === 201) {
                            let success = true;
                            /* additional error check (when responseCode was 201) */
                            if (firstSaveResp.data?.issue) {
                                if (firstSaveResp.data.issue[0].severity) {
                                    if (firstSaveResp.data.issue[0].severity === 'error') {
                                        success = false;
                                        let responseError = '';
                                        if (firstSaveResp.data.issue[0].diagnostics) {
                                            responseError = firstSaveResp.data.issue[0].diagnostics;
                                        }
                                        setQuestionnaireSendResponse('ERROR: ' + responseError);
                                    }
                                }
                            }

                            if (success) {
                                setFirstSave(false);
                                let respId: string;
                                let respData: IQBody;
                                if (qstate.isExternalRequest) {
                                    const firstSaveRespJson = await firstSaveResp.json();
                                    respId = firstSaveRespJson.id;
                                    respData = firstSaveRespJson;
                                } else {
                                    respId = firstSaveResp.data.id;
                                    respData = firstSaveResp.data;
                                }
                                qstate.selectedQuestionnaire.resource.id = respId; /* set unique questionnaire id */
                                qstate.questionnaireBody.data = respData;
                                setShowSaveToast(true);

                                if (props.stepData?.handleQuestionnaireSave !== undefined) {
                                    props.stepData.handleQuestionnaireSave();
                                }
                            }
                        } else {
                            setQuestionnaireSendResponse('ERROR');
                        }
                    }
                } else {
                    /* update existing questionnaire */
                    qstate.questionnaireBody.data.item = answers; /* update questionnaire with current answers */
                    let id = '';
                    if (qstate.questionnaireBody?.data?.id) {
                        id = qstate.questionnaireBody.data.id;
                    } else {
                        id = qstate.selectedQuestionnaire.resource.id;
                    }
                    qstate.questionnaireBody.data.id = id; /* set (short) unique questionnaire id for refresh */
                    const updateResp = await updateQuestionnaireContent(state, qstate, alertdispatch);
                    if (updateResp) {
                        if (updateResp.status === 200) {
                            setShowSaveToast(true);
                            if (props.stepData?.handleQuestionnaireSave !== undefined) {
                                props.stepData.handleQuestionnaireSave();
                            }
                        } else {
                            setQuestionnaireSendResponse('ERROR');
                        }
                    }
                }
            }
        }

        if (clickedSave === true) {
            saveQ();
        }

        setClickedSave(false);
    }, [clickedSave === true]);

    useEffect(() => {
        scrollToRequiredMessageAlert();
        setScrollToRequiredMessage(false);
    }, [scrollToRequiredMessage === true]);

    useEffect(() => {
        scrollToTopOfPage();
        setScrollToTop(false);
    }, [scrollToTop === true]);

    const barcodeScannerCallback: BarcodeScanCallback = async (txt) => {
        console.log(txt);
        if (txt.startsWith('HC1')) {
            const verifyCovidCertResponse = await verifyCovidCert(state.sessionId, txt, 'hc1');
            if (!verifyCovidCertResponse || verifyCovidCertResponse.ERROR) {
                showAlertMessage({
                    alertTitle: 'QR Scan',
                    alertTxt: verifyCovidCertResponse?.ERROR
                        ? verifyCovidCertResponse.ERROR
                        : 'Keine Rückgabe von verifyCovidCert erhalten',
                    alertType: AlertType.error,
                    onHide: AlertOnHide.onlyClose,
                    alertdispatch: alertdispatch,
                });
            } else {
                covidCertValueSuccessWorkflow(verifyCovidCertResponse, [], null);
            }
        } else {
            showAlertMessage({
                alertTitle: 'Unbekannter Barcode',
                alertTxt: 'An dieser Stelle sind nur Überweisungen erlaubt.',
                alertType: AlertType.error,
                onHide: AlertOnHide.onlyClose,
                alertdispatch: alertdispatch,
            });
        }
    };

    const handleClosePDFExternalView = () => {
        if (props.isCheckin) {
            // window.location.reload();
            props.stepData?.handleQuestionnairePDFClose(props.stepData.activeStepNumber);
        } else {
            setPdfModalShow({ show: false, modalTitle: '', pdfData: '', isSignable: false, diagnosticReportData: '' });
            if (props.stepData) {
                props.stepData.handleQuestionnairePDFClose(props.stepData.activeStepNumber);
            } else {
                navigate(Paths.HOME);
            }
        }
    };

    const handleClosePDFInternal = () => {
        setPdfModalShow({
            show: false,
            modalTitle: '',
            pdfData: '',
            isSignable: false,
            isSigned: false,
            diagnosticReportData: '',
        });
        handleButtonFinish();
    };

    const handleCloseForeignQRModal = () => {
        setShowForeignQRModal({ show: false, allValues: [], base64Arr: [], linkId: '' });
    };

    const handleSubmitForeignQRModal = () => {
        setAnswerCovidQRValues(showForeignQRModal.linkId, showForeignQRModal.base64Arr, showForeignQRModal.allValues);
        setShowForeignQRModal({ show: false, allValues: [], base64Arr: [], linkId: '' });
    };

    const setAllSteps = (res?: any) => {
        let pageNr = 1;

        const allSteps: IStepper[] = [];

        let myRes: IQResource;
        if (res) {
            myRes = res;
        } else {
            myRes = qstate.selectedQuestionnaire.resource;
        }

        myRes.item.forEach((data: any) => {
            allSteps.push({
                page: pageNr,
                text: data.text,
                linkId: data.linkId,
            });
            pageNr++;
        });

        setSteps(allSteps);
    };

    const requiredCheck = (actualStep: number) => {
        let reqOk = true;

        qstate.selectedQuestionnaire.resource.item[actualStep - 1].item?.forEach((item: IQItem) => {
            let doCheck = false;
            if (item.required) {
                if (item.enableWhen) {
                    if (item.enableWhen.length > 1) {
                        let doMultiCheck = false;
                        for (let i = 0; i < item.enableWhen.length; i++) {
                            const questionLinkId = item.enableWhen[i].question;
                            if (isAnswerLinkIdPresent(questionLinkId).isPresent) {
                                if (answerEnableWhenCheck(item.enableWhen[i])) {
                                    doMultiCheck = true;
                                } else {
                                    doMultiCheck = false;
                                }
                            } else {
                                doMultiCheck = false;
                                break;
                            }

                            // const questionLinkId = item.enableWhen[i].question;
                            // if (isAnswerLinkIdPresent(questionLinkId).isPresent) {
                            //     if (getAnswerChoiceOption(questionLinkId) === item.enableWhen[i].answerCoding.code) {
                            //         doMultiCheck = true;
                            //     } else {
                            //         doMultiCheck = false;
                            //     }
                            // } else {
                            //     doMultiCheck = false;
                            //     break;
                            // }
                        }
                        doCheck = doMultiCheck;
                    } else {
                        if (answerEnableWhenCheck(item.enableWhen[0])) {
                            doCheck = true;
                        }
                        // const questionLinkId = item.enableWhen[0].question;
                        // if (isAnswerLinkIdPresent(questionLinkId).isPresent) {
                        //     const type = getTypeOfQuestionnaireItem(questionLinkId);
                        //     if (type === 'boolean') {
                        //         if (getAnswerBooleanOption(questionLinkId) === item.enableWhen[0].answerBoolean) {
                        //             doCheck = true;
                        //         }
                        //     } else {
                        //         if (getAnswerChoiceOption(questionLinkId) === item.enableWhen[0].answerCoding.code) {
                        //             doCheck = true;
                        //         }
                        //     }
                        // }
                    }
                } else {
                    doCheck = true;
                }
            }

            if (doCheck) {
                const linkId = item.linkId;
                let foundAnswer = false;
                for (let i = 0; i < answers.length; i++) {
                    if (answers[i].linkId === linkId) {
                        if (answers[i].answer.length > 0) {
                            foundAnswer = true;
                            break;
                        }
                    }
                }
                if (!foundAnswer) {
                    reqOk = false;
                }
            }
        });

        return reqOk;
    };

    const scrollToRequiredMessageAlert = () => {
        requiredMessageRef?.current?.scrollIntoView();
    };

    const scrollToTopOfPage = () => {
        topRef?.current?.scrollIntoView();
    };

    const handleNextStep = () => {
        if (actualStep + 1 <= steps.length) {
            if (requiredCheck(actualStep)) {
                if (!hasStepInvalidState(actualStep)) {
                    setActualStep(actualStep + 1);
                }
                setShowRequiredMessage(false);
                setValidate(false);
            } else {
                setShowRequiredMessage(true);
                setScrollToRequiredMessage(true);
                setValidate(true);
            }
        }
    };

    const handlePreviousStep = () => {
        if (actualStep - 1 >= 1) {
            setActualStep(actualStep - 1);
            setShowRequiredMessage(false);
            setValidate(false);
        }
    };

    const handleButtonCancel = () => {
        if (state.practitionerRoleId && state.activePatient) {
            if (qstate.callingPage.length > 0) {
                navigate(qstate.callingPage);
            } else {
                navigate(Paths.FRAGEBOGEN);
            }
        } else if (qstate.isExternalRequest) {
            window.location.reload();
        } else {
            if (qstate.callingPage.length > 0) {
                navigate(qstate.callingPage);
            } else {
                navigate(Paths.FRAGEBOGEN);
            }
        }
    };

    const handleButtonFinish = () => {
        if (state.practitionerRoleId && state.activePatient) {
            if (qstate.callingPage.length > 0) {
                navigate(qstate.callingPage);
            } else {
                navigate(Paths.FRAGEBOGEN);
            }
        } else if (qstate.isExternalRequest) {
            if (props.stepData) {
                /* if we are coming from a Stepper (e.g. CheckInWL) and want to set the step completed */
                props.stepData.handleQuestionnaireFinished(props.stepData.activeStepNumber);
            } else {
                navigate(Paths.HOME);
            }
        } else {
            if (qstate.callingPage.length > 0) {
                navigate(qstate.callingPage);
            } else {
                navigate(Paths.FRAGEBOGEN);
            }
        }
    };

    const handleButtonNextStep = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (e) {
            e.currentTarget.blur();
        }

        if (actualStep === steps.length) {
            if (requiredCheck(actualStep)) {
                if (!hasStepInvalidState(actualStep)) {
                    handleQSubmit();
                }
                setShowRequiredMessage(false);
                setScrollToTop(true);
            } else {
                setShowRequiredMessage(true);
                setScrollToRequiredMessage(true);
                setValidate(true);
            }
        }

        if (actualStep + 1 <= steps.length) {
            if (requiredCheck(actualStep)) {
                if (!hasStepInvalidState(actualStep)) {
                    setActualStep(actualStep + 1);
                }
                setShowRequiredMessage(false);
                setScrollToTop(true);
                setValidate(false);
            } else {
                setShowRequiredMessage(true);
                setValidate(true);
            }
        }
    };

    const handleButtonPreviousStep = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (e) {
            e.currentTarget.blur();
        }

        if (actualStep - 1 >= 1) {
            setActualStep(actualStep - 1);
            setShowRequiredMessage(false);
            setScrollToTop(true);
            setValidate(false);
        }
    };

    const handleQSubmit = () => {
        setSelectedQuestionnaireAnswers(answers);

        setClickedSubmit(true);
    };

    const handleQSave = () => {
        if (!hasStepInvalidState(actualStep)) {
            setSelectedQuestionnaireAnswers(answers);
            setClickedSave(true);
        } else {
            setShowErrorToast(true);
        }
    };

    const getStepTitle = (steps: IStepper[]) => {
        const stepTitle = steps[actualStep - 1].text;
        return stepTitle.split('#')[0];
    };

    const QStepper = () => {
        return (
            <>
                <Col>
                    <Nav.Item>
                        <Nav.Link disabled={actualStep === 1} onClick={() => handlePreviousStep()}>
                            <MyTooltip
                                id="goBackTooltip"
                                text="Zurück"
                                iconDiv={true}
                                divWidth="50px"
                                marginLeft="auto"
                                marginRight="0"
                                icon={<RiArrowLeftSLine size={50} />}
                            ></MyTooltip>
                        </Nav.Link>
                    </Nav.Item>
                </Col>
                <Col>
                    <StyledParagraph fontSize={ParagraphFontSize.Medium}>
                        {actualStep.toString()} / {steps.length}
                    </StyledParagraph>
                    <StyledParagraph fontSize={ParagraphFontSize.Big}>
                        <strong>{getStepTitle(steps)}</strong>
                    </StyledParagraph>
                </Col>
                <Col>
                    <Nav.Item>
                        <Nav.Link disabled={actualStep === steps.length} onClick={() => handleNextStep()}>
                            <MyTooltip
                                id="goForwardTooltip"
                                text="Weiter"
                                iconDiv={true}
                                divWidth="50px"
                                marginLeft="0"
                                marginRight="auto"
                                icon={<RiArrowRightSLine size={50} />}
                            ></MyTooltip>
                        </Nav.Link>
                    </Nav.Item>
                </Col>
            </>
        );
    };

    const setAnswerDateOption = (linkId: string, valueDate: string) => {
        if (valueDate !== '') {
            const lid = isAnswerLinkIdPresent(linkId);
            const answersCopy = [...answers];

            if (lid.isPresent) {
                answersCopy.splice(lid.index, 1);
            }

            answersCopy.push({
                linkId: linkId,
                answer: [
                    {
                        valueDate: valueDate,
                    },
                ],
            });

            setAnswers(answersCopy);
        } else {
            resetSelectedAnswer(linkId);
        }
    };

    const getAnswerDateOption = (linkId: string) => {
        const lid = isAnswerLinkIdPresent(linkId);

        if (lid.isPresent) {
            const valueDate = answers[lid.index].answer[0]?.valueDate;

            return valueDate;
        }
    };

    const setAnswerTimeOption = (linkId: string, valueTime: string) => {
        const templid = isTempTimeLinkIdPresent(linkId);

        const tempTimeCopy = [...tempTime];
        if (templid.isPresent) {
            tempTimeCopy.splice(templid.index, 1);
        }

        tempTimeCopy.push({
            linkId: linkId,
            value: valueTime.length > 5 ? valueTime.substring(0, 5) : valueTime /* use only hh:mm (without :ss) */,
        });
        setTempTime(tempTimeCopy);

        const lid = isAnswerLinkIdPresent(linkId);
        const answersCopy = [...answers];

        if (lid.isPresent) {
            answersCopy.splice(lid.index, 1);
        }

        if (valueTime.length === 5) {
            valueTime = valueTime + ':00'; /* add seconds for correct time format */
        }

        answersCopy.push({
            linkId: linkId,
            answer: [
                {
                    valueTime: valueTime,
                },
            ],
        });

        setAnswers(answersCopy);
    };

    const getAnswerTimeOption = (linkId: string) => {
        const lid = isAnswerLinkIdPresent(linkId);

        if (lid.isPresent) {
            const valueTime = answers[lid.index].answer[0]?.valueTime.substring(0, 5); /* return only hh:mm */

            return valueTime;
        }
    };

    const getTempDateTimeValue = (linkId: string, tempArrayObject: IDateTimeTemp[]) => {
        const tt = tempArrayObject.find((e) => e.linkId === linkId);
        if (tt != undefined) {
            return tt.value;
        }
    };

    const getTempTime = (linkId: string) => {
        return getTempDateTimeValue(linkId, tempTime);
    };

    const getTempDateTimeDate = (linkId: string) => {
        return getTempDateTimeValue(linkId, tempDateTimeDate);
    };

    const getTempDateTimeTime = (linkId: string) => {
        return getTempDateTimeValue(linkId, tempDateTimeTime);
    };

    const setAnswerDateTimeOption = (linkId: string, valueDateTimeDate: string, valueDateTimeTime: string) => {
        /* set temp values */

        const tempDateLinkId = isTempDateTimeDateLinkIdPresent(linkId);
        const tempDateTimeDateCopy = [...tempDateTimeDate];
        if (tempDateLinkId.isPresent) {
            tempDateTimeDateCopy.splice(tempDateLinkId.index, 1);
        }

        tempDateTimeDateCopy.push({
            linkId: linkId,
            value: valueDateTimeDate,
        });
        setTempDateTimeDate(tempDateTimeDateCopy);

        const tempTimeLinkId = isTempDateTimeTimeLinkIdPresent(linkId);
        const tempDateTimeTimeCopy = [...tempDateTimeTime];
        if (tempTimeLinkId.isPresent) {
            tempDateTimeTimeCopy.splice(tempTimeLinkId.index, 1);
        }

        tempDateTimeTimeCopy.push({
            linkId: linkId,
            value: valueDateTimeTime,
        });
        setTempDateTimeTime(tempDateTimeTimeCopy);

        /* set answers */
        if (valueDateTimeDate !== '' && valueDateTimeTime !== '') {
            const valueDateTime = moment.tz(valueDateTimeDate + ' ' + valueDateTimeTime, 'Europe/Vienna').format();

            if (moment(valueDateTime).isValid() && valueDateTime !== undefined) {
                const lid = isAnswerLinkIdPresent(linkId);
                const answersCopy = [...answers];

                if (lid.isPresent) {
                    answersCopy.splice(lid.index, 1);
                }

                answersCopy.push({
                    linkId: linkId,
                    answer: [
                        {
                            valueDateTime: valueDateTime,
                        },
                    ],
                });

                setAnswers(answersCopy);
                setValidity(linkId, true, true); /* is valid and complete when date and time are correct */
            } else {
                resetSelectedAnswer(linkId);
                setValidity(linkId, false, true); /* is invalid but complete when dateTime is invalid */
            }
        } else {
            resetSelectedAnswer(linkId);
            if (valueDateTimeDate === '' && valueDateTimeTime === '') {
                setValidity(linkId, true, true); /* is valid and complete when date and time are empty */
            } else {
                const timeInvalid = checkInvalidState(linkId); /* get validation of time format */
                setValidity(
                    linkId,
                    timeInvalid !== undefined ? !timeInvalid : false,
                    false,
                ); /* is incomplete when date or time is missing (and invalid when time format is invalid) */
            }
        }
    };

    const setValidity = (linkId: string, validStatus: boolean, completeStatus: boolean) => {
        const inputCheckCopy = inputCheck;
        let itemIndex = -1;

        inputCheckCopy.forEach((icItem, index) => {
            if (icItem.id === linkId) {
                itemIndex = index;
            }
        });

        if (itemIndex >= 0) {
            inputCheckCopy.splice(itemIndex, 1);
        }

        inputCheckCopy.push({ id: linkId, step: actualStep, inValid: !validStatus, inComplete: !completeStatus });
        setInputCheck(inputCheckCopy);
    };

    const handleAnswerDateTimeOption = (linkId: string, valueDateTimeTime: string) => {
        resetSelectedAnswer(linkId);
        setValidity(linkId, true, true);

        const tempTimeLinkId = isTempDateTimeTimeLinkIdPresent(linkId);
        const tempDateTimeTimeCopy = [...tempDateTimeTime];
        if (tempTimeLinkId.isPresent) {
            tempDateTimeTimeCopy.splice(tempTimeLinkId.index, 1);
        }

        tempDateTimeTimeCopy.push({
            linkId: linkId,
            value: valueDateTimeTime,
        });
        setTempDateTimeTime(tempDateTimeTimeCopy);
    };

    const handleAnswerTimeOption = (linkId: string, valueTime: string) => {
        resetSelectedAnswer(linkId);
        setValidity(linkId, true, true);
        const tempTimeCopy = [...tempTime];

        const templid = isTempTimeLinkIdPresent(linkId);

        if (templid.isPresent) {
            tempTimeCopy.splice(templid.index, 1);
        }

        tempTimeCopy.push({
            linkId: linkId,
            value: valueTime,
        });
        setTempTime(tempTimeCopy);
    };

    const getAnswerDateTimeOption = (linkId: string) => {
        const lid = isAnswerLinkIdPresent(linkId);

        if (lid.isPresent) {
            const valueDateTime = answers[lid.index].answer[0]?.valueDateTime;

            return valueDateTime;
        }
    };

    const getAnswerDateTimeDateOption = (linkId: string) => {
        let valueDateTimeDate = getAnswerDateTimeOption(linkId);

        valueDateTimeDate =
            valueDateTimeDate !== undefined && valueDateTimeDate !== '' /* return only YYYY-MM-DD */
                ? valueDateTimeDate.split('T')[0]
                : '';

        return valueDateTimeDate;
    };

    const getAnswerDateTimeTimeOption = (linkId: string) => {
        let valueDateTimeTime = getAnswerDateTimeOption(linkId);

        valueDateTimeTime =
            valueDateTimeTime !== undefined && valueDateTimeTime !== ''
                ? valueDateTimeTime.split('T')[1].substring(0, 5) /* return only hh:mm */
                : '';

        return valueDateTimeTime;
    };

    const setAnswerIntegerOption = (linkId: string, valueInteger: string) => {
        const lid = isAnswerLinkIdPresent(linkId);

        const answersCopy = [...answers];

        if (lid.isPresent) {
            answersCopy.splice(lid.index, 1);
        }

        answersCopy.push({
            linkId: linkId,
            answer: [
                {
                    valueInteger: valueInteger,
                },
            ],
        });

        setAnswers(answersCopy);
    };

    const getAnswerIntegerOption = (linkId: string) => {
        const lid = isAnswerLinkIdPresent(linkId);

        if (lid.isPresent) {
            const valueInt = answers[lid.index].answer[0]?.valueInteger;

            if (valueInt !== undefined) {
                const valueStr = valueInt.toString();
                return valueStr;
            } else {
                return '';
            }
        }
    };

    const getDotOrCommaForDecimalLocal = () => {
        let dotOrComma = '.';

        const testVal = '2.3';
        const testValNumber = parseFloat(testVal);
        const testValLocalString = testValNumber.toLocaleString();
        if (testValLocalString.includes(',')) {
            dotOrComma = ',';
        }

        return dotOrComma;
    };

    const setAnswerDecimalOption = (linkId: string, valueDecimal: string, setValueDecimalLocal: boolean) => {
        const lid = isAnswerLinkIdPresent(linkId);

        const answersCopy = [...answers];

        if (lid.isPresent) {
            answersCopy.splice(lid.index, 1);
        }

        if (valueDecimal && valueDecimal !== 'NaN') {
            let valueDecimalLocal = valueDecimal;
            if (setValueDecimalLocal) {
                try {
                    if (getDotOrCommaForDecimalLocal() === ',') {
                        valueDecimalLocal = valueDecimal.replace('.', ',');
                    }
                } catch (e) {
                    console.error('problems to convert decimal in localeString');
                }
            }

            answersCopy.push({
                linkId: linkId,
                answer: [
                    {
                        valueDecimal: valueDecimal,
                        valueDecimalLocal: valueDecimalLocal,
                    },
                ],
            });
        }

        setAnswers(answersCopy);
    };

    const convertToLocalString = (decimalVal: string, precision: number) => {
        if (decimalVal) {
            const decNumber = new Number(decimalVal).toLocaleString(undefined, {
                minimumFractionDigits: precision,
                maximumFractionDigits: precision,
            });
            return decNumber;
        } else {
            return '';
        }
    };

    const getAnswerDecimalOption = (linkId: string, getValueDecimalLocal: boolean) => {
        const lid = isAnswerLinkIdPresent(linkId);

        if (lid.isPresent) {
            let valueDec: any = '';
            if (getValueDecimalLocal) {
                const valueDecLocal = answers[lid.index].answer[0]?.valueDecimalLocal;
                if (valueDecLocal && valueDecLocal.length > 0) {
                    valueDec = valueDecLocal;
                } else {
                    valueDec = answers[lid.index].answer[0].valueDecimal;
                }
            } else {
                valueDec = answers[lid.index].answer[0].valueDecimal;
            }

            valueDec = valueDec === 'NaN' ? '' : valueDec;

            if (valueDec) {
                /* show as number with an comma */
                if (typeof valueDec === 'number') {
                    valueDec = valueDec.toString();
                }
                valueDec = valueDec.replace('.', ',');
            }

            return valueDec;
        }
    };

    const setAnswerTextOption = (linkId: string, valueString: string) => {
        if (valueString !== '') {
            const lid = isAnswerLinkIdPresent(linkId);

            const answersCopy = [...answers];
            if (lid.isPresent) {
                answersCopy.splice(lid.index, 1);
            }
            answersCopy.push({
                linkId: linkId,
                answer: [
                    {
                        valueString: valueString,
                    },
                ],
            });
            setAnswers(answersCopy);
        } else {
            resetSelectedAnswer(linkId);
        }
    };

    interface ICovidQRArray {
        linkId: string;
        valueString: string;
    }

    const setAnswerCovidQRValues = (
        pictureLinkId: string | null,
        pictureBase64Arr: any | null,
        idValueArray: ICovidQRArray[],
    ) => {
        const answersCopy = [...answers];
        if (answersCopy.length === 0) {
            answersCopy.push({ linkId: 'MULTICBX01', answer: [{ valueCoding: { code: '1' } }] });
        }

        /* Picture */
        if (pictureLinkId != null) {
            const lid = isAnswerLinkIdPresent(pictureLinkId);

            if (lid.isPresent) {
                answersCopy.splice(lid.index, 1);
            }

            const valueAttachmentArr: IValueAttachment[] = [];
            for (let i = 0; i < pictureBase64Arr.length; i++) {
                let contentType: string = pictureBase64Arr[i].split(';')[0];
                contentType = contentType.split('data:')[1];

                const attachment: IValueAttachment = {
                    valueAttachment: {
                        data: pictureBase64Arr[i],
                        contentType: contentType,
                    },
                };
                valueAttachmentArr.push(attachment);
            }

            answersCopy.push({
                linkId: pictureLinkId,
                answer: [valueAttachmentArr],
            });
        }

        /* QRCode values */
        idValueArray.forEach((e) => {
            if (e.valueString !== '') {
                const lid = isAnswerLinkIdPresent(e.linkId, answersCopy);

                if (lid.isPresent) {
                    answersCopy.splice(lid.index, 1);
                }
                answersCopy.push({
                    linkId: e.linkId,
                    answer: [
                        {
                            valueString: e.valueString,
                        },
                    ],
                });
            } else {
                resetSelectedAnswer(e.linkId);
            }
        });
        setAnswers(answersCopy);
    };

    const getAnswerTextOption = (linkId: string) => {
        const lid = isAnswerLinkIdPresent(linkId);

        if (lid.isPresent) {
            const valueStr = answers[lid.index].answer[0]?.valueString;

            return valueStr;
        }
    };

    const deleteAnswerEntryWithCopy = (linkId: string) => {
        const lid = isAnswerLinkIdPresent(linkId);

        const answersCopy = [...answers];

        if (lid.isPresent) {
            answersCopy.splice(lid.index, 1);
        }

        return answersCopy;
    };

    const resetSelectedAnswer = (linkId: string) => {
        const answersCopy = deleteAnswerEntryWithCopy(linkId);
        setAnswers(answersCopy);
    };

    const setAnswerChoiceOption = (
        linkId: string,
        code: string,
        display: string,
        extension: IQOptionExtension[] | undefined,
    ) => {
        const answersCopy = deleteAnswerEntryWithCopy(linkId);

        answersCopy.push({
            linkId: linkId,
            answer: [
                {
                    valueCoding: {
                        code: code,
                        display: display,
                        extension: extension,
                    },
                },
            ],
        });

        setAnswers(answersCopy);
    };

    const setAnswerDropdownOption = (item: any, e: any) => {
        let code = '';
        item.option.forEach((o: any) => {
            if (o.valueCoding.display === e.target.value.toString()) {
                code = o.valueCoding.code;
            }
        });
        if (code !== '') {
            setAnswerChoiceOption(item.linkId, code, e.target.value.toString(), undefined);
        } else {
            resetSelectedAnswer(item.linkId);
        }
    };

    const getAnswerBooleanOption = (linkId: string) => {
        const lid = isAnswerLinkIdPresent(linkId);

        if (lid.isPresent) {
            const valueBoolean = answers[lid.index].answer[0]?.valueBoolean;

            return valueBoolean;
        }
    };

    const setAnswerBooleanOption = (linkId: string, valueBoolean: any) => {
        const lid = isAnswerLinkIdPresent(linkId);

        const answersCopy = [...answers];

        if (valueBoolean === true) {
            if (lid.isPresent) {
                answersCopy.splice(lid.index, 1);
            }

            answersCopy.push({
                linkId: linkId,
                answer: [
                    {
                        valueBoolean: valueBoolean,
                    },
                ],
            });

            setAnswers(answersCopy);
        } else if (valueBoolean === false) {
            resetSelectedAnswer(linkId);
        }
    };

    interface IValueAttachment {
        valueAttachment: {
            data: string;
            contentType: string;
        };
    }

    const setAnswerAttachment = (linkId: string, valueBase64: any[]) => {
        const lid = isAnswerLinkIdPresent(linkId);

        const answersCopy = [...answers];

        if (lid.isPresent) {
            answersCopy.splice(lid.index, 1);
        }

        const valueAttachmentArr: IValueAttachment[] = [];
        for (let i = 0; i < valueBase64.length; i++) {
            const attachment: IValueAttachment = {
                valueAttachment: {
                    data: valueBase64[i],
                    contentType: 'image/png',
                },
            };
            valueAttachmentArr.push(attachment);
        }

        answersCopy.push({
            linkId: linkId,
            answer: [valueAttachmentArr],
        });

        setAnswers(answersCopy);
    };

    const getAnswerAttachment = (linkId: string) => {
        const lid = isAnswerLinkIdPresent(linkId);

        if (lid.isPresent) {
            let valueAttachment = answers[lid.index].answer[0]?.valueAttachment;
            if (valueAttachment === undefined) {
                valueAttachment = answers[lid.index].answer[0][0]?.valueAttachment; //zur Zeit darf es nur 1 Foto geben
            }

            return valueAttachment;
        }

        return undefined;
    };

    const setAnswerSignature = (linkId: string, valueBase64: string) => {
        const lid = isAnswerLinkIdPresent(linkId);

        const answersCopy = [...answers];

        if (lid.isPresent) {
            answersCopy.splice(lid.index, 1);
        }

        const valueAttachmentArr: IValueAttachment[] = [];
        const attachment: IValueAttachment = {
            valueAttachment: {
                data: valueBase64,
                contentType: 'image/svg+xml',
            },
        };
        valueAttachmentArr.push(attachment);

        answersCopy.push({
            linkId: linkId,
            answer: [valueAttachmentArr],
        });

        setAnswers(answersCopy);
    };

    // const getAnswerSignature = (linkId: string) => {
    //     const lid = isAnswerLinkIdPresent(linkId);

    //     if (lid.isPresent) {
    //         let valueAttachment = answers[lid.index].answer[0].valueAttachment;
    //         if (valueAttachment === undefined) {
    //             valueAttachment = answers[lid.index].answer[0][0].valueAttachment; //zur Zeit darf es nur 1 Foto geben
    //         }

    //         return valueAttachment;
    //     }

    //     return undefined;
    // };

    interface IAnswerOpenChoiceValues {
        singleSelectionAttr: boolean;
        valueCoding: {
            code: string;
            display: string;
        };
    }

    const getAnswerOpenChoiceOptions = (linkId: string) => {
        const lid = isAnswerLinkIdPresent(linkId);
        const valueCodingEntries: IAnswerOpenChoiceValues[] = [];

        if (lid.isPresent) {
            answers[lid.index].answer.forEach((valueCoding: IAnswerOpenChoiceValues) => {
                valueCodingEntries.push(valueCoding);
            });
        }
        return valueCodingEntries;
    };

    const setAnswerOpenChoiceOption = (
        linkId: string,
        code: string,
        display: string,
        isChecked: boolean,
        singleSelectionAttr: boolean,
        allOptions: IQItemChoiceOption[] | undefined,
    ) => {
        const answersCopy: IQAnswers[] = deleteAnswerEntryWithCopy(linkId);

        const currentValueCodingEntries = getAnswerOpenChoiceOptions(linkId);

        if (isChecked) {
            currentValueCodingEntries.push({
                singleSelectionAttr: singleSelectionAttr,
                valueCoding: {
                    code: code,
                    display: display,
                },
            });

            if (singleSelectionAttr) {
                /* if checkbox with an singleSelection modifierExtension is set, then all other options must be unset */
                for (let i = currentValueCodingEntries.length - 1; i >= 0; i--) {
                    if (currentValueCodingEntries[i].valueCoding.code !== code) {
                        currentValueCodingEntries.splice(i, 1);
                    }
                }
            } else {
                /* if checkbox without an singleSelection modifierExtension is set, then a option with an singleModifierExtension be unset */
                const allOptionsWithSingleSelection: string[] = [];
                if (allOptions !== undefined) {
                    allOptions.forEach((option) => {
                        if (getOptionExtensionSingleSelection(option)) {
                            allOptionsWithSingleSelection.push(option.valueCoding.code);
                        }
                    });
                }

                allOptionsWithSingleSelection.forEach((optionWithSingleSelection) => {
                    for (let i = currentValueCodingEntries.length - 1; i >= 0; i--) {
                        if (currentValueCodingEntries[i].valueCoding.code === optionWithSingleSelection) {
                            currentValueCodingEntries.splice(i, 1);
                        }
                    }
                });
            }
        } else {
            /* if checkbox is deselected, then delete from array */
            for (let i = 0; i < currentValueCodingEntries.length; i++) {
                if (currentValueCodingEntries[i].valueCoding.code === code) {
                    currentValueCodingEntries.splice(i, 1);
                    break;
                }
            }
        }

        answersCopy.push({
            linkId: linkId,
            answer: currentValueCodingEntries,
        });

        setAnswers(answersCopy);
    };

    const isAnswerOpenChoiceOptionSet = (linkId: string, code: string) => {
        const lid = isAnswerLinkIdPresent(linkId);

        if (lid.isPresent) {
            const valueCodingEntries = getAnswerOpenChoiceOptions(linkId);
            for (let i = 0; i < valueCodingEntries.length; i++) {
                if (valueCodingEntries[i].valueCoding?.code === code) {
                    return true;
                }
            }
        }

        return false;
    };

    const getAnswerChoiceOption = (linkId: string) => {
        const lid = isAnswerLinkIdPresent(linkId);

        if (lid.isPresent) {
            const valueCode = answers[lid.index].answer[0]?.valueCoding.code;
            return valueCode;
        }
    };

    const getAnswerChoiceOptionExtended = (linkId: string) => {
        const lid = isAnswerLinkIdPresent(linkId);

        if (lid.isPresent) {
            const valueCoding = answers[lid.index].answer[0]?.valueCoding;
            return valueCoding;
        }
    };

    const getAnswerDropdownOption = (linkId: string) => {
        const lid = isAnswerLinkIdPresent(linkId);

        if (lid.isPresent) {
            const valueDisplay = answers[lid.index].answer[0]?.valueCoding.display;
            return valueDisplay;
        }
    };

    const getQuestionnaireItem = (linkId: string) => {
        const lid = isAnswerLinkIdPresent(linkId);
        if (lid.isPresent) {
            for (let i = 0; i < qstate.selectedQuestionnaire.resource.item.length; i++) {
                for (let j = 0; j < qstate.selectedQuestionnaire.resource.item[i].item.length; j++) {
                    if (qstate.selectedQuestionnaire.resource.item[i].item[j].linkId === linkId) {
                        return qstate.selectedQuestionnaire.resource.item[i].item[j];
                    }
                }
            }
        }
    };

    const getTypeOfQuestionnaireItem = (linkId: string) => {
        const item = getQuestionnaireItem(linkId);
        if (item) {
            return item.type;
        }
    };

    const answerEnableWhenCheck = (itemEnableWhen: IQItemEnableWhen) => {
        const questionLinkId = itemEnableWhen.question;
        const type = getTypeOfQuestionnaireItem(questionLinkId);
        if (type !== undefined) {
            if (type === 'boolean') {
                if (getAnswerBooleanOption(questionLinkId) === itemEnableWhen.answerBoolean) {
                    return true;
                }
            } else {
                if (getAnswerChoiceOption(questionLinkId) === itemEnableWhen.answerCoding.code) {
                    return true;
                }
            }
        }

        return false;
    };

    interface ILinkIdPresent {
        isPresent: boolean;
        index: number;
    }

    /**
     * search for a answer in IQAnswers
     *
     * @param linkIdToFind
     *      the id to find
     * @param answersToSearch
     *      optional, an specific IQAnswer, otherwise the state answer
     * @returns
     *      isPresent and index if present
     */
    const isAnswerLinkIdPresent = (linkIdToFind: string, answersToSearch?: IQAnswers[]): ILinkIdPresent => {
        let answ = answers;
        if (answersToSearch) {
            answ = answersToSearch;
        }

        let linkIdPresent: ILinkIdPresent = {
            isPresent: false,
            index: 0,
        };

        for (let i = 0; i < answ.length; i++) {
            if (answ[i].linkId === linkIdToFind) {
                linkIdPresent = {
                    isPresent: true,
                    index: i,
                };
                break;
            }
        }

        return linkIdPresent;
    };

    const isTempLinkIdPresent = (linkIdToFind: string, tempArrayObject: IDateTimeTemp[]) => {
        let linkIdPresent: ILinkIdPresent = {
            isPresent: false,
            index: 0,
        };

        for (let i = 0; i < tempArrayObject.length; i++) {
            if (tempArrayObject[i].linkId === linkIdToFind) {
                linkIdPresent = {
                    isPresent: true,
                    index: i,
                };
                break;
            }
        }

        return linkIdPresent;
    };

    const isTempTimeLinkIdPresent = (linkIdToFind: string): ILinkIdPresent => {
        return isTempLinkIdPresent(linkIdToFind, tempTime);
    };

    const isTempDateTimeDateLinkIdPresent = (linkIdToFind: string): ILinkIdPresent => {
        return isTempLinkIdPresent(linkIdToFind, tempDateTimeDate);
    };

    const isTempDateTimeTimeLinkIdPresent = (linkIdToFind: string): ILinkIdPresent => {
        return isTempLinkIdPresent(linkIdToFind, tempDateTimeTime);
    };

    const isAnswerChoiceOptionSet = (linkIdToFind: string, selectedCode: string) => {
        let isSet = false;
        answers.forEach((data) => {
            if (data.linkId === linkIdToFind) {
                if (data.answer !== undefined) {
                    if (data?.answer[0].valueCoding.code === selectedCode) {
                        isSet = true;
                    }
                }
            }
        });

        return isSet;
    };

    const interpretPresetValue = (presetValue: string): any => {
        const commandSplit = presetValue.split(':');
        const command = commandSplit[0];
        const commandVals = commandSplit[1];
        if ('SUMWEIGHTS' === command) {
            let sum = 0;
            if (commandVals) {
                const commandValArr = commandVals.split(',');
                for (let i = 0; i < commandValArr.length; i++) {
                    const choiceAnswer = getAnswerChoiceOptionExtended(commandValArr[i]);
                    if (choiceAnswer) {
                        let answerVal = null;
                        for (let j = 0; j < choiceAnswer.extension?.length; j++) {
                            if (
                                choiceAnswer.extension[j].url?.startsWith('selWeight') &&
                                choiceAnswer.extension[j].valueInteger !== undefined
                            ) {
                                answerVal = choiceAnswer.extension[j].valueInteger;
                                break;
                            }
                        }
                        if (answerVal !== null) {
                            sum += answerVal;
                        } else {
                            return null;
                        }
                    } else {
                        return null;
                    }
                }

                return sum;
            }
        }
        return null;
    };

    /**
     * @id the linkId of the related question
     * Returns the saved answer of a related question for a conditional display.
     * Using the given id, the item is filtered from all existing items in order to use the item type for the appropriate request of the given answer.
     */
    function getCurrentAnswer(id: string) {
        let value: any = '';

        /* find the requested item by its id */
        let allItems: IQItem[] = [];
        allItems = getAllItems<IQItem>(qstate.selectedQuestionnaire.resource.item, allItems);
        const item = allItems.find((item) => item.linkId === id);

        if (item !== undefined) {
            if (item.type === 'choice') {
                let displayType = getExtensionClassValue(item);
                if (!displayType) {
                    displayType = getExtensionDisplayType(item);
                }
                if (displayType === 'radio' || displayType === 'button') {
                    value = getAnswerChoiceOption(id);
                } else if (displayType === 'dropdown') {
                    value = getAnswerDropdownOption(id);
                } else {
                    value = getAnswerChoiceOption(id);
                }
            } else if (item.type === 'boolean') {
                value = getAnswerBooleanOption(id) ? 'true' : 'false';
            } else if (item.type === 'open-choice') {
                value = getAnswerOpenChoiceOptions(id);
            }
        }
        return value;
    }

    const qEnableWhenHandling = (item: IQItem) => {
        let display = 'block';

        if (item.enableWhen) {
            const questionLinkIds = getRequiredItems(item).questionLinkIds;
            const requiredValues = getRequiredItems(item).requiredValues;

            if (requiredValues.length > 0) {
                const match: boolean[] = [];
                for (let i = 0; i < questionLinkIds.length; i++) {
                    const currentAnswer = getCurrentAnswer(questionLinkIds[i]);
                    const requiredAnswer = requiredValues[i];

                    if (Array.isArray(currentAnswer)) {
                        let foundMatchInArray = false;
                        currentAnswer.forEach((e) => {
                            if (e.valueCoding !== undefined) {
                                if (e.valueCoding.code === requiredAnswer) {
                                    foundMatchInArray = true;
                                }
                            }
                        });
                        if (foundMatchInArray) {
                            match.push(true);
                        }
                    } else {
                        match.push(currentAnswer === requiredAnswer);
                    }
                }

                let isTrue = match[0];
                const andExp = getAndExpressionAsArray(item); /* check if ALL conditions must match */

                if (andExp.length > 0) {
                    /* check if ALL conditions match (AND) */
                    for (let i = 0; i < andExp.length; i++) {
                        const index = parseInt(andExp[i]);
                        isTrue = isTrue && match[index]; /* if one match is false => isTrue = false */
                    }
                } else {
                    /* check if ANY condition matches (OR) */
                    for (let i = 0; i < match.length; i++) {
                        isTrue = isTrue || match[i]; /* if at minimum one match is true => isTrue = true */
                    }
                }

                if (isTrue) {
                    display = 'block';
                } else {
                    display = 'none';
                }
            } else {
                for (let i = 0; i < questionLinkIds.length; i++) {
                    const currentAnswer = getCurrentAnswer(questionLinkIds[i]);
                    if (currentAnswer !== undefined) {
                        display = 'block';
                    } else {
                        display = 'none';
                    }
                }
            }
            /* if the item is not displayed then it should be deleted from Answers too */
            if (display === 'none' && isAnswerLinkIdPresent(item.linkId).isPresent) {
                resetSelectedAnswer(item.linkId);
            }
        }
        return display;
    };

    const equals = (array1: string[], array2: string[]) => {
        return array1.length === array2.length && array1.every((v, i) => v === array2[i]);
    };

    const qMultiEnableWhenHandling = (item: IQItem) => {
        let display = 'block';

        if (item.enableWhen) {
            const questionLinkIds: string[] = [];
            for (let i = 0; i < item.enableWhen.length; i++) {
                questionLinkIds.push(item.enableWhen[i].question);
            }
            let allAnswerLinkIdsPresent = true;
            for (let i = 0; i < questionLinkIds.length; i++) {
                allAnswerLinkIdsPresent =
                    isAnswerLinkIdPresent(questionLinkIds[i]).isPresent && allAnswerLinkIdsPresent; // all must be true
            }
            if (allAnswerLinkIdsPresent) {
                const enableWhenAnswers: string[] = [];
                const currentAnswers: string[] = [];
                for (let i = 0; i < item.enableWhen.length; i++) {
                    enableWhenAnswers.push(item.enableWhen[i].answerCoding.code);
                    currentAnswers.push(getAnswerChoiceOption(questionLinkIds[i]));
                }
                if (equals(enableWhenAnswers, currentAnswers)) {
                    display = 'block';
                } else {
                    display = 'none';

                    /* if the item is not displayed then it should be deleted from Answers too */
                    if (isAnswerLinkIdPresent(item.linkId).isPresent) {
                        const answersCopy = deleteAnswerEntryWithCopy(item.linkId);
                        setAnswers(answersCopy);
                    }
                }
            } else {
                display = 'none';
            }
        }
        return display;
    };

    const requiredMessage = () => {
        if (showRequiredMessage) {
            return (
                <Alert variant="danger" ref={requiredMessageRef}>
                    <StyledParagraph fontSize={ParagraphFontSize.Big}>
                        Bitte füllen Sie alle mit Stern (*) markierten Pflichtfelder aus
                    </StyledParagraph>
                </Alert>
            );
        }
        return null;
    };

    const errorResponseMessage = (errorMsg: string) => {
        if (errorMsg !== null && errorMsg !== '') {
            return (
                <Alert variant="danger" ref={errorMessageRef}>
                    <StyledParagraph fontSize={ParagraphFontSize.Medium} color={'red'}>
                        {errorMsg}
                    </StyledParagraph>
                </Alert>
            );
        } else {
            return null;
        }
    };

    interface IInputCheck {
        id: string;
        step: number;
        inValid?: boolean;
        inComplete?: boolean;
    }
    const [inputCheck, setInputCheck] = useState<IInputCheck[]>([]);

    interface ICheckInput {
        linkId: string;
        type: string;
        input: string;
        regex?: any;
        precision?: number;
        minVal?: number;
        maxVal?: number;
    }

    const checkInput = (props: ICheckInput) => {
        let input = props.input;

        /* regEx check */
        let isValid = true;
        if (props.regex !== undefined && props.type === 'integer') {
            isValid = props.regex.test(input);
        } else if (props.type === 'decimal') {
            input = props.input.replace(',', '.');
            input = roundDecimalInput(input, props.precision).toFixed(props.precision).toString();
        } else if (props.type === 'time' || props.type === 'dateTimeTime') {
            input = modifyTimeInput(input);
            isValid = props.regex.test(input);
        }

        /* minVal check / maxVal check */
        let inputNumber: number | undefined = undefined;
        if (props.type === 'integer') {
            inputNumber = parseInt(input);
        } else if (props.type === 'decimal') {
            inputNumber = parseFloat(input);
        }

        if (isValid && inputNumber && props.minVal !== undefined) {
            if (inputNumber < props.minVal) {
                isValid = false;
            }
        }

        if (isValid && inputNumber && props.maxVal !== undefined) {
            if (inputNumber > props.maxVal) {
                isValid = false;
            }
        }

        setValidity(props.linkId, isValid, true);

        if (input === '' && props.type !== 'dateTimeTime') {
            resetSelectedAnswer(props.linkId);
        } else {
            setAnswerAfterCheck(props.linkId, input, props.type);
        }
    };

    const setAnswerAfterCheck = (linkId: string, input: string, type: string) => {
        if (type === 'decimal') {
            setAnswerDecimalOption(linkId, input, true);
        } else if (type === 'integer') {
            setAnswerIntegerOption(linkId, input);
        } else if (type === 'time') {
            setAnswerTimeOption(linkId, input);
        } else if (type === 'dateTimeTime') {
            let dateTimeDate = getTempDateTimeDate(linkId);
            if (dateTimeDate === undefined) {
                dateTimeDate = '';
            }
            setAnswerDateTimeOption(linkId, dateTimeDate, input);
        } else {
            setAnswerTextOption(linkId, input);
        }
    };

    const checkInvalidState = (linkId: string) => {
        const myItem = inputCheck.find((i) => i.id === linkId);
        const isInvalid = myItem !== undefined ? myItem.inValid : false;

        return isInvalid;
    };

    const hasStepInvalidState = (stepToCheck: number) => {
        let stepHasInvalidState = false;

        inputCheck.some((icItem) => {
            if (icItem.step === stepToCheck) {
                if (icItem.inValid || icItem.inComplete) {
                    stepHasInvalidState = true;
                    return RiTreasureMapFill;
                }
            }
        });

        return stepHasInvalidState;
    };

    const qTypeInteger = (item: IQItem) => {
        let minVal: number | undefined = undefined;
        let maxVal: number | undefined = undefined;

        minVal = getExtensionMinValue(item);
        maxVal = getExtensionMaxValue(item);

        const labelLeft = getExtensionLabelLeft(item);
        const labelRight = getExtensionLabelRight(item);

        let presetValue: number | undefined = undefined;
        const presetValueCmd = getExtensionPresetValue(item);
        if (presetValueCmd) {
            const _val = interpretPresetValue(presetValueCmd);
            if (Number.isInteger(_val)) {
                presetValue = _val;
            }
        }

        let isSlider = false;
        const displayType = getExtensionDisplayType(item);
        if (displayType === 'slider') {
            isSlider = true;
        }

        if (isSlider) {
            minVal = minVal ? minVal : 0;
            maxVal = maxVal ? maxVal : 10;

            const step = getExtensionStepWidth(item);
            const skipKA = extensionSkipKA(item);
            const labelKA = getExtensionLabelKA(item);

            const multiCol =
                qstate.selectedQuestionnaire.resource.extension &&
                qstate.selectedQuestionnaire.resource.extension[0].valueString === 'twoCol'
                    ? true
                    : false;

            const marks: any = [];

            if (marks.length < 1) {
                if (minVal !== undefined && maxVal !== undefined) {
                    for (let s = minVal; s <= maxVal; s = s + step) {
                        marks.push({ value: s, label: s + '' });
                    }
                }
            }

            const noInfoCheckBoxVals: INoInfoCheckbox = {
                item: item,
                labelKA: labelKA,
            };

            /* Red color if answer is required and there is no answer given and the "Weiter"-Button has been clicked (=validate) */
            const sliderColor =
                item.required && !isAnswerLinkIdPresent(item.linkId).isPresent && validate ? '#EC0035' : '#3c70c7';

            const value = getAnswerIntegerOption(item.linkId);
            return (
                <div key={item.linkId}>
                    <QDiv display={qEnableWhenHandling(item)}>
                        <div style={{ paddingLeft: qDisplayBorderPadding }}>
                            <LeftDiv>
                                <Row>
                                    <Col>
                                        <p>{item.required ? RequiredAsterisk(item.text) : item.text}</p>
                                    </Col>
                                </Row>
                                <div>
                                    <Row>
                                        <Col>
                                            <p>Wert: {value === NOINFO_CBX_VALUE ? labelKA : value}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        {labelLeft !== '' ? (
                                            <Col>
                                                {multiCol && screenSize.width < 1000 ? (
                                                    <LeftDiv>{labelLeft}</LeftDiv>
                                                ) : (
                                                    <RightDiv>{labelLeft}</RightDiv>
                                                )}
                                            </Col>
                                        ) : null}
                                        <Col
                                            xs={multiCol ? 12 : 4}
                                            md={multiCol ? 12 : 6}
                                            lg={multiCol ? 4 : 8}
                                            xl={multiCol ? 6 : 8}
                                        >
                                            <Slider
                                                ThumbComponent={
                                                    value && value !== NOINFO_CBX_VALUE
                                                        ? ActivatedThumb
                                                        : DeactivatedThumb
                                                }
                                                style={{ color: sliderColor }}
                                                defaultValue={minVal}
                                                value={value || '0'}
                                                aria-labelledby="discrete-slider-small-steps"
                                                step={step}
                                                marks={marks}
                                                min={minVal}
                                                max={maxVal}
                                                valueLabelDisplay="auto"
                                                onChange={(e, newVal) =>
                                                    setAnswerIntegerOption(item.linkId, newVal.toString())
                                                }
                                            />
                                        </Col>
                                        {labelRight !== '' ? (
                                            <Col>
                                                {multiCol && screenSize.width < 1000 ? (
                                                    <RightDiv>{labelRight}</RightDiv>
                                                ) : (
                                                    <LeftDiv>{labelRight}</LeftDiv>
                                                )}
                                            </Col>
                                        ) : (
                                            <Col></Col>
                                        )}
                                        {!skipKA ? (
                                            <Col xs={12}>
                                                <NoInfoCheckbox {...noInfoCheckBoxVals} />
                                            </Col>
                                        ) : null}
                                    </Row>
                                </div>
                            </LeftDiv>
                        </div>
                    </QDiv>
                </div>
            );
        } else {
            /* integer input */

            if (getAnswerIntegerOption(item.linkId) === undefined) {
                item?.initialInteger && setAnswerIntegerOption(item.linkId, item.initialInteger.toString());
            }

            let type = 'text';
            if (getExtensionClassValue(item) === 'updown') {
                type = 'number';
            }

            let feedbackMessage = 'Bitte eine ganze Zahl eingeben.';
            if (minVal !== undefined && maxVal !== undefined) {
                feedbackMessage = 'Bitte eine Zahl zwischen ' + minVal + ' und ' + maxVal + ' eingeben.';
            } else if (minVal !== undefined) {
                feedbackMessage = 'Bitte eine Zahl größer als ' + minVal + ' eingeben.';
            } else if (maxVal !== undefined) {
                feedbackMessage = 'Bitte eine Zahl kleiner als ' + maxVal + ' eingeben.';
            } else {
                feedbackMessage = 'Bitte eine ganze Zahl eingeben.';
            }

            const fieldWidthType = getExtensionAppearanceValue(item);
            const fieldWidth = fieldWidthType === 'medium' ? '180px' : '100px';
            const wrapperWidth = fieldWidthType === 'medium' ? '190px' : '110px';

            const regex: RegExp | string = /^(-?)[\d]*$/; /* positive and negative numbers, empty string and '-' */

            /* True if answer is required and there is no answer given and the "Weiter"-Button has been clicked (=validate) */
            const invalid = item.required && !isAnswerLinkIdPresent(item.linkId).isPresent && validate ? true : false;

            if (presetValue) {
                const currentSetAnswer = getAnswerIntegerOption(item.linkId);
                if (!currentSetAnswer || currentSetAnswer !== presetValue.toString()) {
                    checkInput({
                        linkId: item.linkId,
                        type: item.type,
                        input: presetValue.toString(),
                        regex: regex,
                        minVal: minVal,
                        maxVal: maxVal,
                    });
                }
            }

            return (
                <QDiv display={qEnableWhenHandling(item)}>
                    <div style={{ paddingLeft: qDisplayBorderPadding, paddingRight: qDisplayBorderPadding }}>
                        <Form.Group controlId={`formIntegerInput-${item.linkId}`}>
                            <Form.Label>{item.required ? RequiredAsterisk(item.text) : item.text}</Form.Label>
                            <div style={{ display: 'flex' }}>
                                {labelLeft !== '' ? (
                                    <LeftDiv style={{ paddingTop: '10px', paddingRight: '10px', position: 'relative' }}>
                                        {labelLeft}
                                    </LeftDiv>
                                ) : null}
                                <div style={{ width: wrapperWidth }}>
                                    <Form.Control
                                        style={{ width: fieldWidth }}
                                        required={item.required ? true : false}
                                        type={type}
                                        readOnly={item.readOnly ? item.readOnly : false}
                                        value={getAnswerIntegerOption(item.linkId) || ''}
                                        onChange={(e) =>
                                            checkInput({
                                                linkId: item.linkId,
                                                type: item.type,
                                                input: e.target.value.toString(),
                                                regex: regex,
                                                minVal: minVal,
                                                maxVal: maxVal,
                                            })
                                        }
                                        isInvalid={invalid || checkInvalidState(item.linkId)}
                                    />
                                    <Form.Control.Feedback
                                        style={{ width: '500px', overflowX: 'visible' }}
                                        type="invalid"
                                    >
                                        {feedbackMessage}
                                    </Form.Control.Feedback>
                                </div>
                                {labelRight !== '' ? (
                                    <LeftDiv style={{ paddingTop: '10px', position: 'relative' }}>{labelRight}</LeftDiv>
                                ) : null}
                            </div>
                        </Form.Group>
                    </div>
                </QDiv>
            );
        }
    };

    const decimalInput = (item: IQItem, precision: number, minVal?: number, maxVal?: number) => {
        const labelLeft = getExtensionLabelLeft(item);
        const labelRight = getExtensionLabelRight(item);
        let feedbackMessageRange = '';

        if (minVal !== undefined && maxVal !== undefined) {
            feedbackMessageRange =
                'Bitte eine Zahl zwischen ' +
                minVal.toFixed(precision) +
                ' und ' +
                maxVal.toFixed(precision) +
                ' eingeben.';
        } else if (minVal !== undefined) {
            feedbackMessageRange = 'Bitte eine Zahl größer als ' + minVal.toFixed(precision) + ' eingeben.';
        } else if (maxVal !== undefined) {
            feedbackMessageRange = 'Bitte eine Zahl kleiner als ' + maxVal.toFixed(precision) + ' eingeben.';
        } else {
            feedbackMessageRange = '';
        }

        const fieldWidthType = getExtensionAppearanceValue(item);
        const fieldWidth = fieldWidthType === 'medium' ? '180px' : '100px';
        const wrapperWidth = fieldWidthType === 'medium' ? '190px' : '110px';

        /* True if answer is required and there is no answer given and the "Weiter"-Button has been clicked (=validate) */
        const invalid = item.required && !isAnswerLinkIdPresent(item.linkId).isPresent && validate ? true : false;

        return (
            <QDiv display={qEnableWhenHandling(item)}>
                <div style={{ paddingLeft: qDisplayBorderPadding, paddingRight: qDisplayBorderPadding }}>
                    <Form.Group controlId={`formTextLong-${item.linkId}`}>
                        <Form.Label>{item.required ? RequiredAsterisk(item.text) : item.text}</Form.Label>
                        <div style={{ display: 'flex' }}>
                            {labelLeft !== '' ? (
                                <LeftDiv style={{ paddingTop: '10px', paddingRight: '10px', position: 'relative' }}>
                                    {labelLeft}
                                </LeftDiv>
                            ) : null}
                            <div style={{ width: wrapperWidth }}>
                                <Form.Control
                                    type="text"
                                    required={item.required ? true : false}
                                    style={{ width: fieldWidth }}
                                    value={getAnswerDecimalOption(item.linkId, true) || ''}
                                    onChange={(e) => setAnswerDecimalOption(item.linkId, e.target.value, false)}
                                    onBlur={(e: any) =>
                                        checkInput({
                                            linkId: item.linkId,
                                            type: item.type,
                                            input: e.target.value.toString(),
                                            precision: precision,
                                            minVal: minVal,
                                            maxVal: maxVal,
                                        })
                                    }
                                    isInvalid={invalid || checkInvalidState(item.linkId)}
                                />
                                <Form.Control.Feedback style={{ width: '500px', overflowX: 'visible' }} type="invalid">
                                    {feedbackMessageRange}
                                </Form.Control.Feedback>
                            </div>
                            {labelRight !== '' ? (
                                <LeftDiv style={{ paddingTop: '10px', position: 'relative' }}>{labelRight}</LeftDiv>
                            ) : null}
                        </div>
                    </Form.Group>
                </div>
            </QDiv>
        );
    };

    const qTypeDecimal = (item: IQItem) => {
        if (item?.extension || item?.modifierExtension) {
            let minVal: any = 0;
            let maxVal: any = 10;
            let precision = 1;
            minVal = getExtensionMinValue(item);
            maxVal = getExtensionMaxValue(item);
            precision = getExtensionPrecision(item);

            let sliderPrecisionCount = 0.1;
            const multiCol =
                qstate.selectedQuestionnaire.resource.extension &&
                qstate.selectedQuestionnaire.resource.extension[0].valueString === 'twoCol'
                    ? true
                    : false;

            let isSlider = false;
            const displayType = getExtensionDisplayType(item);
            if (displayType === 'slider') {
                isSlider = true;
            }

            if (isSlider) {
                const step = getExtensionStepWidth(item);
                const labelLeft = getExtensionLabelLeft(item);
                const labelRight = getExtensionLabelRight(item);
                const skipKA = extensionSkipKA(item);
                const labelKA = getExtensionLabelKA(item);
                const marks: any = [];

                if (precision) {
                    sliderPrecisionCount = 1 / Math.pow(10, precision);
                }

                if (marks.length < 1) {
                    for (let s = minVal; s <= maxVal; s = s + step) {
                        marks.push({ value: s, label: s + '' });
                    }
                }

                let value = getAnswerDecimalOption(item.linkId, false);
                if (!value) {
                    /* when set to k.A. then it is a integer */
                    value = getAnswerIntegerOption(item.linkId);
                }

                const noInfoCheckBoxVals: INoInfoCheckbox = {
                    item: item,
                    labelKA: labelKA,
                };

                /* Red color if answer is required and there is no answer given and the "Weiter"-Button has been clicked (=validate) */
                const sliderColor =
                    item.required && !isAnswerLinkIdPresent(item.linkId).isPresent && validate ? '#EC0035' : '#3c70c7';

                return (
                    <div key={item.linkId}>
                        <QDiv display={qEnableWhenHandling(item)}>
                            <div style={{ paddingLeft: qDisplayBorderPadding }}>
                                <LeftDiv>
                                    <Row>
                                        <Col>
                                            <p>{item.required ? RequiredAsterisk(item.text) : item.text}</p>
                                        </Col>
                                    </Row>
                                    <div
                                        style={{ paddingLeft: labelLeft !== '' && labelRight !== '' ? '0px' : '20px' }}
                                    >
                                        <Row>
                                            <Col>
                                                <p>
                                                    Wert:{' '}
                                                    {value === NOINFO_CBX_VALUE
                                                        ? labelKA
                                                        : convertToLocalString(value, precision)}
                                                </p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            {labelLeft !== '' ? (
                                                <Col>
                                                    {multiCol && screenSize.width < 1000 ? (
                                                        <LeftDiv>{labelLeft}</LeftDiv>
                                                    ) : (
                                                        <RightDiv>{labelLeft}</RightDiv>
                                                    )}
                                                </Col>
                                            ) : null}
                                            <Col
                                                xs={multiCol ? 12 : 4}
                                                md={multiCol ? 12 : 6}
                                                lg={multiCol ? 4 : 8}
                                                xl={multiCol ? 6 : 8}
                                            >
                                                <Slider
                                                    ThumbComponent={
                                                        value && value !== NOINFO_CBX_VALUE
                                                            ? ActivatedThumb
                                                            : DeactivatedThumb
                                                    }
                                                    style={{ color: sliderColor }}
                                                    value={value || '0'}
                                                    aria-labelledby="continuous-slider"
                                                    min={minVal}
                                                    max={maxVal}
                                                    step={sliderPrecisionCount}
                                                    marks={marks}
                                                    onChange={(e, newVal) =>
                                                        setAnswerDecimalOption(item.linkId, newVal.toString(), false)
                                                    }
                                                />
                                            </Col>
                                            {labelRight !== '' ? (
                                                <Col>
                                                    {multiCol && screenSize.width < 1000 ? (
                                                        <RightDiv>{labelRight}</RightDiv>
                                                    ) : (
                                                        <LeftDiv>{labelRight}</LeftDiv>
                                                    )}
                                                </Col>
                                            ) : null}
                                            {!skipKA ? (
                                                <Col xs={12}>
                                                    <NoInfoCheckbox {...noInfoCheckBoxVals} />
                                                </Col>
                                            ) : null}
                                        </Row>
                                    </div>
                                </LeftDiv>
                            </div>
                        </QDiv>
                    </div>
                );
            } else {
                return decimalInput(item, precision, minVal, maxVal);
            }
        } else {
            return decimalInput(item, 1);
        }
    };

    const qTypeText = (item: IQItem) => {
        let inputWidth = '100%';

        if (item.extension || item.modifierExtension) {
            const appearance = getExtensionAppearanceValue(item);
            if (appearance === 'small') {
                inputWidth = '25%';
            } else if (appearance === 'medium') {
                inputWidth = '50%';
            }
        }

        /* True if answer is required and there is no answer given and the "Weiter"-Button has been clicked (=validate) */
        const invalid = item.required && !isAnswerLinkIdPresent(item.linkId).isPresent && validate ? true : false;

        return (
            <QDiv key={item.linkId} display={qEnableWhenHandling(item)}>
                <div style={{ paddingLeft: qDisplayBorderPadding, paddingRight: qDisplayBorderPadding }}>
                    <Form.Group controlId={`formText-${item.linkId}`}>
                        <Form.Label>{item.required ? RequiredAsterisk(item.text) : item.text}</Form.Label>
                        <Form.Control
                            type="text"
                            required={item.required ? true : false}
                            as="textarea"
                            rows={3}
                            style={{ width: inputWidth }}
                            value={getAnswerTextOption(item.linkId)}
                            onChange={(e) => setAnswerTextOption(item.linkId, e.target.value.toString())}
                            isInvalid={invalid}
                        />
                    </Form.Group>
                </div>
            </QDiv>
        );
    };

    const qTypeString = (item: IQItem) => {
        let maxLengthStr = 524288;
        let inputWidth = '100%';
        if (item.maxLength) {
            maxLengthStr = item.maxLength;
            inputWidth = calcInputWidth(screenSize, maxLengthStr);
        }

        const appearance = getExtensionAppearanceValue(item);
        if (appearance === 'small') {
            inputWidth = '25%';
        } else if (appearance === 'medium') {
            inputWidth = '50%';
        } else if (appearance === 'large') {
            inputWidth = '100%';
        }

        /* True if answer is required and there is no answer given and the "Weiter"-Button has been clicked (=validate) */
        const invalid = item.required && !isAnswerLinkIdPresent(item.linkId).isPresent && validate ? true : false;

        return (
            <QDiv display={qEnableWhenHandling(item)} key={item.linkId}>
                <div style={{ paddingLeft: qDisplayBorderPadding, paddingRight: qDisplayBorderPadding }}>
                    <Form.Group controlId={`formTextLong-${item.linkId}`}>
                        <Form.Label>{item.required ? RequiredAsterisk(item.text) : item.text}</Form.Label>
                        <Form.Control
                            type="text"
                            readOnly={item.readOnly ? item.readOnly : false}
                            name={item.linkId}
                            required={item.required ? true : false}
                            style={{ width: inputWidth }}
                            value={getAnswerTextOption(item.linkId) || ''}
                            onChange={(e) => setAnswerTextOption(item.linkId, e.target.value.toString())}
                            isInvalid={invalid}
                        />
                    </Form.Group>
                </div>
            </QDiv>
        );
    };

    const qTypeDate = (item: IQItem) => {
        // if (tempDate === undefined) {
        //     let initialDate = item.initialDate ? item.initialDate : '';
        //     initialDate = getAnswerDateOption(item.linkId) ? getAnswerDateOption(item.linkId) : initialDate;
        //     setAnswerDateOption(item.linkId, initialDate);
        // }
        let dateVal = '';
        const initialDate = item.initialDate ? item.initialDate : '';
        if (initialDate && !getAnswerDateOption(item.linkId)) {
            dateVal = initialDate;
            setAnswerDateOption(item.linkId, dateVal);
        } else if (getAnswerDateOption(item.linkId)) {
            dateVal = getAnswerDateOption(item.linkId);
        }

        const labelRight = getExtensionLabelRight(item);
        const labelLeft = getExtensionLabelLeft(item);

        const appearance = getExtensionAppearanceValue(item);

        let showButton_twoDaysAgo = false;
        let showButton_yesterday = false;
        let showButton_today = false;
        let showButton_tomorow = false;
        let showButton_dayAfterTomorrow = false;

        if (appearance.includes('v')) {
            showButton_twoDaysAgo = true;
        }
        if (appearance.includes('g')) {
            showButton_yesterday = true;
        }
        if (appearance.includes('h')) {
            showButton_today = true;
        }
        if (appearance.includes('m')) {
            showButton_tomorow = true;
        }
        if (appearance.includes('u')) {
            showButton_dayAfterTomorrow = true;
        }

        return (
            <QDiv display={qEnableWhenHandling(item)} key={item.linkId}>
                <div style={{ paddingLeft: qDisplayBorderPadding, paddingRight: qDisplayBorderPadding }}>
                    <Form.Group controlId={`formGridQuestionnaireDate-${item.linkId}`}>
                        <Form.Label>{item.required ? RequiredAsterisk(item.text) : item.text}</Form.Label>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {labelLeft !== '' ? (
                                <LeftDiv style={{ paddingTop: '10px', paddingRight: '10px', position: 'relative' }}>
                                    {labelLeft}
                                </LeftDiv>
                            ) : null}
                            <div style={{ width: '210px' }}>
                                <Form.Control
                                    style={{ width: '200px' }}
                                    type="date"
                                    value={dateVal}
                                    onChange={(e) => setAnswerDateOption(item.linkId, e.target.value)}
                                />
                            </div>
                            {labelRight !== '' ? (
                                <LeftDiv style={{ paddingTop: '10px', position: 'relative' }}>{labelRight}</LeftDiv>
                            ) : null}
                            {showButton_twoDaysAgo && (
                                <div style={{ paddingLeft: '10px' }}>
                                    <Button
                                        variant="light"
                                        onClick={() =>
                                            setAnswerDateOption(
                                                item.linkId,
                                                moment().subtract(2, 'day').format('yyyy-MM-DD'),
                                            )
                                        }
                                    >
                                        Vorgestern
                                    </Button>
                                </div>
                            )}
                            {showButton_yesterday && (
                                <div style={{ paddingLeft: '10px' }}>
                                    <Button
                                        variant="light"
                                        onClick={() =>
                                            setAnswerDateOption(
                                                item.linkId,
                                                moment().subtract(1, 'day').format('yyyy-MM-DD'),
                                            )
                                        }
                                    >
                                        Gestern
                                    </Button>
                                </div>
                            )}
                            {showButton_today && (
                                <div style={{ paddingLeft: '10px' }}>
                                    <Button
                                        variant="light"
                                        onClick={() => setAnswerDateOption(item.linkId, moment().format('yyyy-MM-DD'))}
                                    >
                                        Heute
                                    </Button>
                                </div>
                            )}
                            {showButton_tomorow && (
                                <div style={{ paddingLeft: '10px' }}>
                                    <Button
                                        variant="light"
                                        onClick={() =>
                                            setAnswerDateOption(
                                                item.linkId,
                                                moment().add(1, 'day').format('yyyy-MM-DD'),
                                            )
                                        }
                                    >
                                        Morgen
                                    </Button>
                                </div>
                            )}
                            {showButton_dayAfterTomorrow && (
                                <div style={{ paddingLeft: '10px' }}>
                                    <Button
                                        variant="light"
                                        onClick={() =>
                                            setAnswerDateOption(
                                                item.linkId,
                                                moment().add(2, 'day').format('yyyy-MM-DD'),
                                            )
                                        }
                                    >
                                        Übermorgen
                                    </Button>
                                </div>
                            )}
                        </div>
                    </Form.Group>
                </div>
            </QDiv>
        );
    };

    const qTypeTime = (item: IQItem) => {
        if (getTempTime(item.linkId) === undefined) {
            let initialTime = item.initialTime ? item.initialTime : ''; /* get initialTime from item */
            initialTime = getAnswerTimeOption(item.linkId)
                ? getAnswerTimeOption(item.linkId) /* get saved time */
                : initialTime;

            setAnswerTimeOption(item.linkId, initialTime);
        }

        const timeRegex: RegExp | string =
            /^$|^(?:(?:([01]?\d|2[0-3]):)?([0-5]?\d))$/; /* HH:MM 00:00-23:59 or empty string */

        const labelRight = getExtensionLabelRight(item);
        const labelLeft = getExtensionLabelLeft(item);

        const appearance = getExtensionAppearanceValue(item);
        let showButton_Jetzt = false;
        let showButton_Zuruecksetzen = false;
        if (appearance.includes('j')) {
            showButton_Jetzt = true;
        }
        if (appearance.includes('z')) {
            showButton_Zuruecksetzen = true;
        }

        /* True if answer is required and there is no answer given and the "Weiter"-Button has been clicked (=validate) */
        // const invalid = item.required && !isAnswerLinkIdPresent(item.linkId).isPresent && validate ? true : false;
        let timeAnswerSet = false;
        const timeAnswer = getAnswerTimeOption(item.linkId);
        if (timeAnswer !== undefined && timeAnswer.length > 0) {
            timeAnswerSet = true;
        }
        const invalid = item.required && !timeAnswerSet && validate ? true : false;

        return (
            <QDiv display={qEnableWhenHandling(item)}>
                <div style={{ paddingLeft: qDisplayBorderPadding, paddingRight: qDisplayBorderPadding }}>
                    <Form.Group controlId={`formTimeInput-${item.linkId}`}>
                        <Form.Label>{item.required ? RequiredAsterisk(item.text) : item.text}</Form.Label>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {labelLeft !== '' ? (
                                <LeftDiv style={{ paddingTop: '10px', paddingRight: '10px', position: 'relative' }}>
                                    {labelLeft}
                                </LeftDiv>
                            ) : null}
                            <div style={{ width: '110px' }}>
                                <Form.Control
                                    style={{ width: '100px' }}
                                    required={item.required ? true : false}
                                    type="text" /* time */
                                    placeholder="hh:mm"
                                    value={getTempTime(item.linkId)}
                                    onChange={(e) =>
                                        e.target.value.toString() === 'j'
                                            ? handleAnswerTimeOption(item.linkId, moment().format('LT'))
                                            : handleAnswerTimeOption(item.linkId, e.target.value.toString())
                                    }
                                    onBlur={(e) =>
                                        checkInput({
                                            linkId: item.linkId,
                                            type: 'time',
                                            input: e.target.value.toString(),
                                            regex: timeRegex,
                                        })
                                    }
                                    isInvalid={invalid || checkInvalidState(item.linkId)}
                                />
                                <Form.Control.Feedback style={{ width: '500px', overflowX: 'visible' }} type="invalid">
                                    Ungültige Eingabe
                                </Form.Control.Feedback>
                            </div>
                            {labelRight !== '' ? (
                                <LeftDiv style={{ paddingTop: '10px', position: 'relative' }}>{labelRight}</LeftDiv>
                            ) : null}
                            {showButton_Jetzt && (
                                <div style={{ paddingLeft: '10px' }}>
                                    <Button
                                        variant="light"
                                        onClick={() => setAnswerTimeOption(item.linkId, moment().format('LT'))}
                                    >
                                        Jetzt
                                    </Button>
                                </div>
                            )}
                            {showButton_Zuruecksetzen && (
                                <div style={{ paddingLeft: '10px' }}>
                                    <Button variant="light" onClick={() => setAnswerTimeOption(item.linkId, '')}>
                                        Zurücksetzen
                                    </Button>
                                </div>
                            )}
                        </div>
                    </Form.Group>
                </div>
            </QDiv>
        );
    };

    const qTypeDateTime = (item: IQItem) => {
        /* set initial dateTime */
        if (getTempDateTimeDate(item.linkId) === undefined && getTempDateTimeTime(item.linkId) === undefined) {
            let initDateTimeDate = '';
            let initDateTimeTime = '';
            /* get initialDateTime from item */
            if (item.initialDateTime) {
                initDateTimeDate = item.initialDateTime.split('T')[0]; /* only return YYYY-MM-DD */
                initDateTimeTime = item.initialDateTime.split('T')[1].substring(0, 5); /* only return hh:mm */
            }
            /* get saved dateTime when reopening */
            if (getAnswerDateTimeDateOption(item.linkId) !== '' && getAnswerDateTimeTimeOption(item.linkId) !== '') {
                initDateTimeDate = getAnswerDateTimeDateOption(item.linkId);
                initDateTimeTime = getAnswerDateTimeTimeOption(item.linkId);
            }

            setAnswerDateTimeOption(item.linkId, initDateTimeDate, initDateTimeTime);
        }

        const timeRegex: RegExp | string =
            /^$|^(?:(?:([01]?\d|2[0-3]):)?([0-5]?\d))$/; /* HH:MM 00:00-23:59 or empty string */

        const labelRight = getExtensionLabelRight(item);
        const labelLeft = getExtensionLabelLeft(item);

        const appearance = getExtensionAppearanceValue(item);
        let showButton_Jetzt = false;
        let showButton_Zuruecksetzen = false;
        if (appearance.includes('j')) {
            showButton_Jetzt = true;
        }
        if (appearance.includes('z')) {
            showButton_Zuruecksetzen = true;
        }

        /* Error message checks */
        const dateIsEmpty = getTempDateTimeDate(item.linkId) === '' || getTempDateTimeDate(item.linkId) === undefined;
        const timeIsEmpty = getTempDateTimeTime(item.linkId) === '' || getTempDateTimeTime(item.linkId) === undefined;
        const invalid = item.required && dateIsEmpty && timeIsEmpty && validate ? true : false;
        const dateIsMissing = dateIsEmpty && !timeIsEmpty;
        const timeIsMissing = !dateIsEmpty && timeIsEmpty;
        const invalidTimeFormat = checkInvalidState(item.linkId);

        let tempDateTimeTime = '';
        const tdt = getTempDateTimeTime(item.linkId);
        tempDateTimeTime = tdt ?? '';

        return (
            <QDiv display={qEnableWhenHandling(item)} key={item.linkId}>
                <div style={{ paddingLeft: qDisplayBorderPadding, paddingRight: qDisplayBorderPadding }}>
                    <Form.Group controlId={`formTimestamp-${item.linkId}`}>
                        <Form.Label>{item.required ? RequiredAsterisk(item.text) : item.text}</Form.Label>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {labelLeft !== '' ? (
                                <LeftDiv style={{ paddingTop: '10px', paddingRight: '10px', position: 'relative' }}>
                                    {labelLeft}
                                </LeftDiv>
                            ) : null}
                            <div style={{ width: '210px' }}>
                                <Form.Control
                                    style={{ width: '200px' }}
                                    type="date"
                                    value={getTempDateTimeDate(item.linkId)}
                                    onChange={(e) =>
                                        setAnswerDateTimeOption(
                                            item.linkId,
                                            e.target.value.toString(),
                                            tempDateTimeTime,
                                        )
                                    }
                                    isInvalid={invalid || dateIsMissing}
                                />
                                <Form.Control.Feedback style={{ width: '500px', overflowX: 'visible' }} type="invalid">
                                    Bitte Datum angeben
                                </Form.Control.Feedback>
                            </div>
                            <div style={{ width: '110px' }}>
                                <Form.Control
                                    style={{ width: '100px' }}
                                    required={item.required ? true : false}
                                    type="text" /* time */
                                    placeholder="hh:mm"
                                    value={getTempDateTimeTime(item.linkId)}
                                    onChange={(e) =>
                                        e.target.value.toString() === 'j'
                                            ? handleAnswerDateTimeOption(item.linkId, moment().format('LT'))
                                            : handleAnswerDateTimeOption(item.linkId, e.target.value.toString())
                                    }
                                    onBlur={(e) =>
                                        checkInput({
                                            linkId: item.linkId,
                                            type: 'dateTimeTime',
                                            input: e.target.value.toString(),
                                            regex: timeRegex,
                                        })
                                    }
                                    isInvalid={invalid || timeIsMissing || invalidTimeFormat}
                                />
                                <Form.Control.Feedback style={{ width: '500px', overflowX: 'visible' }} type="invalid">
                                    {timeIsMissing || invalid ? 'Bitte Zeit angeben' : 'Ungültige Eingabe'}
                                </Form.Control.Feedback>
                            </div>
                            {labelRight !== '' ? (
                                <LeftDiv style={{ paddingTop: '10px', position: 'relative' }}>{labelRight}</LeftDiv>
                            ) : null}
                            {showButton_Jetzt && (
                                <div style={{ paddingLeft: '10px' }}>
                                    <Button
                                        variant="light"
                                        onClick={() =>
                                            setAnswerDateTimeOption(
                                                item.linkId,
                                                moment().format('yyyy-MM-DD'),
                                                moment().format('LT'),
                                            )
                                        }
                                    >
                                        Jetzt
                                    </Button>
                                </div>
                            )}
                            {showButton_Zuruecksetzen && (
                                <div style={{ paddingLeft: '10px' }}>
                                    <Button
                                        variant="light"
                                        onClick={() => setAnswerDateTimeOption(item.linkId, '', '')}
                                    >
                                        Zurücksetzen
                                    </Button>
                                </div>
                            )}
                        </div>
                    </Form.Group>
                </div>
            </QDiv>
        );
    };

    const qTypeDisplay = (item: IQItem) => {
        let content = <></>;
        if (item?.extension || item?.modifierExtension) {
            const className = getExtensionClassValue(item);
            const appearance = getExtensionAppearanceValue(item);
            let asHTML = false;
            if (appearance === 'html') {
                asHTML = true;
            }
            if (className === 'heading1') {
                if (asHTML) {
                    content = (
                        <p
                            dangerouslySetInnerHTML={{ __html: item.text }}
                            style={{ fontWeight: 'bold', fontSize: '20px' }}
                        />
                    );
                } else {
                    content = (
                        <LeftDiv>
                            <p style={{ fontWeight: 'bold', fontSize: '20px' }}>{item.text}</p>
                        </LeftDiv>
                    );
                }
            } else if (className === 'heading2') {
                if (asHTML) {
                    content = (
                        <p
                            dangerouslySetInnerHTML={{ __html: item.text }}
                            style={{ fontWeight: 'bold', fontSize: '16px' }}
                        />
                    );
                } else {
                    content = <p style={{ fontWeight: 'bold', fontSize: '16px' }}>{item.text}</p>;
                }
            } else if (className === 'blockText') {
                if (asHTML) {
                    content = <p dangerouslySetInnerHTML={{ __html: item.text }} />;
                } else {
                    content = <p>{item.text}</p>;
                }
            } else if (className === 'listItem') {
                if (asHTML) {
                    content = (
                        <ul>
                            <li dangerouslySetInnerHTML={{ __html: item.text }} />
                        </ul>
                    );
                } else {
                    content = (
                        <ul>
                            <li>{item.text}</li>
                        </ul>
                    );
                }
            } else {
                content = <></>;
            }
        } else {
            content = <p style={{ fontWeight: 'bold', fontSize: '20px' }}>{item.text}</p>;
        }

        return (
            <div key={item.linkId}>
                <QDiv marginBottom="10px" display={qEnableWhenHandling(item)}>
                    {content}
                </QDiv>
            </div>
        );
    };

    const qTypeChoice = (item: IQItem) => {
        const allOptions: any[] = [];

        let rowLimit_sm: number | undefined = undefined;
        let rowLimit_lg: number | undefined = undefined;
        let rowLimit_md: number | undefined = undefined;
        let rowLimit_xs: number | undefined = undefined;
        let rowLimit_xl: number | undefined = undefined;

        if (item?.extension || item?.modifierExtension) {
            let className = getExtensionClassValue(item);
            if (className.length <= 0) {
                className = getExtensionDisplayType(item);
            }

            if (!className || className === '') {
                className = 'radio'; //default value
            }

            const appearance = getExtensionAppearanceValue(item);
            const maxValue = getExtensionMaxValue(item);

            const myOptions: IOption[] = [{ code: '0', display: '' }];
            if (item.option) {
                for (let i = 0; i < item.option.length; i++) {
                    myOptions.push({
                        code: item.option[i].valueCoding.code,
                        display: item.option[i].valueCoding.display,
                    });
                }
            }

            if (className === 'dropdown') {
                let dropdownWidth = '50%';
                if (appearance === 'small') {
                    dropdownWidth = '25%';
                } else if (appearance === 'large') {
                    dropdownWidth = '100%';
                }

                /* True if answer is required and there is no answer given and the "Weiter"-Button has been clicked (=validate) */
                const invalid =
                    item.required && !isAnswerLinkIdPresent(item.linkId).isPresent && validate ? true : false;

                return (
                    <QDiv display={qEnableWhenHandling(item)}>
                        <div style={{ paddingLeft: qDisplayBorderPadding, paddingRight: qDisplayBorderPadding }}>
                            <Form.Group controlId={`formDropdown-${item.linkId}`}>
                                <Form.Label>{item.required ? RequiredAsterisk(item.text) : item.text}</Form.Label>
                                <div style={{ width: dropdownWidth }}>
                                    <Form.Control
                                        as="select"
                                        required={item.required ? true : false}
                                        name={item.text}
                                        value={getAnswerDropdownOption(item.linkId)}
                                        onChange={(e) => setAnswerDropdownOption(item, e)}
                                        isInvalid={invalid}
                                    >
                                        {myOptions.map((option: { code: string; display: string }) => (
                                            <option key={option.display}>{option.display}</option>
                                        ))}
                                    </Form.Control>
                                </div>
                            </Form.Group>
                        </div>
                    </QDiv>
                );
            } else if (className === 'button') {
                const requiredMissing =
                    validate && item.required && getAnswerChoiceOption(item.linkId) === undefined ? true : false;

                const allOptionTexts = getAllOptionTextsAsArray(item.option);
                const buttonWidthVal = buttonWidth(screenSize, allOptionTexts);
                item.option?.map((option: IQItemChoiceOption) => {
                    const id = item.linkId + option.valueCoding.display;
                    allOptions.push(
                        <StyledToggleButton
                            key={id}
                            width={buttonWidthVal}
                            requiredmissing={requiredMissing.toString()}
                            id={id}
                            value={option.valueCoding.code}
                            onChange={() =>
                                setAnswerChoiceOption(
                                    item.linkId,
                                    option.valueCoding.code,
                                    option.valueCoding.display,
                                    option.valueCoding.extension,
                                )
                            }
                        >
                            {option.valueCoding.display}
                        </StyledToggleButton>,
                    );
                });

                /* if an optional answer is selected, we want a reset-button */
                if (getAnswerChoiceOption(item.linkId) !== undefined && !item.required) {
                    allOptions.push(
                        <StyledResetButton
                            variant="light"
                            id={item.linkId}
                            value="X"
                            onChange={() => resetSelectedAnswer(item.linkId)}
                        >
                            <OverlayTrigger
                                key="right"
                                placement="right"
                                overlay={<Tooltip id="deactivateChoiceTooltip">Auswahl löschen</Tooltip>}
                            >
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                >
                                    <AiOutlineMinusCircle size={20} color={themeContext.icon.neg.color} />
                                </div>
                            </OverlayTrigger>
                        </StyledResetButton>,
                    );
                }

                return (
                    <QDiv display={qEnableWhenHandling(item)} key={item.linkId}>
                        <div style={{ paddingLeft: qDisplayBorderPadding, paddingRight: qDisplayBorderPadding }}>
                            <Form.Group>
                                <Form.Label>{item.required ? RequiredAsterisk(item.text) : item.text}</Form.Label>
                                <ButtonToolbar aria-label="Toolbar with button groups">
                                    <ToggleButtonGroupFlex
                                        type="radio"
                                        value={getAnswerChoiceOption(item.linkId)}
                                        name={item.linkId}
                                    >
                                        {allOptions}
                                    </ToggleButtonGroupFlex>
                                </ButtonToolbar>
                            </Form.Group>
                        </div>
                    </QDiv>
                );
            } else if (className === 'radio') {
                if (getAnswerChoiceOption(item.linkId) === undefined) {
                    item?.initialCoding &&
                        setAnswerChoiceOption(
                            item.linkId,
                            item.initialCoding.code,
                            item.initialCoding.display,
                            undefined,
                        );
                }

                // let radioBreakpointSM = appearance === 'vertical' ? 12 : 4;
                // let radioSpanLG = appearance === 'vertical' ? 12 : 2;
                // let rowHeight = appearance === 'vertical' ? '45px' : 'auto';

                let radioBreakpointSM: number | undefined = 4;
                let radioSpanLG: number | undefined = 2;
                let rowHeight = 'auto';

                if (appearance === 'vertical') {
                    radioBreakpointSM = 12;
                    radioSpanLG = 12;
                    rowHeight = '45px';
                } else {
                    if (maxValue !== undefined) {
                        /* we must set the col numbers in <Col> element to undefined if we want to use col numbers in <Row> */
                        radioBreakpointSM = undefined;
                        radioSpanLG = undefined;
                    } else {
                        radioBreakpointSM = 4;
                        radioSpanLG = 2;
                    }
                    rowHeight = 'auto';
                    rowLimit_sm = maxValue !== undefined && maxValue < 3 ? maxValue : 3;
                    rowLimit_lg = maxValue;
                    rowLimit_xl = maxValue;
                    rowLimit_md = maxValue;
                    rowLimit_xs = 1;
                }

                /* True if answer is required and there is no answer given and the "Weiter"-Button has been clicked (=validate) */
                const invalid =
                    item.required && !isAnswerLinkIdPresent(item.linkId).isPresent && validate ? true : false;

                item.option?.map((option: IQItemChoiceOption) => {
                    const id = item.linkId + option.valueCoding.code;
                    allOptions.push(
                        <>
                            <Col sm={radioBreakpointSM} lg={{ span: radioSpanLG }} key={`formRadio-${id}`}>
                                <div style={{ height: rowHeight, paddingTop: '10px', paddingLeft: '0px' }}>
                                    <Form.Check
                                        name={`formRadio-${item.linkId}`}
                                        type="radio"
                                        required={item.required ? true : false}
                                        id={`formRadio-${id}`}
                                        label={option.valueCoding.display}
                                        checked={isAnswerChoiceOptionSet(item.linkId, option.valueCoding.code)}
                                        onChange={() =>
                                            setAnswerChoiceOption(
                                                item.linkId,
                                                option.valueCoding.code,
                                                option.valueCoding.display,
                                                option.valueCoding.extension,
                                            )
                                        }
                                        isInvalid={invalid}
                                    />
                                </div>
                            </Col>
                        </>,
                    );
                });

                /* if an optional answer is selected, we want a reset-button */
                if (getAnswerChoiceOption(item.linkId) !== undefined && !item.required) {
                    allOptions.push(
                        <ToggleButtonGroupFlex type="radio" name={item.linkId}>
                            <StyledResetButton
                                variant="link"
                                id={item.linkId}
                                value="X"
                                onChange={() => resetSelectedAnswer(item.linkId)}
                            >
                                <OverlayTrigger
                                    key="right"
                                    placement="right"
                                    overlay={<Tooltip id="deactivateChoiceTooltip">Auswahl löschen</Tooltip>}
                                >
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                        }}
                                    >
                                        <AiOutlineMinusCircle size={20} color={themeContext.icon.neg.color} />
                                    </div>
                                </OverlayTrigger>
                            </StyledResetButton>
                        </ToggleButtonGroupFlex>,
                    );
                }

                return (
                    <QDiv display={qEnableWhenHandling(item)}>
                        <div style={{ paddingLeft: qDisplayBorderPadding }}>
                            <Form.Group>
                                <Form.Label>{item.required ? RequiredAsterisk(item.text) : item.text}</Form.Label>
                                <QCheckboxRow
                                    sm={rowLimit_sm}
                                    xl={rowLimit_xl}
                                    lg={rowLimit_lg}
                                    md={rowLimit_md}
                                    xs={rowLimit_xs}
                                >
                                    {allOptions}
                                </QCheckboxRow>
                            </Form.Group>
                        </div>
                    </QDiv>
                );
            }
        } else {
            /* True if answer is required and there is no answer given and the "Weiter"-Button has been clicked (=validate) */
            const invalid = item.required && !isAnswerLinkIdPresent(item.linkId).isPresent && validate ? true : false;

            item.option?.map((option: IQItemChoiceOption) => {
                const id = item.linkId + option.valueCoding.code;
                allOptions.push(
                    <>
                        <Col sm={4} lg={{ span: 2 }} key={`formRadio-${id}`}>
                            <div style={{ height: '45px', paddingTop: '10px', paddingLeft: '10px' }}>
                                <Form.Check
                                    type="radio"
                                    required={item.required ? true : false}
                                    id={`formRadio-${id}`}
                                    label={option.valueCoding.display}
                                    checked={isAnswerChoiceOptionSet(item.linkId, option.valueCoding.code)}
                                    onChange={() =>
                                        setAnswerChoiceOption(
                                            item.linkId,
                                            option.valueCoding.code,
                                            option.valueCoding.display,
                                            option.valueCoding.extension,
                                        )
                                    }
                                    isInvalid={invalid}
                                />
                            </div>
                        </Col>
                    </>,
                );
            });

            /* if an optional answer is selected, we want a reset-button */
            if (getAnswerChoiceOption(item.linkId) !== undefined && !item.required) {
                allOptions.push(
                    <ToggleButtonGroupFlex type="radio" name={item.linkId} style={{ paddingBottom: '5px' }}>
                        <StyledResetButton
                            variant="link"
                            id={item.linkId}
                            value="X"
                            onChange={() => resetSelectedAnswer(item.linkId)}
                        >
                            <OverlayTrigger
                                key="right"
                                placement="right"
                                overlay={<Tooltip id="deactivateChoiceTooltip">Auswahl löschen</Tooltip>}
                            >
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                >
                                    <AiOutlineMinusCircle size={20} color={themeContext.icon.neg.color} />
                                </div>
                            </OverlayTrigger>
                        </StyledResetButton>
                    </ToggleButtonGroupFlex>,
                );
            }

            return (
                <QDiv display={qEnableWhenHandling(item)}>
                    <div style={{ paddingLeft: qDisplayBorderPadding }}>
                        <Form.Group>
                            <Form.Label>{item.required ? RequiredAsterisk(item.text) : item.text}</Form.Label>
                            <Row>{allOptions}</Row>
                        </Form.Group>
                    </div>
                </QDiv>
            );
        }
    };

    const qTypeOpenChoice = (item: IQItem) => {
        const allOptions: any[] = [];
        const appearance = getExtensionAppearanceValue(item);
        const andSubExpressions = getExtensionAndSubExpressions(item);
        const maxCol = getExtensionMaxValue(item);

        /* default 3 columns (LG) collapsing to 2 columns (SM) */
        let horizontalBreakpointSM = 6;
        let horizontalBreakpointLG = 4;

        if (appearance === 'vertical' || maxCol === 1) {
            horizontalBreakpointLG = 12;
            horizontalBreakpointSM = 12;
        } else if (maxCol === 2) {
            horizontalBreakpointLG = 6;
            horizontalBreakpointSM = 12;
        } else if (maxCol === 3) {
            horizontalBreakpointLG = 4;
            horizontalBreakpointSM = 6;
        } else if (maxCol === 4) {
            horizontalBreakpointLG = 3;
            horizontalBreakpointSM = 4;
        }

        /* True if answer is required and there is no answer given and the "Weiter"-Button has been clicked (=validate) */
        const invalid = item.required && getAnswerOpenChoiceOptions(item.linkId).length < 1 && validate ? true : false;

        item?.option?.map((option: IQItemChoiceOption) => {
            const id = item.linkId + option.valueCoding.code;
            const singleSelectionAttr = getOptionExtensionSingleSelection(option);

            allOptions.push(
                <Col sm={horizontalBreakpointSM} lg={horizontalBreakpointLG} key={id}>
                    <div style={{ position: 'relative', width: '100%' }}>
                        <Form.Check inline id={`inline-cbx-openchoice-${id}`}>
                            <Form.Check.Input
                                required={item.required}
                                type="checkbox"
                                checked={isAnswerOpenChoiceOptionSet(item.linkId, option.valueCoding.code)}
                                onChange={(e: any) =>
                                    setAnswerOpenChoiceOption(
                                        item.linkId,
                                        option.valueCoding.code,
                                        option.valueCoding.display,
                                        e.target.checked,
                                        singleSelectionAttr,
                                        item.option,
                                    )
                                }
                                isInvalid={invalid}
                            />
                            <Form.Check.Label>{option.valueCoding.display}</Form.Check.Label>
                        </Form.Check>
                    </div>
                </Col>,
            );
        });

        return (
            <>
                {andSubExpressions === '' ? (
                    <QDiv display={qEnableWhenHandling(item)}>
                        <div style={{ paddingLeft: qDisplayBorderPadding }}>
                            <Form.Group>
                                <Form.Label>{item.required ? RequiredAsterisk(item.text) : item.text}</Form.Label>
                                <QCheckboxRow>{allOptions}</QCheckboxRow>
                            </Form.Group>
                        </div>
                    </QDiv>
                ) : (
                    <QDiv display={qMultiEnableWhenHandling(item)}>
                        <div style={{ paddingLeft: qDisplayBorderPadding }}>
                            <Form.Group>
                                <Form.Label>{item.required ? RequiredAsterisk(item.text) : item.text}</Form.Label>
                                <QCheckboxRow>{allOptions}</QCheckboxRow>
                            </Form.Group>
                        </div>
                    </QDiv>
                )}
            </>
        );
    };

    const qTypeBoolean = (item: IQItem) => {
        const labelLeft = getExtensionLabelLeft(item);
        let labelLeftColor = 'black';
        let invalid = false;

        /* Red label and invalid if answer is required and the checkbox is not checked (!== true) and the "Weiter"-Button has been clicked (=validate) */
        if (item.required && getAnswerBooleanOption(item.linkId) !== true && validate) {
            labelLeftColor = '#EC0035';
            invalid = true;
        }

        return (
            <QDiv display={qEnableWhenHandling(item)} key={item.linkId}>
                <div style={{ paddingLeft: qDisplayBorderPadding }}>
                    <Form.Group>
                        <div style={{ display: 'flex' }}>
                            {labelLeft !== '' ? (
                                <div style={{ position: 'relative', paddingRight: '10px', color: labelLeftColor }}>
                                    {labelLeft}
                                </div>
                            ) : null}
                            <div style={{ position: 'relative' }}>
                                <Form.Check id={`cbx-booleanchoice-${item.linkId}`}>
                                    <Form.Check.Input
                                        type="checkbox"
                                        required={item.required ? true : false}
                                        checked={getAnswerBooleanOption(item.linkId) || ''}
                                        onChange={(e: any) => setAnswerBooleanOption(item.linkId, e.target.checked)}
                                        isInvalid={invalid}
                                    />
                                    <Form.Check.Label>
                                        {item.required ? RequiredAsterisk(item.text) : item.text}
                                    </Form.Check.Label>
                                </Form.Check>
                            </div>
                        </div>
                    </Form.Group>
                </div>
            </QDiv>
        );
    };

    const qTypeSignature = (item: IQItem) => {
        const signatureSize = getExtensionSize(item) !== '' ? getExtensionSize(item) : 'middle';
        const signaturePosition = getExtensionPosition(item) !== '' ? getExtensionPosition(item) : 'image_left';

        let sigFloat: any = 'none';
        if (signaturePosition === 'image_left') {
            sigFloat = 'left';
        } else if (signaturePosition === 'image_right') {
            sigFloat = 'right';
        } else if (signaturePosition === 'image_center') {
            sigFloat = 'none';
        }

        let width = 700;
        let height = 300;

        if (signatureSize === 'small') {
            if (isMobile(screenSize.width)) {
                width = 200;
                height = 100;
            } else if (isTablet(screenSize.width)) {
                width = 300;
                height = 200;
            } else {
                width = 400;
                height = 250;
            }
        } else if (signatureSize === 'middle') {
            if (isMobile(screenSize.width)) {
                width = 250;
                height = 180;
            } else if (isTablet(screenSize.width)) {
                width = 400;
                height = 250;
            } else {
                width = 600;
                height = 300;
            }
        } else if (signatureSize === 'large') {
            if (isMobile(screenSize.width)) {
                width = 300;
                height = 200;
            } else if (isTablet(screenSize.width)) {
                width = 500;
                height = 300;
            } else {
                width = 700;
                height = 450;
            }
        } else {
            width = 700;
            height = 300;
        }

        const onSignaturePadWriteEnd = (sigData) => {
            // setSignatureData(sigData);
            setAnswerSignature(item.linkId, sigData);
        };

        const content = (
            <>
                <SignaturePad onWriteEnd={onSignaturePadWriteEnd} width={width} height={height} margin="auto" />
            </>
        );

        return (
            <QDiv display={qEnableWhenHandling(item)} key={item.linkId} float={sigFloat}>
                {content}
            </QDiv>
        );
    };

    const qTypeAttachment = (item: IQItem) => {
        let imageSrc = 'data:image/png;base64,';
        if (item.initialAttachment) {
            imageSrc = 'data:' + item.initialAttachment?.contentType + ';base64,' + item.initialAttachment?.data;
        }

        const className = getExtensionClassValue(item);

        const imageWidth = getExtensionImageWidth(item) !== '' ? getExtensionImageWidth(item) : '50mm';
        const imageHeight = getExtensionImageHeight(item) !== '' ? getExtensionImageHeight(item) : '50mm';
        const descAppearance =
            getExtensionImageDescAppearance(item) !== '' ? getExtensionImageDescAppearance(item) : null;

        const imageTooltip = getExtensionFieldNote(item);

        const image = (
            <Image src={imageSrc} fluid title={imageTooltip} style={{ width: imageWidth, height: imageHeight }} />
        );
        const imageDescription = item.text ? item.text : null;

        const alignFromClassName = className.split('_')[1];
        let align = 'left';
        if (alignFromClassName) {
            align = alignFromClassName;
        }

        let float = 'none';
        if (getExtensionAppearanceValue(item) === 'float') {
            if (align === 'left' || align === 'right') {
                float = align;
            }
        }

        let imageDescDirection = 'column';
        let displayFlex = 'flex';
        let parentDivDirection = 'left';
        let alignItemsFloatDiv = 'left';
        let textDirection = 'left';
        let floatDivWidth = 'auto';
        if (descAppearance) {
            if (descAppearance === 'bottom_text') {
                imageDescDirection = 'column';
                displayFlex = 'flex';
                textDirection = 'bottom';
            } else if (descAppearance === 'top_text') {
                imageDescDirection = 'column-reverse';
                displayFlex = 'flex';
                textDirection = 'top';
            } else if (descAppearance === 'left_text') {
                imageDescDirection = 'row-reverse';
                displayFlex = 'inline-flex';
                textDirection = 'left';
            } else if (descAppearance === 'right_text') {
                imageDescDirection = 'row';
                displayFlex = 'inline-flex';
                textDirection = 'right';
            }
        }

        if (align === 'left') {
            parentDivDirection = 'left';
            alignItemsFloatDiv = 'flex-start';
            floatDivWidth = 'auto';
        } else if (align === 'right') {
            parentDivDirection = 'right';
            alignItemsFloatDiv = 'flex-end';
            floatDivWidth = 'auto';
        } else if (align === 'center') {
            parentDivDirection = 'center';
            alignItemsFloatDiv = 'center';
            if (textDirection === 'left') {
                floatDivWidth = 'auto';
            } else if (textDirection === 'right') {
                floatDivWidth = 'auto';
            } else {
                floatDivWidth = '100%';
            }
        }

        let content = <></>;

        content = (
            <>
                <ImageFloatDiv
                    float={float}
                    direction={imageDescDirection}
                    display={displayFlex}
                    alignItems={alignItemsFloatDiv}
                    width={floatDivWidth}
                >
                    <ImageDiv textAlign={align} float={float} textDirection={textDirection}>
                        {image}
                    </ImageDiv>
                    {imageDescription && descAppearance && (
                        <ImageDescDiv
                            textAlign={align}
                            divWidth={imageWidth}
                            float={float}
                            textDirection={textDirection}
                        >
                            {imageDescription}
                        </ImageDescDiv>
                    )}
                </ImageFloatDiv>
                {/*
                <div>
                    <p>
                        Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Cras commodo imperdiet lectus. Aliquam
                        tempus ipsum eget urna egestas sagittis. In nulla ligula, congue et, mollis nec, venenatis ut,
                        est. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Cras commodo imperdiet lectus.
                        Aliquam tempus ipsum eget urna egestas sagittis. In nulla ligula, congue et, mollis nec,
                        venenatis ut, est. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Cras commodo
                        imperdiet lectus. Aliquam tempus ipsum eget urna egestas sagittis. In nulla ligula, congue et,
                        mollis nec, venenatis ut, est. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Cras
                        commodo imperdiet lectus. Aliquam tempus ipsum eget urna egestas sagittis. In nulla ligula,
                        congue et, mollis nec, venenatis ut, est. Lorem ipsum dolor sit amet, consectetuer adipiscing
                        elit. Cras commodo imperdiet lectus. Aliquam tempus ipsum eget urna egestas sagittis. In nulla
                        ligula, congue et, mollis nec, venenatis ut, est.
                    </p>
                </div>
                */}
            </>
        );

        return (
            <QImageDiv key={item.linkId} textAlign={parentDivDirection}>
                {content}
            </QImageDiv>
        );
    };

    const toBase64 = (file: any) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    const setCovidCertValues = (verifyCovidCertResponse: any) => {
        const allValues: ICovidQRArray[] = [];
        if (verifyCovidCertResponse !== undefined) {
            if (verifyCovidCertResponse.data) {
                if (verifyCovidCertResponse.data.dob !== undefined) {
                    allValues.push({ linkId: 'DOB', valueString: verifyCovidCertResponse.data.dob });
                }
                if (verifyCovidCertResponse.data.nam !== undefined) {
                    if (verifyCovidCertResponse.data.nam.fn !== undefined) {
                        allValues.push({ linkId: 'NAM_FN', valueString: verifyCovidCertResponse.data.nam.fn });
                    }
                    if (verifyCovidCertResponse.data.nam.fnt !== undefined) {
                        allValues.push({ linkId: 'NAM_FNT', valueString: verifyCovidCertResponse.data.nam.fnt });
                    }
                    if (verifyCovidCertResponse.data.nam.gn !== undefined) {
                        allValues.push({ linkId: 'NAM_GN', valueString: verifyCovidCertResponse.data.nam.gn });
                    }
                    if (verifyCovidCertResponse.data.nam.gnt !== undefined) {
                        allValues.push({ linkId: 'NAM_GNT', valueString: verifyCovidCertResponse.data.nam.gnt });
                    }
                }
                if (verifyCovidCertResponse.data.v !== undefined) {
                    if (verifyCovidCertResponse.data.v[0].tg !== undefined) {
                        allValues.push({ linkId: 'V_TG', valueString: verifyCovidCertResponse.data.v[0].tg });
                    }
                    if (verifyCovidCertResponse.data.v[0].vp !== undefined) {
                        allValues.push({ linkId: 'V_VP', valueString: verifyCovidCertResponse.data.v[0].vp });
                    }
                    if (verifyCovidCertResponse.data.v[0].mp !== undefined) {
                        allValues.push({ linkId: 'V_MP', valueString: verifyCovidCertResponse.data.v[0].mp });
                    }
                    if (verifyCovidCertResponse.data.v[0].ma !== undefined) {
                        allValues.push({ linkId: 'V_MA', valueString: verifyCovidCertResponse.data.v[0].ma });
                    }
                    if (verifyCovidCertResponse.data.v[0].dn !== undefined) {
                        allValues.push({ linkId: 'V_DN', valueString: verifyCovidCertResponse.data.v[0].dn });
                    }
                    if (verifyCovidCertResponse.data.v[0].sd !== undefined) {
                        allValues.push({ linkId: 'V_SD', valueString: verifyCovidCertResponse.data.v[0].sd });
                    }
                    if (verifyCovidCertResponse.data.v[0].dt !== undefined) {
                        allValues.push({ linkId: 'V_DT', valueString: verifyCovidCertResponse.data.v[0].dt });
                    }
                    if (verifyCovidCertResponse.data.v[0].co !== undefined) {
                        allValues.push({ linkId: 'V_CO', valueString: verifyCovidCertResponse.data.v[0].co });
                    }
                    if (verifyCovidCertResponse.data.v[0].is !== undefined) {
                        allValues.push({ linkId: 'V_IS', valueString: verifyCovidCertResponse.data.v[0].is });
                    }
                    if (verifyCovidCertResponse.qrContent !== undefined) {
                        // hier nehmen wir nicht den ci Eintrag, sondern den kompletten
                        allValues.push({ linkId: 'V_CI', valueString: verifyCovidCertResponse.qrContent });
                    }
                }
                if (verifyCovidCertResponse.data.t !== undefined) {
                    if (verifyCovidCertResponse.data.t[0].tg !== undefined) {
                        allValues.push({ linkId: 'T_TG', valueString: verifyCovidCertResponse.data.t[0].tg });
                    }
                    if (verifyCovidCertResponse.data.t[0].tt !== undefined) {
                        allValues.push({ linkId: 'T_TT', valueString: verifyCovidCertResponse.data.t[0].tt });
                    }
                    if (verifyCovidCertResponse.data.t[0].nm !== undefined) {
                        allValues.push({ linkId: 'T_NM', valueString: verifyCovidCertResponse.data.t[0].nm });
                    }
                    if (verifyCovidCertResponse.data.t[0].ma !== undefined) {
                        allValues.push({ linkId: 'T_MA', valueString: verifyCovidCertResponse.data.t[0].ma });
                    }
                    if (verifyCovidCertResponse.data.t[0].sc !== undefined) {
                        allValues.push({ linkId: 'T_SC', valueString: verifyCovidCertResponse.data.t[0].sc });
                    }
                    if (verifyCovidCertResponse.data.t[0].tr !== undefined) {
                        allValues.push({ linkId: 'T_TR', valueString: verifyCovidCertResponse.data.t[0].tr });
                    }
                    if (verifyCovidCertResponse.data.t[0].tc !== undefined) {
                        allValues.push({ linkId: 'T_TC', valueString: verifyCovidCertResponse.data.t[0].tc });
                    }
                    if (verifyCovidCertResponse.data.t[0].co !== undefined) {
                        allValues.push({ linkId: 'T_CO', valueString: verifyCovidCertResponse.data.t[0].co });
                    }
                    if (verifyCovidCertResponse.data.t[0].is !== undefined) {
                        allValues.push({ linkId: 'T_IS', valueString: verifyCovidCertResponse.data.t[0].is });
                    }
                    if (verifyCovidCertResponse.qrContent !== undefined) {
                        //hier nehmen wir nicht den ci Eintrag, sondern den kompletten
                        allValues.push({ linkId: 'T_CI', valueString: verifyCovidCertResponse.qrContent });
                    }
                }
                if (verifyCovidCertResponse.data.r !== undefined) {
                    if (verifyCovidCertResponse.data.r[0].tg !== undefined) {
                        allValues.push({ linkId: 'R_TG', valueString: verifyCovidCertResponse.data.r[0].tg });
                    }
                    if (verifyCovidCertResponse.data.r[0].fr !== undefined) {
                        allValues.push({ linkId: 'R_FR', valueString: verifyCovidCertResponse.data.r[0].fr });
                    }
                    if (verifyCovidCertResponse.data.r[0].co !== undefined) {
                        allValues.push({ linkId: 'R_CO', valueString: verifyCovidCertResponse.data.r[0].co });
                    }
                    if (verifyCovidCertResponse.data.r[0].is !== undefined) {
                        allValues.push({ linkId: 'R_IS', valueString: verifyCovidCertResponse.data.r[0].is });
                    }
                    if (verifyCovidCertResponse.data.r[0].df !== undefined) {
                        allValues.push({ linkId: 'R_DF', valueString: verifyCovidCertResponse.data.r[0].df });
                    }
                    if (verifyCovidCertResponse.data.r[0].du !== undefined) {
                        allValues.push({ linkId: 'R_DU', valueString: verifyCovidCertResponse.data.r[0].du });
                    }
                    if (verifyCovidCertResponse.qrContent !== undefined) {
                        //hier nehmen wir nicht den ci Eintrag, sondern den kompletten
                        allValues.push({ linkId: 'R_CI', valueString: verifyCovidCertResponse.qrContent });
                    }
                }
            }
        }

        return allValues;
    };

    const covidCertValueSuccessWorkflow = (
        verifyCovidCertResponse: any,
        base64Arr: any[] | null,
        linkId: string | null,
    ) => {
        const allValues = setCovidCertValues(verifyCovidCertResponse);

        if (verifyCovidCertResponse.targetPatId !== undefined && verifyCovidCertResponse.targetPatId > 0) {
            setTargetPatId(verifyCovidCertResponse.targetPatId);
            // showAlertMessage({
            //     alertTitle: 'QR Scan - Vertreter',
            //     alertTxt: 'Zertifikat einer anderen Person: Wollen Sie die Daten trotzdem ins Formular übernehmen?',
            //     alertType: AlertType.info,
            //     onHide: AlertOnHide.onlyClose,
            //     alertdispatch: alertdispatch,
            // });
            setShowForeignQRModal({ show: true, allValues: allValues, base64Arr: base64Arr, linkId: linkId });
        } else {
            setAnswerCovidQRValues(linkId, base64Arr, allValues);
        }
    };

    const handleSelectedPhoto = async (e: React.ChangeEvent<any>, linkId: string, buttonType: string) => {
        const files: any[] = Array.from(e.target.files);
        const base64Arr: any[] = [];
        for (let i = 0; i < files.length; i++) {
            const base64Src = await toBase64(files[i]);
            base64Arr.push(base64Src);
        }

        if (buttonType === 'QRSCAN') {
            setTargetPatId(0);
            const verifyCovidCertResponse = await verifyCovidCert(state.sessionId, base64Arr[0], 'picture');
            if (!verifyCovidCertResponse || verifyCovidCertResponse.ERROR) {
                showAlertMessage({
                    alertTitle: 'QR Scan',
                    alertTxt: verifyCovidCertResponse?.ERROR
                        ? verifyCovidCertResponse.ERROR
                        : 'Keine Rückgabe von verifyCovidCert erhalten',
                    alertType: AlertType.error,
                    onHide: AlertOnHide.onlyClose,
                    alertdispatch: alertdispatch,
                });
            } else {
                covidCertValueSuccessWorkflow(verifyCovidCertResponse, base64Arr, linkId);
            }
        } else {
            setAnswerAttachment(linkId, base64Arr);
        }
    };

    interface IUploadButton {
        linkId: string;
        buttonName: string;
        tooltip: string;
        possibleFileType: string;
        popOverFileUploadName: string;
        popOverCameraUploadName: string;
        buttonType: string;
    }

    const UploadButton = (props: IUploadButton) => {
        const inputRef = useRef<HTMLInputElement>(null);
        const [openWebcamPreview, setOpenWebcamPreview] = useState(false);
        const [photoPreviewColl, setPreviewColl] = useState<IPhoto[]>([]);
        const [showOverlay, setShowOverlay] = useState(false);

        let acceptFileType = '';
        if (props.possibleFileType) {
            let fileTypeList = props.possibleFileType;
            fileTypeList = fileTypeList.replace(/;/g, ',');
            const fileTypeArr = fileTypeList.split(',');
            for (let i = 0; i < fileTypeArr.length; i++) {
                if (i > 0) {
                    acceptFileType = acceptFileType + ',';
                }
                let fileType = fileTypeArr[i];
                if (!fileType.startsWith('.')) {
                    fileType = '.' + fileType;
                }

                acceptFileType = acceptFileType + fileType;
            }
        }

        const handleUpload = () => {
            inputRef.current?.click();
        };

        const handleClosePreview = () => {
            setOpenWebcamPreview(false);
            setPreviewColl([]);
        };

        const uploadSelectedPhotos = async (linkId: string, buttonType: string) => {
            const base64Arr: any[] = [];
            for (let i = 0; i < photoPreviewColl.length; i++) {
                const base64Src = photoPreviewColl[i].data;
                base64Arr.push(base64Src);
            }

            if (buttonType === 'QRSCAN') {
                setTargetPatId(0);
                const verifyCovidCertResponse = await verifyCovidCert(state.sessionId, base64Arr[0], 'picture');
                if (!verifyCovidCertResponse || verifyCovidCertResponse.ERROR) {
                    showAlertMessage({
                        alertTitle: 'QR Scan',
                        alertTxt: verifyCovidCertResponse?.ERROR
                            ? verifyCovidCertResponse.ERROR
                            : 'Keine Rückgabe von verifyCovidCert erhalten',
                        alertType: AlertType.error,
                        onHide: AlertOnHide.onlyClose,
                        alertdispatch: alertdispatch,
                    });
                } else {
                    covidCertValueSuccessWorkflow(verifyCovidCertResponse, base64Arr, linkId);
                }
            } else {
                setAnswerAttachment(linkId, base64Arr);
            }
        };

        const handleSaveCollection = (linkId: string) => {
            if (photoPreviewColl.length > 0) {
                uploadSelectedPhotos(linkId, props.buttonType);
            }
            setOpenWebcamPreview(false);
        };

        const handleOpenPreview = () => {
            setOpenWebcamPreview(true);
            setShowOverlay(false);
        };

        const handleRemovePhoto = (photoIndex: number) => {
            const temp = [...photoPreviewColl];
            temp.splice(photoIndex, 1);
            setPreviewColl(temp);
        };

        const handleAddPhotoToPreview = (photo: string) => {
            if (photo) {
                const photoData = photo.split(',').pop();
                if (photoData) {
                    const newPhoto: IPhoto = {
                        name: '',
                        lastModifiedDate: new Date(),
                        size: 0,
                        type: '',
                        data: photoData,
                    };
                    /* use when we support array of photos */
                    // setPreviewColl((photoPreviewColl) => [...photoPreviewColl, newPhoto]); // add new photo to array in hook

                    /* one photo version */
                    const photo: IPhoto[] = [];
                    photo.push(newPhoto);
                    setPreviewColl(photo);
                }
            }
        };

        const popoverId = 'popover_' + props.linkId;
        const formFileId = 'formFile_' + props.linkId;

        return (
            <div className="m-3">
                <WebcamPreviewModal
                    show={openWebcamPreview}
                    onCancel={() => handleClosePreview()}
                    photos={photoPreviewColl}
                    onSave={() => handleSaveCollection(props.linkId)}
                    onRemove={(photoIndex: number) => handleRemovePhoto(photoIndex)}
                    addPhoto={(photo: string) => handleAddPhotoToPreview(photo)}
                />
                <OverlayTrigger
                    trigger="click"
                    show={showOverlay}
                    key="right"
                    placement="right-end"
                    onToggle={() => setShowOverlay(!showOverlay)}
                    overlay={
                        <Popover id={popoverId}>
                            <Popover.Content>
                                <Form.File id={formFileId} custom>
                                    <Button variant="light" block onClick={handleUpload}>
                                        <div style={{ float: 'left' }}>
                                            <FiUpload size={30} type="file" style={{ paddingBottom: '5px' }} />
                                            {props.popOverFileUploadName}
                                        </div>
                                        <input
                                            type="file"
                                            accept={acceptFileType}
                                            ref={inputRef}
                                            onChange={(e) => handleSelectedPhoto(e, props.linkId, props.buttonType)}
                                            hidden
                                        />
                                    </Button>
                                </Form.File>
                                <Button variant="light" block onClick={() => handleOpenPreview()}>
                                    <div style={{ float: 'left' }}>
                                        <BiCamera size={30} style={{ paddingBottom: '5px' }} />
                                        {props.popOverCameraUploadName}
                                    </div>
                                </Button>
                            </Popover.Content>
                        </Popover>
                    }
                >
                    <StyledButton title={props.tooltip} onClick={handleUpload} className="btn btn-outline-primary">
                        {props.buttonName}
                    </StyledButton>
                </OverlayTrigger>
            </div>
        );
    };

    const qTypeButton = (item: IQItem, buttonType: string) => {
        // const className = getExtensionClassValue(item);
        const label = getExtensionLabel(item) !== '' ? getExtensionLabel(item) : '';
        const tooltip = getExtensionTooltip(item) !== '' ? getExtensionTooltip(item) : '';
        // const functionName = getExtensionFunction(item) !== '' ? getExtensionFunction(item) : '';
        let possibleFileType = getExtensionPossibleFileType(item) !== '' ? getExtensionPossibleFileType(item) : '';

        let popOverFileName = 'Foto hochladen ...';
        let popOverCameraName = 'Kamera ...';

        if (buttonType === 'QRSCAN') {
            popOverFileName = 'QR Code File';
            popOverCameraName = 'QR Code Kamera';
            possibleFileType = 'jpg;png;pdf';
        }

        let content = <></>;

        let imageSrc = 'data:image/png;base64,';
        let image = <></>;
        const valueAttachmentAnswer = getAnswerAttachment(item.linkId);
        let showImage = false;
        if (valueAttachmentAnswer) {
            showImage = true;
            if (valueAttachmentAnswer.contentType === 'application/pdf') {
                image = <GrDocumentPdf size={60} />;
            } else {
                if (
                    valueAttachmentAnswer.contentType === 'image/jpg' ||
                    valueAttachmentAnswer.contentType === 'image/jpeg'
                ) {
                    imageSrc = imageSrc.replace('png', 'jpg');
                }
                let imageData = valueAttachmentAnswer.data;
                if (imageData && imageData.includes(',')) {
                    imageData = imageData.split(',')[1];
                }
                imageSrc = imageSrc + imageData;

                image = <Image src={imageSrc} fluid style={{ width: '50mm' }} />;
            }
        }

        const displayImage = (
            <>
                <ImageFloatDiv float="none" direction="column" display="flex" alignItems="flex-start" width="100%">
                    <ImageDiv textAlign="left" float="none" textDirection="left">
                        {image}
                    </ImageDiv>
                </ImageFloatDiv>
            </>
        );

        // content = (
        //     <Form.Group controlId="formFileMultiple" className="mb-3">
        //         <Form.Label>{label}</Form.Label>
        //         <Form.Control type="file" onChange={(e) => handleSelectedPhoto(e, item.linkId)} />
        //     </Form.Group>
        // );

        content = (
            <UploadButton
                linkId={item.linkId}
                buttonName={label}
                tooltip={tooltip}
                possibleFileType={possibleFileType}
                popOverFileUploadName={popOverFileName}
                popOverCameraUploadName={popOverCameraName}
                buttonType={buttonType}
            />
        );

        return (
            <QDiv display={qEnableWhenHandling(item)} key={item.linkId}>
                {content}
                {showImage ? displayImage : null}
            </QDiv>
        );
    };

    interface INoInfoCheckbox {
        item: IQItem;
        labelKA: string;
    }

    const NoInfoCheckbox = (props: INoInfoCheckbox) => {
        const setNoInfoCheckBox = (e: any) => {
            if (e.target.checked) {
                setAnswerIntegerOption(props.item.linkId, NOINFO_CBX_VALUE);
            } else {
                resetSelectedAnswer(props.item.linkId);
            }
        };

        const getNoInfoCheckBox = () => {
            let noInfoCbx = getAnswerIntegerOption(props.item.linkId);
            if (noInfoCbx === undefined) {
                noInfoCbx = getAnswerDecimalOption(props.item.linkId, false);
            }
            if (noInfoCbx && noInfoCbx === NOINFO_CBX_VALUE) {
                return true;
            }
            return false;
        };

        let checkboxRequired = props.item.required ? true : false;
        /* if the form is validate and an item is required, the checkbox turns green after a slider value is selected */
        if (validate && props.item.required) {
            checkboxRequired = isAnswerLinkIdPresent(props.item.linkId).isPresent ? false : true;
        }

        /* True if answer is required and there is no answer given and the "Weiter"-Button has been clicked (=validate) */
        const invalid =
            props.item.required && !isAnswerLinkIdPresent(props.item.linkId).isPresent && validate ? true : false;

        return (
            <div key={`cbxNoInfo-${props.item.linkId}`} style={{ paddingLeft: qDisplayBorderPadding }}>
                <Form.Group>
                    <Form.Check
                        type="checkbox"
                        required={checkboxRequired}
                        label={props.labelKA}
                        id={`cbx-noinfo-${props.item.linkId}`}
                        checked={getNoInfoCheckBox()}
                        inline
                        onChange={(e) => setNoInfoCheckBox(e)}
                        isInvalid={invalid}
                    ></Form.Check>
                </Form.Group>
            </div>
        );
    };

    const questions = () => {
        const allItems: any[] = [];
        qstate.selectedQuestionnaire.resource.item[actualStep - 1].item?.forEach((item: IQItem) => {
            if (item.type === 'choice') {
                allItems.push(qTypeChoice(item));
            } else if (item.type === 'integer') {
                allItems.push(qTypeInteger(item));
            } else if (item.type === 'decimal') {
                allItems.push(qTypeDecimal(item));
            } else if (item.type === 'open-choice') {
                allItems.push(qTypeOpenChoice(item));
            } else if (item.type === 'date') {
                allItems.push(qTypeDate(item));
            } else if (item.type === 'time') {
                allItems.push(qTypeTime(item));
            } else if (item.type === 'dateTime') {
                allItems.push(qTypeDateTime(item));
            } else if (item.type === 'display') {
                allItems.push(qTypeDisplay(item));
            } else if (item.type === 'text') {
                allItems.push(qTypeText(item));
            } else if (item.type === 'string') {
                allItems.push(qTypeString(item));
            } else if (item.type === 'boolean') {
                allItems.push(qTypeBoolean(item));
            } else if (item.type === 'attachment') {
                if ('button' === getExtensionClassValue(item)) {
                    let buttonType = '';
                    if (
                        qstate.selectedQuestionnaire.resource.id === 'VACCINATION_CERTIFICATE' ||
                        qstate.selectedQuestionnaire.resource.id === 'TEST_CERTIFICATE'
                    ) {
                        buttonType = 'QRSCAN';
                    }
                    allItems.push(qTypeButton(item, buttonType));
                } else if ('signature' === getExtensionClassValue(item)) {
                    allItems.push(qTypeSignature(item));
                } else {
                    allItems.push(qTypeAttachment(item));
                }
            }
        });
        return allItems;
    };

    const getPostStoreDocumentPDF = async () => {
        if (!pdfModalShow.show) {
            const response = await getDiagnosticReport(state.sessionId, postStoreDocumentRefId);
            const [modalTitle, pdfsrc, isSignable, isSigned] = getPDFDataFromDiagnosticReportResponse(response, '', '');

            setPdfModalShow({
                show: true,
                modalTitle: modalTitle,
                isSignable: isSignable === 'true' ? true : false,
                isSigned: isSigned === 'true' ? true : false,
                pdfData: <IFramePDF src={pdfsrc} />,
                diagnosticReportData: response.data,
            });
        }
    };

    const qContent = () => {
        /* questionnaire send correctly */
        if (questionnaireSendResponse.startsWith('SUCCESS')) {
            let showPdf = false;
            let responseMessage = '';
            if (questionnaireSendResponse.startsWith('ERROR')) {
                responseMessage = 'Fehler beim versenden: ' + questionnaireSendResponse;
            } else {
                if (postStoreDocumentRefId !== '') {
                    // console.log('postStoreDocumentRefId: ', postStoreDocumentRefId);
                    showPdf = true;
                    getPostStoreDocumentPDF();
                    // const response = await getDiagnosticReport(state.sessionId, postStoreDocumentRefId);
                    // const [modalTitle, pdfsrc] = getPDFDataFromDiagnosticReportResponse(response, '', '');

                    // showPdf = true;
                    // setPdfModalShow({
                    //     show: true,
                    //     modalTitle: modalTitle,
                    //     pdfData: <IFramePDF src={pdfsrc} />,
                    // });
                }
                responseMessage = 'Der Fragebogen wurde erfolgreich gesendet';
            }

            if (showPdf) {
                return <></>;
            } else {
                return (
                    <>
                        <CenterDiv marginTop="30px" style={{ overflowX: 'hidden' }}>
                            <Row>
                                <Col>
                                    <h2>{responseMessage}</h2>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Lottie options={lottieCheck} height={100} width={100} />
                                </Col>
                            </Row>
                        </CenterDiv>
                        <Container className="mt-4 mb-4">
                            <Row>
                                <Col sm={{ offset: 10 }}>
                                    <StyledButton onClick={() => handleButtonFinish()}>Fertig</StyledButton>
                                </Col>
                            </Row>
                        </Container>
                    </>
                );
            }
        } else {
            /* questionnaiere formular (not send or not send correctly) */
            let responseMessage = '';
            if (questionnaireSendResponse.startsWith('ERROR')) {
                responseMessage = 'Fehler beim versenden: ' + questionnaireSendResponse;
            }

            return (
                <>
                    <CenterDiv marginTop="30px" style={{ overflowX: 'hidden' }}>
                        <Row>
                            <QStepper />
                        </Row>
                        <Row>
                            <Container style={{ overflowX: 'auto' }}>
                                <LeftDiv>
                                    <Form noValidate>
                                        {qstate.selectedQuestionnaire.resource.extension &&
                                        qstate.selectedQuestionnaire.resource.extension[0].valueString === 'twoCol' ? (
                                            <div className="twoColumns" style={{ width: '100%', paddingTop: '40px' }}>
                                                {questions()}
                                            </div>
                                        ) : (
                                            <div style={{ width: '100%', paddingTop: '40px' }}>{questions()}</div>
                                        )}
                                    </Form>
                                </LeftDiv>
                            </Container>
                        </Row>
                        <Row>{errorResponseMessage(responseMessage)}</Row>
                    </CenterDiv>
                    <Container className="mt-4 mb-4">
                        <Row>{requiredMessage()}</Row>
                        <Row>
                            <Col xs={4} sm={4} md={6} lg={6} xl={8}>
                                <LeftDiv>
                                    <StyledButton variant="secondary" onClick={() => handleButtonCancel()}>
                                        Abbrechen
                                    </StyledButton>
                                </LeftDiv>
                            </Col>
                            <Col>
                                <RightDiv>
                                    <StyledButton
                                        disabled={actualStep === 1}
                                        onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                                            handleButtonPreviousStep(e)
                                        }
                                    >
                                        Zurück
                                    </StyledButton>
                                </RightDiv>
                            </Col>
                            <Col>
                                <RightDiv>
                                    <StyledButton
                                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleButtonNextStep(e)}
                                    >
                                        {actualStep === steps.length ? 'Absenden' : 'Weiter'}
                                    </StyledButton>
                                </RightDiv>
                            </Col>
                        </Row>
                    </Container>
                </>
            );
        }
    };

    const questionnaireMainPage = () => {
        const disableTempStorage = isDisableTempStorage(qstate.selectedQuestionnaire.resource);

        return (
            <Container fluid id="QuestionnaireMainId">
                <Row ref={topRef}></Row>
                <StickyHeaderTitleDiv>
                    <MainHeaderRow noGutters>
                        <MainHeaderCol></MainHeaderCol>
                        <MainHeaderCol></MainHeaderCol>
                        <MainHeaderCol xs={8}>
                            {isMobile(screenSize.width) ? (
                                <OverlayTrigger
                                    placement="bottom"
                                    transition={false}
                                    overlay={
                                        <Tooltip id={`mainheader-tooltip`}>
                                            {qstate.selectedQuestionnaire.resource.title}
                                        </Tooltip>
                                    }
                                >
                                    <MainH4Title>{qstate.selectedQuestionnaire.resource.title}</MainH4Title>
                                </OverlayTrigger>
                            ) : null}
                            {isTablet(screenSize.width) ? (
                                <OverlayTrigger
                                    placement="bottom"
                                    transition={false}
                                    overlay={
                                        <Tooltip id={`mainheader-tooltip`}>
                                            {qstate.selectedQuestionnaire.resource.title}
                                        </Tooltip>
                                    }
                                >
                                    <MainH3Title>{qstate.selectedQuestionnaire.resource.title}</MainH3Title>
                                </OverlayTrigger>
                            ) : null}
                            {isDesktop(screenSize.width) ? (
                                <OverlayTrigger
                                    placement="bottom"
                                    transition={false}
                                    overlay={
                                        <Tooltip id={`mainheader-tooltip`}>
                                            {qstate.selectedQuestionnaire.resource.title}
                                        </Tooltip>
                                    }
                                >
                                    <MainH2Title>{qstate.selectedQuestionnaire.resource.title}</MainH2Title>
                                </OverlayTrigger>
                            ) : null}
                        </MainHeaderCol>
                        <Col>
                            <div style={{ position: 'absolute', right: '10px', paddingTop: '10px' }}>
                                <ConfirmationToast
                                    show={showSaveToast}
                                    onHide={() => setShowSaveToast(false)}
                                    text="In Dokumente gespeichert!"
                                    delay={2500}
                                />
                            </div>
                            <div style={{ position: 'absolute', right: '10px', paddingTop: '10px' }}>
                                <ErrorToast
                                    show={showErrorToast}
                                    onHide={() => setShowErrorToast(false)}
                                    // text="Leere Fragebögen werden nicht gespeichert!"
                                    text="Fragebogen konnte nicht gespeichert werden! (Fehlende oder ungültige Angaben)"
                                    delay={2500}
                                    height="150px"
                                />
                            </div>
                        </Col>
                        {!disableTempStorage && (
                            <MainHeaderButtonCol>
                                <OverlayTrigger
                                    key="saveQ"
                                    placement="bottom"
                                    overlay={<Tooltip id={`tooltip-$"bottom"`}>Zwischenspeichern</Tooltip>}
                                >
                                    <MainHeaderButtonIcon id="intermediateSave" onClick={() => handleQSave()}>
                                        <FaRegSave
                                            size={themeContext.icon.size}
                                            color={themeContext.header.main.buttonColor}
                                            strokeWidth={themeContext.icon.strokeWidth}
                                        />
                                    </MainHeaderButtonIcon>
                                </OverlayTrigger>
                            </MainHeaderButtonCol>
                        )}
                    </MainHeaderRow>
                </StickyHeaderTitleDiv>
                <CenterDiv>{qContent()}</CenterDiv>
                <PDFModal
                    show={pdfModalShow.show}
                    onHide={() => handleClosePDFInternal()}
                    modalTitle={pdfModalShow.modalTitle}
                    isSignable={pdfModalShow.isSignable}
                    isSigned={pdfModalShow.isSigned}
                    pdfData={pdfModalShow.pdfData}
                    diagnosticReportData={pdfModalShow.diagnosticReportData}
                />
                <ForeignQRModal
                    show={showForeignQRModal.show}
                    onHide={() => handleCloseForeignQRModal()}
                    onSubmit={() => handleSubmitForeignQRModal()}
                />
            </Container>
        );
    };

    if (props.type === 'Questionnaire') {
        if (isExternalQuest) {
            if (pdfModalShow.show) {
                return (
                    <PDFModal
                        show={pdfModalShow.show}
                        onHide={() => handleClosePDFExternalView()}
                        modalTitle={pdfModalShow.modalTitle}
                        isSignable={pdfModalShow.isSignable}
                        pdfData={pdfModalShow.pdfData}
                        diagnosticReportData={pdfModalShow.diagnosticReportData}
                    />
                );
            } else {
                return questionnaireMainPage();
            }
        } else {
            return null;
        }
    } else {
        return <Mainscreen>{questionnaireMainPage()}</Mainscreen>;
    }
};

export default FragebogenDetail;
