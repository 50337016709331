import { useContext } from 'react';
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger';
import Tooltip from 'react-bootstrap/esm/Tooltip';
import { BiVolumeFull, BiVolumeMute } from 'react-icons/bi';
import { ThemeContext } from 'styled-components';
import VideochatContext from '../../context/videochatContext';
import { IconDiv } from '../Div/StyledDiv';
import { StyledParagraphVerticallyCenter } from '../StyledParagraph/StyledParagraph';

const VolumeIcon = () => {
    const themeContext = useContext(ThemeContext);
    const { vsstate, vsdispatch } = useContext(VideochatContext);

    const volumeTooltip = () => {
        return (
            <Tooltip id={`tooltip-bottom`}>
                {vsstate.audioMuted ? 'Klingelton einschalten' : 'Klingelton ausschalten'}
            </Tooltip>
        );
    };

    const handleVolumeClick = () => {
        vsdispatch({
            type: 'SETAUDIOMUTED',
            audioMuted: !vsstate.audioMuted,
        });
    };

    return (
        <div style={{ display: 'flex' }}>
            {vsstate.audioMuted ? (
                <StyledParagraphVerticallyCenter margin={'auto 5px'} color={themeContext.icon.neg.color}>
                    Klingelton einschalten
                </StyledParagraphVerticallyCenter>
            ) : null}
            <OverlayTrigger key="bottom" placement="bottom" overlay={volumeTooltip()}>
                <IconDiv onClick={handleVolumeClick}>
                    {vsstate.audioMuted ? (
                        <BiVolumeMute size={35} color={themeContext.icon.neg.color} style={{ paddingTop: 5 }} />
                    ) : (
                        <BiVolumeFull size={35} color={themeContext.icon.default.color} style={{ paddingTop: 5 }} />
                    )}
                </IconDiv>
            </OverlayTrigger>
        </div>
    );
};

export default VolumeIcon;
