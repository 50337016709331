import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { ViewCallbackProperties } from 'react-calendar';
import 'react-calendar/dist/Calendar.css'; //default css of react-calendar -> the StyledCalendar component override some of this
import AlertContext, { AlertOnHide, AlertType } from '../../context/alertContext';
import LoginContext from '../../context/loginContext';
import TerminierungContext, { IAvailableTimeslot } from '../../context/terminierungContext';
import { useAppointmentSelection } from '../../hooks/useAppointmentSelection';
import { getPossibleTimelist, searchAppointments } from '../../services/RestServices';
import {
    IFreeAppointmentSearch,
    getFreeAppointmentTimeSlots,
    getFreeAppointmentTimeSlotsRenew,
} from '../../services/fhir/FHIRAppointment';
import { showAlertMessage } from '../../utils/alertHandling';
import { APPOINTMENT_POSTPONE_REASON } from '../../utils/appointmentUtils';
import { simulateClick } from '../../utils/generalUtils';
import { isTimeslotDateSet } from '../Calendar/TimeSlots';
import StyledCalendar from './StyledCalendar';
import './StyledCalendar.css'; // it is not possible to style all with StyledComponents, then use this .css to style globally

interface ICalendar {
    setSelectedDay: any;
}
interface IFreeAppointments {
    resource: any;
    date: string;
    // timeslots: ITimeslot[];
    selectedDay: string;
}

export const onNewAppointments = (possibleTimelist: any, appointmentNr: number, setAvailableTimeslots: any) => {
    const availableTimeSlots = possibleTimelist?.extension.map((rootExtension: any) => {
        const container = {} as IAvailableTimeslot;
        rootExtension.extension.map((subExtension: any) => {
            if (subExtension.url.endsWith('timeSlot')) {
                container.timeslot = subExtension.valuePeriod;
            }
            if (subExtension.url.endsWith('replacedRoom')) {
                container.replacedRoom = subExtension.valueReference;
            }
        });
        return container;
    });

    setAvailableTimeslots(appointmentNr, availableTimeSlots);

    // if (tmstate.workOnAppointmentNr > 1) {
    //     tmdispatch({
    //         type: 'SETAVAILABLETIMESLOTS_2',
    //         availableTimeslots_2: availableTimeSlots,
    //     });
    // } else {
    //     tmdispatch({
    //         type: 'SETAVAILABLETIMESLOTS',
    //         availableTimeslots: availableTimeSlots,
    //     });
    // }
};

const Calendar = (props: ICalendar) => {
    const { tmstate } = useContext(TerminierungContext);
    const { alertdispatch } = useContext(AlertContext);
    const { setAvailableTimeslots, setSelectedResource, resetAllAvailableTimeslots, getSelectedTimeslot } =
        useAppointmentSelection();

    const getFirstDayOfMonth = (date?: Date) => {
        const dateToday = moment(date).format('yyyy-MM-DD');
        const firstDayOfMonth = dateToday.substring(0, 8).concat('01');
        return firstDayOfMonth;
    };

    const getMonthFromDate = (date?: Date) => {
        const month = moment(date).format('MM');
        return month;
    };

    const getMonthFromStrFormat = (date?: string) => {
        const month = date?.substring(5, 7);
        return month;
    };

    const addPatientIdToResource = (resource: any) => {};

    /* #### getFreeAppointments #### */
    const { state } = useContext(LoginContext);
    const [firstDayOfMonth, setFirstDayOfMonth] = useState(getFirstDayOfMonth());
    const [freeAppointments, setFreeAppointments] = useState<IFreeAppointments[]>();
    const [initMonthDone, setInitMonthDone] = useState(false);

    useEffect(() => {
        const getFreeAppointments = async (freeAppointmentSearch: IFreeAppointmentSearch) => {
            let response;
            if (tmstate.withLogin) {
                if (tmstate.renewAppointment) {
                    response = await getFreeAppointmentTimeSlotsRenew(state, tmstate, freeAppointmentSearch);
                } else {
                    response = await getFreeAppointmentTimeSlots(state, tmstate, freeAppointmentSearch);
                }
            } else {
                response = await searchAppointments(freeAppointmentSearch);
                response.data = response;
            }

            if (response?.data?.entry) {
                const freeAppointments = response?.data.entry.map((item: any, index: number) => {
                    const container: IFreeAppointments = {
                        resource: '',
                        date: '',
                        selectedDay: '',
                    };

                    const dateFormated = moment(item.resource.start).format('yyyy-MM-DD');
                    if (isTimeslotDateSet(getSelectedTimeslot(1)) && !initMonthDone) {
                        if (index === 0) {
                            setFirstDayOfMonth(item.resource.start);
                            setInitMonthDone(true);
                        }
                    }

                    /* add patient id to resource if undefined */
                    if (item.resource.participant !== undefined) {
                        item.resource.participant.forEach((p) => {
                            if (p.actor?.reference) {
                                if (p.actor.reference === 'Patient/unknown') {
                                    if (state.userId) {
                                        p.actor.reference = 'Patient/'.concat(state.userId);
                                    }
                                }
                            }
                        });
                    }

                    container.resource = item.resource;
                    container.date = dateFormated;

                    return container;
                });

                setFreeAppointments(freeAppointments);
            } else {
                /*no free appointments this month, try next month (but only 3 months in future)*/
                const dateToday = moment();
                const firstDayOf3MonthsFuture = dateToday.add(3, 'month');
                const search1MonthLater = moment(firstDayOfMonth).add(1, 'month');

                if (search1MonthLater.isBefore(firstDayOf3MonthsFuture)) {
                    const newSearchDate = moment(search1MonthLater).format('yyyy-MM-DD');
                    const firstDayOfMonthNewSearch = newSearchDate.substring(0, 8).concat('01');
                    setFirstDayOfMonth(firstDayOfMonthNewSearch);
                } else {
                    showAlertMessage({
                        alertTitle: 'Terminplanung',
                        alertTxt: 'Keine freien Termine in den nächsten 3 Monaten',
                        alertType: AlertType.info,
                        onHide: AlertOnHide.onlyClose,
                        alertdispatch: alertdispatch,
                    });
                }
            }
        };

        const dateSearch = 'gt'.concat(firstDayOfMonth);
        // // let appSearchPattern: IFreeAppointmentSearch = {
        // //     makroId: macroNameToId(tmstate.selectedCategory.name),
        // //     date: dateSearch,
        // // };

        let makroId = '0';
        if (tmstate.selectedReason.makroId > 0) {
            makroId = tmstate.selectedReason.makroId.toString();
        } else {
            makroId = tmstate.selectedCategory.id.toString();
        }
        let physicianId = '';
        if (tmstate.selectedPhysician?.id > 0) {
            physicianId = tmstate.selectedPhysician.id.toString();
        }
        const appSearchPattern: IFreeAppointmentSearch = {
            makroId: makroId,
            date: dateSearch,
            physicianId: physicianId,
        };

        if (tmstate.renewAppointment) {
            getFreeAppointments(appSearchPattern);
        } else {
            if (tmstate.workOnAppointmentNr <= tmstate.selectedCategory.countAPKs) {
                if (
                    tmstate.cancelNewAppointmentData.reason === APPOINTMENT_POSTPONE_REASON &&
                    appSearchPattern.makroId === '0'
                ) {
                    let serviceType;
                    if (tmstate.cancelNewAppointmentData) {
                        serviceType = tmstate.cancelNewAppointmentData.data.serviceType;
                    } else {
                        serviceType = tmstate.enrollData.fhir.serviceType;
                    }
                    const c0 = serviceType[0].coding[0];
                    let makroIdPostponeAppoinment = '';
                    if (c0) {
                        if (c0.extension) {
                            makroIdPostponeAppoinment = c0.extension[0].valueInteger.toString();
                        } else {
                            makroIdPostponeAppoinment = c0.id;
                        }
                    }
                    appSearchPattern.makroId = makroIdPostponeAppoinment;
                }
                getFreeAppointments(appSearchPattern);
            }
        }

        return () => {
            setFreeAppointments([]);
            props.setSelectedDay('');
            // tmdispatch({
            //     type: 'RESETAVAILABLETIMESLOTS',
            // });
            // tmdispatch({
            //     type: 'RESETAVAILABLETIMESLOTS_2',
            // });
            resetAllAvailableTimeslots();
        };
    }, [firstDayOfMonth, tmstate.workOnAppointmentNr]);

    useEffect(() => {
        /* find earliest day and click */
        try {
            const oneYearLater = moment().add(1, 'year');
            let earliestDay = moment().add(1, 'year');
            if (freeAppointments) {
                freeAppointments.forEach((item: any) => {
                    if (item.date) {
                        const dateFormated = moment(item.date, 'yyyy-MM-DD');
                        if (dateFormated.isBefore(earliestDay)) {
                            earliestDay = dateFormated;
                        }
                    }
                });

                if (earliestDay.isBefore(oneYearLater)) {
                    const earliestDayFormated = moment(earliestDay).format('LL');
                    if (earliestDayFormated) {
                        const dateButtonLabel = document.querySelector(
                            'abbr[aria-label="' + earliestDayFormated + '"]',
                        );
                        if (dateButtonLabel) {
                            const dateButton = dateButtonLabel.parentElement;
                            simulateClick(dateButton);
                        }
                    }
                }
            }
        } catch (error) {
            console.log('problems to auto click earlieast date', error);
        }
    }, [freeAppointments]);

    /* #### calender workflow #### */

    const isSameDay = (dDate: string, referenceDate: string) => {
        if (dDate === referenceDate) {
            return true;
        }
    };

    const tileDisabled = ({ date, view }: any): any => {
        const dateFormated = moment(date).format('yyyy-MM-DD');
        if (view === 'month') {
            // Check if a date React-Calendar wants to check is on the list of disabled dates
            // return disabledDates.find((dDate) => isSameDay(dDate, dateFormated.toString()));
            return !freeAppointments
                ?.map((item) => item.date)
                .find((dDate) => isSameDay(dDate, dateFormated.toString()));
        }
    };

    const onChange = (value: Date) => {
        const firstDayOfClickedMonth = getFirstDayOfMonth(value);
        setFirstDayOfMonth(firstDayOfClickedMonth);
    };

    const onClickDay = async (value: Date) => {
        const dateFormated = moment(value).format('yyyy-MM-DD');
        const selectedDay = freeAppointments?.find((item) => item.date === dateFormated);
        const possibleTimelist = await getPossibleTimelist(selectedDay?.resource);

        const availableTimeSlots = possibleTimelist?.extension.map((rootExtension: any) => {
            // let container = {};
            const container = {} as IAvailableTimeslot;
            rootExtension.extension.map((subExtension: any) => {
                if (subExtension.url.endsWith('timeSlot')) {
                    container.timeslot = subExtension.valuePeriod;
                }
                if (subExtension.url.endsWith('replacedRoom')) {
                    container.replacedRoom = subExtension.valueReference;
                }
            });
            return container;
        });

        // if (tmstate.workOnAppointmentNr > 1) {
        //     tmdispatch({
        //         type: 'SETAVAILABLETIMESLOTS_2',
        //         availableTimeslots_2: availableTimeSlots,
        //     });
        //     props.setSelectedDay(dateFormated);
        //     tmdispatch({
        //         type: 'SETRESOURCE_2',
        //         selectedResource_2: selectedDay?.resource,
        //     });
        //     tmdispatch({
        //         type: 'SETRESOURCE',
        //         appointmentNr: tmstate.workOnAppointmentNr,
        //         selectedResource: selectedDay?.resource,
        //     });
        // } else {
        //     tmdispatch({
        //         type: 'SETAVAILABLETIMESLOTS',
        //         availableTimeslots: availableTimeSlots,
        //     });
        //     props.setSelectedDay(dateFormated);
        //     tmdispatch({
        //         type: 'SETRESOURCE',
        //         selectedResource: selectedDay?.resource,
        //     });
        // }

        let supressDuration = false;
        if (possibleTimelist.modifierExtension) {
            for (const ext of possibleTimelist.modifierExtension) {
                if (ext.url == 'http://www.principa.siegele-software.com/suppressDuration' && ext.valueBoolean) {
                    supressDuration = true;
                }
            }
        }
        setAvailableTimeslots(tmstate.workOnAppointmentNr, availableTimeSlots, supressDuration);
        props.setSelectedDay(dateFormated);
        setSelectedResource(tmstate.workOnAppointmentNr, selectedDay?.resource);
    };

    const onActiveStartDateChange = ({ activeStartDate }: ViewCallbackProperties) => {
        const month_selectedDay = getMonthFromDate(activeStartDate);
        const month_firstDayOfMonth = getMonthFromStrFormat(firstDayOfMonth);
        if (month_selectedDay !== month_firstDayOfMonth) {
            onChange(activeStartDate);
        }
    };

    return (
        <div>
            <StyledCalendar
                onClickDay={onClickDay}
                onActiveStartDateChange={onActiveStartDateChange}
                activeStartDate={new Date(firstDayOfMonth)}
                tileDisabled={tileDisabled}
                showDoubleView={false}
                prev2Label={null}
                next2Label={null}
                showNeighboringMonth={false}
            />
        </div>
    );
};

export default Calendar;
