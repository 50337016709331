import { AlertOnHide, AlertType } from '../context/alertContext';

export interface IAlertMessageWithoutDispatch {
    alertTitle: string;
    alertTxt: string;
    alertType: AlertType;
    onHide: AlertOnHide;
    onHideCustom?: any;
}
export interface IAlertMessage extends IAlertMessageWithoutDispatch {
    alertdispatch: React.Dispatch<any>;
}

export const showAlertMessage = (props: IAlertMessage) => {
    props.alertdispatch({
        type: 'SETSHOWALERTMESSAGE',
        alertMessage: {
            showModal: true,
            alertTitle: props.alertTitle,
            alertTxt: props.alertTxt,
            alertType: props.alertType,
            onHide: props.onHide,
            onHideCustom: props.onHideCustom,
        },
    });
};

export const resetShowAlertMessage = (alertdispatch: any) => {
    alertdispatch({
        type: 'RESETSHOWALERTMESSAGE',
    });
};
