import { Button } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import styled from 'styled-components';
import { appointmentStatus } from '../../globals/global';
import { ParagraphFontSize } from '../StyledParagraph/StyledParagraph';

export const StyledBadge = styled(Badge)`
    align-self: right;
    border-width: ${(props) => (props.borderwidth ? props.borderwidth : 'thin')};
    border-radius: 10px;
    border-style: solid;
    border-color: ${(props) =>
        props.status === 'cancelled' || props.status === appointmentStatus.enteredInError
            ? props.theme.badge.neg.borderColor
            : 'black'};
    color: ${(props) =>
        props.status === 'cancelled' || props.status === appointmentStatus.enteredInError
            ? props.theme.badge.neg.color
            : 'black'};
    font-weight: ${(props) => (props.status === appointmentStatus.enteredInError ? 'bold' : 'normal')};
    font-size: 14px;
`;

export const Styled2FABadge = styled(Badge)`
    align-self: right;
    border-width: thin;
    border-radius: 10px;
    border-style: solid;
    border-color: ${(props) =>
        props.status === 'deaktiviert' ? props.theme.badge.neg.borderColor : props.theme.badge.pos.borderColor};
    color: ${(props) => (props.status === 'deaktiviert' ? props.theme.badge.neg.color : props.theme.badge.pos.color)};
    font-weight: bold;
    font-size: 14px;
`;

interface ICategoryBadge {
    backgroundColor?: string;
    backgroundColorSelected?: string;
    color?: string;
    colorSelected?: string;
    marginRight?: string;
    marginBottom?: string;
    fontSize?: string | ParagraphFontSize;
}

export const CategoryBadge = styled(Button)<ICategoryBadge>`
    border-radius: 50px;
    background-color: ${(props) => (props.$backgroundColor ? props.$backgroundColor : null)};
    border-color: ${(props) => (props.$backgroundColor ? props.$backgroundColor : null)};
    color: ${(props) => (props.color ? props.color : null)};
    margin-right: ${(props) => (props.$marginRight ? props.$marginRight : null)};
    margin-bottom: ${(props) => (props.$marginBottom ? props.$marginBottom : null)};
    font-size: ${(props) => (props.fontSize ? props.fontSize : null)};
    &:hover {
        background-color: ${(props) => (props.$backgroundColorSelected ? props.$backgroundColorSelected : null)};
        border-color: ${(props) => (props.$backgroundColorSelected ? props.$backgroundColorSelected : null)};
        color: ${(props) => (props.$colorSelected ? props.$colorSelected : null)};
    }
    &:focus {
        background-color: ${(props) => (props.$backgroundColorSelected ? props.$backgroundColorSelected : null)};
        border-color: ${(props) => (props.$backgroundColorSelected ? props.$backgroundColorSelected : null)};
        color: ${(props) => (props.$colorSelected ? props.$colorSelected : null)};
    }
    &:hover:focus:active {
        background-color: ${(props) => (props.$backgroundColorSelected ? props.$backgroundColorSelected : null)};
        border-color: ${(props) => (props.$backgroundColorSelected ? props.$backgroundColorSelected : null)};
        color: ${(props) => (props.$colorSelected ? props.$colorSelected : null)};
    }
    &:active {
        background-color: ${(props) => (props.$backgroundColorSelected ? props.$backgroundColorSelected : null)};
        border-color: ${(props) => (props.$backgroundColorSelected ? props.$backgroundColorSelected : null)};
        color: ${(props) => (props.$colorSelected ? props.$colorSelected : null)};
    }
`;

interface ICategoryBadgeSelected {
    backgroundColor?: string;
    color?: string;
    marginLeft?: string;
    marginRight?: string;
    marginBottom?: string;
    fontSize?: string | ParagraphFontSize;
}

export const CategoryBadgeSelected = styled(Badge)<ICategoryBadgeSelected>`
    border-radius: 50px;
    background-color: ${(props) => (props.$backgroundColor ? props.$backgroundColor : null)};
    border-color: ${(props) => (props.$backgroundColor ? props.$backgroundColor : null)};
    color: ${(props) => (props.color ? props.color : null)};
    margin-left: ${(props) => (props.$marginLeft ? props.$marginLeft : null)};
    margin-right: ${(props) => (props.$marginRight ? props.$marginRight : null)};
    margin-bottom: ${(props) => (props.$marginBottom ? props.$marginBottom : null)};
    font-size: ${(props) => (props.fontSize ? props.fontSize : null)};
`;

interface IBadgeWithWordWrap {
    padding?: string;
}

export const BadgeWithWordWrap = styled(Badge)<IBadgeWithWordWrap>`
    white-space: normal;
    text-align: left;
    padding: ${(props) => (props.padding ? props.padding : null)};
`;
