import { createContext } from 'react';

export enum AlertOnHide {
    onlyClose = 1,
    goToHome,
    custom,
}

export enum AlertType {
    error,
    info,
}

export type InitialAlertType = {
    alertMessage: {
        showModal: boolean;
        alertTitle: string;
        alertTxt: string;
        alertType: AlertType;
        onHide: AlertOnHide;
        onHideCustom: any;
    };
};

export const initialAlertState = {
    alertMessage: {
        showModal: false,
        alertTitle: '',
        alertTxt: '',
        alertType: AlertType.info,
        onHide: AlertOnHide.onlyClose,
        onHideCustom: undefined,
    },
};

const AlertContext = createContext<{
    alertstate: InitialAlertType;
    alertdispatch: React.Dispatch<any>;
}>({
    alertstate: initialAlertState,
    alertdispatch: () => null,
});

export default AlertContext;
