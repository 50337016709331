import moment from 'moment';
import * as React from 'react';
import { AlertOnHide, AlertType } from '../../context/alertContext';
import { InitialLoginType } from '../../context/loginContext';
import { responseErrorHandling } from '../../utils/errorHandling';
import { IFile } from '../../utils/fileUtils';
import { IPhoto } from '../../utils/photoUtils';
import { getFHIRConfig } from './fhirConstants';

const fhir = require('fhir.js');

const DIAGNOSTIC_REPORT = 'DiagnosticReport';

export interface IDRResource {
    effectiveDateTime: string;
    status: string;
    category: {
        coding: [
            {
                code: string;
                display: string;
                system: string;
            },
        ];
    };
    code: {
        coding: [
            {
                code: string;
                display: string;
                system: string;
            },
        ];
    };
    context: {
        reference: string;
    };
    id: string;
    basedOn: [
        {
            reference: string;
        },
    ];
}

export interface IDRDataEntry {
    resource: IDRResource;
}

export const getDiagnosticReportsOfPatient = async (state: InitialLoginType, alertdispatch: React.Dispatch<any>) => {
    return await fhir(getFHIRConfig(state.sessionId))
        .search({
            type: DIAGNOSTIC_REPORT,
            query: {
                patient: 'Patient/' + state.activeUserId,
                // _sort: '-date',
            },
        })
        .catch(function (e: any) {
            responseErrorHandling({
                response: e,
                alertdispatch: alertdispatch,
            });
        });
};

export const getDiagnosticReportsByCategory = async (
    state: InitialLoginType,
    category: string,
    alertdispatch: React.Dispatch<any>,
) => {
    return await fhir(getFHIRConfig(state.sessionId))
        .search({
            type: DIAGNOSTIC_REPORT,
            query: {
                patient: 'Patient/' + state.activeUserId,
                category: category,
                _targetDevice: 'WEB',
                // _sort: '-date',
            },
        })
        .catch(function (e: any) {
            responseErrorHandling({
                response: e,
                alertdispatch: alertdispatch,
            });
        });
};

export const getDiagnosticReport = async (sessionId: string, documentId: string) => {
    return await fhir(getFHIRConfig(sessionId)).read({
        type: DIAGNOSTIC_REPORT,
        id: documentId,
    });
};

/* not used yet, but can be used if we want to group the documents in day sections */
export const getSectionedReports = (reports: IDRDataEntry[]) => {
    const result: any[] = [];

    const reportsMap = new Map();

    reports?.map((report) => {
        const date = moment(report.resource.effectiveDateTime).format('DD.MM.YYYY');

        let arr = reportsMap.get(date);
        if (arr) {
            arr.push(report.resource);
        } else {
            arr = [report.resource];
        }
        reportsMap.set(date, arr);
    });

    const mapIter = reportsMap[Symbol.iterator]();

    for (const item of mapIter) {
        result.push({ key: item[0], data: item[1] });
    }

    return result;
};

export const uploadPDF = async (
    state: InitialLoginType,
    file: IFile,
    title: string,
    conclusion: string,
    alertdispatch: React.Dispatch<any>,
) => {
    const dataTrim = file.data.split('base64,')[1];

    const dataResource = {
        resourceType: DIAGNOSTIC_REPORT,
        subject: { reference: 'Patient/' + state.activeUserId },
        conclusion: conclusion,
        effectiveDateTime: new Date(),
        presentedForm: [
            {
                contentType: 'application/pdf',
                data: dataTrim,
                title: title,
            },
        ],
    };

    return await fhir(getFHIRConfig(state.sessionId))
        .create({
            resource: dataResource,
        })
        .catch(function (e: any) {
            responseErrorHandling({
                response: e,
                alertdispatch: alertdispatch,
                alertMessage: {
                    alertTitle: 'Upload PDF',
                    alertTxt: 'Upload Fehler:' + e.status,
                    alertType: AlertType.error,
                    onHide: AlertOnHide.onlyClose,
                },
            });
            // console.log('An error happened while uploadPDF: \n', e);
        });
};

export const sendPhotoDiagnosticReport = async (
    state: InitialLoginType,
    imageArr: IPhoto[],
    alertdispatch: React.Dispatch<any>,
) => {
    const userId = state.activeUserId;
    const sessionId = state.sessionId;

    const presentedForm: any[] = [];

    imageArr.forEach((img: IPhoto, index: number) => {
        let name = 'Bild ' + index;
        let contentType = 'image/jpeg';
        if (img.name && img.name.length > 0) {
            name = img.name;
        }
        if (img.type && img.type.length > 0) {
            contentType = img.type;
        }
        presentedForm.push({
            contentType: contentType,
            data: img.data,
            title: name,
        });
    });

    const photoResource = {
        resourceType: DIAGNOSTIC_REPORT,
        meta: { versionId: '1', lastUpdated: new Date() },
        status: 'final',
        category: {
            coding: [{ system: 'http://www.principa.com', code: 'CD', display: 'CommonData' }],
        },
        code: {
            coding: [
                {
                    system: 'http://www.principa.com',
                    code: 'PHOTODOK',
                    display: 'Fotodokumentation',
                },
            ],
        },
        subject: { reference: 'Patient/' + userId },
        effectiveDateTime: new Date(),
        context: { reference: 'Encounter/' + userId + (state.encounterId ? '_' + state.encounterId : '_null') },
        presentedForm,
    };

    return await fhir(getFHIRConfig(sessionId))
        .create({
            resource: photoResource,
        })
        .catch(function (e: { status: string }) {
            responseErrorHandling({
                response: e,
                alertdispatch: alertdispatch,
                alertMessage: {
                    alertTitle: 'Fotodokumentation',
                    alertTxt: 'Fotodokumentation senden Fehler:' + e.status,
                    alertType: AlertType.error,
                    onHide: AlertOnHide.onlyClose,
                },
            });
        });
};
