import { useCallback, useContext } from 'react';
import AlertContext, { AlertOnHide, AlertType } from '../context/alertContext';
import LoginContext from '../context/loginContext';
import TerminierungContext, { IAvailableTimeslot, ITimeslot } from '../context/terminierungContext';
import { cancelAppointment } from '../services/fhir/FHIRAppointment';
import { showAlertMessage } from '../utils/alertHandling';
import {
    APPOINTMENT_POSTPONE_REASON,
    getOriginalAppointmentDate,
    resetAvailableTimeslotsWithNr,
    resetSelectedResourceWithNr,
    resetSelectedTimeslotsWithNr,
    updateAvailableTimeslots,
    updateSelectedResources,
    updateSelectedTimeslot,
} from '../utils/appointmentUtils';

export const useAppointmentSelection = () => {
    const { tmstate, tmdispatch } = useContext(TerminierungContext);
    const { alertdispatch } = useContext(AlertContext);
    const { state } = useContext(LoginContext);

    const setSelectedResource = useCallback(
        (appointmentNr: number, selectedResource: any) => {
            let appNr = 1;
            if (appointmentNr) {
                appNr = appointmentNr;
            }

            if (selectedResource !== undefined) {
                const updatedSelectedResource = updateSelectedResources(
                    tmstate.selectedResources,
                    appNr,
                    selectedResource,
                );
                tmdispatch({
                    type: 'SETRESOURCE',
                    selectedResources: updatedSelectedResource,
                });
            }
        },
        [tmdispatch, tmstate.selectedResources],
    );

    const resetSelectedResource = useCallback(
        (appointmentNr: number) => {
            let appNr = 1;
            if (appointmentNr) {
                appNr = appointmentNr;
            }

            const resetedResource = resetSelectedResourceWithNr(tmstate.selectedResources, appNr);
            tmdispatch({
                type: 'SETRESOURCE',
                selectedResources: resetedResource,
            });
        },
        [tmdispatch, tmstate.selectedResources],
    );

    const setAvailableTimeslots = useCallback(
        (appointmentNr: number, availableTimeslots: IAvailableTimeslot[], supressDuration: boolean) => {
            let appNr = 1;
            if (appointmentNr) {
                appNr = appointmentNr;
            }

            if (availableTimeslots !== undefined) {
                const updatedAvailableTimeslot = updateAvailableTimeslots(
                    tmstate.availableTimeslots,
                    appNr,
                    availableTimeslots,
                    supressDuration,
                );
                tmdispatch({
                    type: 'SETAVAILABLETIMESLOTS',
                    availableTimeslots: updatedAvailableTimeslot,
                });
            }
        },

        [tmdispatch, tmstate.availableTimeslots],
    );

    const resetAvailableTimeslots = useCallback(
        (appointmentNr: number) => {
            let appNr = 1;
            if (appointmentNr) {
                appNr = appointmentNr;
            }

            const resetedAvailableTimeslot = resetAvailableTimeslotsWithNr(tmstate.availableTimeslots, appNr);
            tmdispatch({
                type: 'SETAVAILABLETIMESLOTS',
                availableTimeslots: resetedAvailableTimeslot,
            });
        },
        [tmdispatch, tmstate.availableTimeslots],
    );

    const getSelectedResource = (appointmentNr: number) => {
        let appNr = 1;
        if (appointmentNr) {
            appNr = appointmentNr;
        }
        const foundResource = tmstate.selectedResources?.find((e) => e.appointmentNr === appNr);
        return foundResource?.resource;
    };

    const getAvailableTimeslots = (appointmentNr: number) => {
        let appNr = 1;
        if (appointmentNr) {
            appNr = appointmentNr;
        }
        const foundAvailableTimeslots = tmstate.availableTimeslots?.find((e) => e.appointmentNr === appNr);
        return foundAvailableTimeslots;
    };

    const getSelectedTimeslot = (appointmentNr: number) => {
        let appNr = 1;
        if (appointmentNr) {
            appNr = appointmentNr;
        }
        const foundSelectedTimeslots = tmstate.selectedTimeslots?.find((e) => e.appointmentNr === appNr);
        return foundSelectedTimeslots?.selectedTimeslot;
    };

    const setSelectedTimeslot = useCallback(
        (appointmentNr: number, selectedTimeslot: ITimeslot) => {
            let appNr = 1;
            if (appointmentNr) {
                appNr = appointmentNr;
            }

            if (selectedTimeslot !== undefined) {
                const updatedSelectedTimeslot = updateSelectedTimeslot(
                    tmstate.selectedTimeslots,
                    appNr,
                    selectedTimeslot,
                );
                tmdispatch({
                    type: 'SELECTTIMESLOT',
                    selectedTimeslots: updatedSelectedTimeslot,
                });
                tmdispatch({
                    type: 'TIMESLOTCLICKED',
                    timeslotClicked: true,
                });
            }
        },

        [tmdispatch, tmstate.selectedTimeslots],
    );

    const resetSelectedTimeslots = useCallback(
        (appointmentNr: number) => {
            let appNr = 1;
            if (appointmentNr) {
                appNr = appointmentNr;
            }

            const resetedSelectedTimeslot = resetSelectedTimeslotsWithNr(tmstate.selectedTimeslots, appNr);
            tmdispatch({
                type: 'SELECTTIMESLOT',
                selectedTimeslots: resetedSelectedTimeslot,
            });
        },
        [tmdispatch, tmstate.selectedTimeslots],
    );

    const resetAllSelectedTimeslots = useCallback(() => {
        tmdispatch({
            type: 'SELECTTIMESLOT',
            selectedTimeslot: [],
        });
    }, [tmdispatch]);

    const resetAllAvailableTimeslots = useCallback(() => {
        tmdispatch({
            type: 'SETAVAILABLETIMESLOTS',
            availableTimeslots: [],
        });
    }, [tmdispatch]);

    const resetAllSelectedResource = useCallback(() => {
        tmdispatch({
            type: 'SETRESOURCE',
            selectedResources: [],
        });
    }, [tmdispatch]);

    const isAppointmentPostpone = useCallback(() => {
        if (tmstate.cancelNewAppointmentData.reason === APPOINTMENT_POSTPONE_REASON) {
            return true;
        } else {
            return false;
        }
    }, [tmdispatch]);

    const cancelPostponeAppointment = useCallback(async () => {
        if (isAppointmentPostpone()) {
            const originalAppointmentDetails = getOriginalAppointmentDate(tmstate);

            const cancelRes = await cancelAppointment(
                state.sessionId,
                tmstate.cancelNewAppointmentData.data.id,
                'Termin verschoben' + originalAppointmentDetails,
                alertdispatch,
            );
            if (cancelRes) {
                if (cancelRes.ERROR) {
                    showAlertMessage({
                        alertTitle: 'Termin verschieben - Fehler beim absagen des alten Termins',
                        alertTxt: cancelRes.ERROR,
                        alertType: AlertType.error,
                        onHide: AlertOnHide.onlyClose,
                        alertdispatch: alertdispatch,
                    });
                }
            }
        }
    }, [tmdispatch]);

    const isEarlierAppointmentPossible = useCallback(() => {
        if (tmstate.selectedDepartment.fruehererTermin !== undefined) {
            return tmstate.selectedDepartment.fruehererTermin;
        }
        return false;
    }, [tmdispatch]);

    return {
        setSelectedResource,
        resetSelectedResource,
        setAvailableTimeslots,
        resetAvailableTimeslots,
        getSelectedResource,
        getAvailableTimeslots,
        getSelectedTimeslot,
        setSelectedTimeslot,
        resetSelectedTimeslots,
        resetAllSelectedTimeslots,
        resetAllAvailableTimeslots,
        resetAllSelectedResource,
        isAppointmentPostpone,
        cancelPostponeAppointment,
        isEarlierAppointmentPossible,
    };
};
