import { createGlobalStyle } from 'styled-components';
import Inter from '../fonts/Inter.ttf';
import Roboto from '../fonts/Roboto-Regular.ttf';

const GlobalStyles = createGlobalStyle`
    @font-face {
        font-family: 'Inter';
        src: local('Inter'), url(${Inter}) format('truetype');
    }
    @font-face {
        font-family: 'Roboto';
        src: local('Roboto'), url(${Roboto}) format('truetype');
    }
    body {

    }

    .App {
        font-family: -apple-system, BlinkMacSystemFont, 'Inter', 'Roboto', 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
            'Droid Sans', 'Helvetica Neue', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    #page-wrap {
        text-align: center;
    }
`;

export default GlobalStyles;
