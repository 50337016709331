import { createContext } from 'react';

export interface IPossibleMacros {
    code: string;
    name: string;
    id: number;
    countAPKs: number;
    type?: string;
}

export interface ITodoSecurityItem {
    name: string;
    type: string;
}

export interface ISecurityItems {
    CHARTS: boolean;
    CHARTS_WEIGHT: boolean;
    CHARTS_FATMASS: boolean;
    CHARTS_BLOODSUGAR: boolean;
    CHARTS_FLI: boolean;
    CHARTS_SAM: boolean;
    CHARTS_ACTIVITY: boolean;
    TABLE_VAL: boolean;
    PATLIST: boolean;
    DOKUMENT: boolean;
    FOTOS: boolean;
    FRAGEBOGEN: boolean;
    MEDIKATION: boolean;
    TAGESPLAN: boolean;
    TERMINE: boolean;
    VIDEO: boolean;
    JOURNEY: boolean;
    TODO: ITodoSecurityItem[];
    TAB_ORDER: string;
}

export interface IActiveStudies {
    studyName: string;
    studyOrgaId: string;
    studyPatId: string;
    studyPatSynonym: string;
    studyPid: string;
    securityItems: ISecurityItems;
}

export interface IActivePatients {
    patientId: string;
    nameLast: string;
    nameFirst: string;
    birthDate: string;
}

export interface ILastPatOpened {
    patId: string;
    nameLast: string;
    nameFirst: string;
    birthDate: string;
}

export interface ILink {
    headline: string;
    href: string;
    linkText: string;
    prefix: string;
    target: string;
    restmethod: string;
    innerHTML: string;
}

export interface IPossibleStudies {
    code: string;
    display: string;
}

export interface IDepartments {
    name: string;
    reasons: IDepartmentReasons[];
    kontaktArt?: string[];
    versicherungsArt?: string[];
    fruehererTermin?: boolean;
}

export interface IDepartmentReasons {
    makroCode: string;
    makroId: number;
    makroName: string;
    pFilter: string;
    aFilter: string;
    reasonCode: string;
    reasonId: number;
    reasonName: string;
}

export type InitialLoginType = {
    isLoggedIn: boolean;
    setOfInitPageDone: boolean;
    userId: string;
    practitionerRoleId: string;
    userName: string;
    sessionId: string;
    securityItems: ISecurityItems;
    securityItemsStudy?: ISecurityItems;
    finalSecurityItemsOrder: string[];
    iceServers: Array<string>;
    possibleMacros: Array<IPossibleMacros>;
    departments: IDepartments[];
    activeStudies: Array<IActiveStudies>;
    activeUserId: string;
    activeProfile: string;
    termsHTML: string;
    privacyHTML: string;
    redirectUser: string;
    redirectPwd: string;
    extraLinks: Array<ILink>;
    possibleStudies: Array<IPossibleStudies>;
    addConsignationsToAsk: Array<string>;
    encounterId: string;
    encounterTitle: string;
    activeSidebarItem: string;
    activePatients: Array<IActivePatients>;
    activePatient: string;
    gdt: {
        id: string;
    };
    lastPatOpened: Array<ILastPatOpened>;
};

export const initialLoginState = {
    isLoggedIn: false,
    setOfInitPageDone: false,
    userId: '',
    practitionerRoleId: '',
    userName: '',
    sessionId: '',
    securityItems: {
        CHARTS: false,
        CHARTS_WEIGHT: false,
        CHARTS_FATMASS: false,
        CHARTS_BLOODSUGAR: false,
        CHARTS_FLI: false,
        CHARTS_SAM: false,
        CHARTS_ACTIVITY: false,
        TABLE_VAL: false,
        PATLIST: false,
        DOKUMENT: false,
        FOTOS: false,
        FRAGEBOGEN: false,
        MEDIKATION: false,
        TAGESPLAN: false,
        TERMINE: false,
        VIDEO: false,
        JOURNEY: false,
        TODO: [],
        TAB_ORDER: '',
    },
    securityItemsStudy: {
        CHARTS: false,
        CHARTS_WEIGHT: false,
        CHARTS_FATMASS: false,
        CHARTS_BLOODSUGAR: false,
        CHARTS_FLI: false,
        CHARTS_SAM: false,
        CHARTS_ACTIVITY: false,
        TABLE_VAL: false,
        PATLIST: false,
        DOKUMENT: false,
        FOTOS: false,
        FRAGEBOGEN: false,
        MEDIKATION: false,
        TAGESPLAN: false,
        TERMINE: false,
        VIDEO: false,
        JOURNEY: false,
        TODO: [],
        TAB_ORDER: '',
    },
    iceServers: [],
    finalSecurityItemsOrder: [],
    possibleMacros: [],
    departments: [],
    activeStudies: [],
    activeUserId: '',
    activeProfile: '',
    termsHTML: '',
    privacyHTML: '',
    redirectUser: '',
    redirectPwd: '',
    extraLinks: [],
    possibleStudies: [],
    addConsignationsToAsk: [],
    encounterId: '',
    encounterTitle: '',
    activeSidebarItem: '',
    activePatients: [],
    activePatient: '',
    gdt: {
        id: '',
    },
    lastPatOpened: [],
};

// const LoginContext = createContext<InitialLoginType>(initialLoginState);
const LoginContext = createContext<{
    state: InitialLoginType;
    dispatch: React.Dispatch<any>;
}>({
    state: initialLoginState,
    dispatch: () => null,
});

export default LoginContext;
