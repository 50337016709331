import moment from 'moment';

export const getPDFDataFromDiagnosticReportResponse = (
    diagnosticReportResponse: any,
    filename: string,
    date: string,
) => {
    let modalTitle = '';
    let pdfdata = '';
    let pdfsrc = '';
    let isSignable = 'false';
    let isSigned = 'false,';
    if (diagnosticReportResponse.data.presentedForm) {
        if (date === '') {
            if (diagnosticReportResponse.data.effectiveDateTime) {
                date = moment(diagnosticReportResponse.data.effectiveDateTime).format('L LT');
            }
        }
        if (filename === '') {
            if (diagnosticReportResponse.data.code.coding[0].display) {
                filename = diagnosticReportResponse.data.code.coding[0].display;
            }
        }
        modalTitle = filename + ' / ' + date;
        pdfdata = diagnosticReportResponse.data.presentedForm[0].data;
        pdfsrc = 'data:application/pdf;base64,' + pdfdata;

        if (diagnosticReportResponse.data.extension) {
            diagnosticReportResponse.data.extension.forEach((e) => {
                if (e.url.endsWith('isSignable')) {
                    if (e.valueBoolean === true) {
                        isSignable = 'true';
                    }
                }
                if (e.url.endsWith('isSigned')) {
                    if (e.valueBoolean === true) {
                        isSigned = 'true';
                    }
                }
            });
        }
    } else {
        modalTitle = 'Kein PDF zum anzeigen vorhanden';
    }

    return [modalTitle, pdfsrc, isSignable, isSigned];
};
