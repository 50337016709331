import { useContext } from 'react';
import { FaHospitalAlt, FaUserNurse } from 'react-icons/fa';
import { ThemeContext } from 'styled-components';
import AppPropsContext from '../../context/appPropsContext';
import {
    APPOINTMENTTYPE_CAREUNIT,
    APPOINTMENTTYPE_DEPARTMENT,
    APPOINTMENTTYPE_PRACTITIONER,
    APPOINTMENTTYPE_ROOM,
    getParticipantType,
    IParticipant,
} from '../../utils/appointmentUtils';

const Participants = (props: IParticipant) => {
    const themeContext = useContext(ThemeContext);
    const { apstate } = useContext(AppPropsContext);

    function renderActor(props: IParticipant) {
        const showDepartment = apstate.AppointmentSummary.fach;
        const showCareUnit = apstate.AppointmentSummary.pflege;
        const showRoom = apstate.AppointmentSummary.raum;
        const showPractitioner = apstate.AppointmentSummary.behandler;

        const ptype = getParticipantType(props);

        /* Displaying */
        if (props.actor.display === '') {
            return <></>;
        } else {
            switch (ptype) {
                case APPOINTMENTTYPE_DEPARTMENT:
                    if (showDepartment) {
                        return (
                            <div style={{ margin: 7 }}>
                                <FaHospitalAlt size={15} color={themeContext.icon.passive.color} />{' '}
                                {props.actor.display}
                            </div>
                        );
                    } else {
                        return <></>;
                    }
                case APPOINTMENTTYPE_CAREUNIT:
                    if (showCareUnit) {
                        return (
                            <div style={{ margin: 7 }}>
                                <FaHospitalAlt size={15} color={themeContext.icon.passive.color} />{' '}
                                {props.actor.display}
                            </div>
                        );
                    } else {
                        return <></>;
                    }

                case APPOINTMENTTYPE_ROOM:
                    if (showRoom) {
                        return (
                            <div style={{ margin: 7, fontWeight: 600 }}>
                                <FaHospitalAlt size={15} color={themeContext.icon.passive.color} />{' '}
                                {props.actor.display}
                            </div>
                        );
                    } else {
                        return <></>;
                    }

                case APPOINTMENTTYPE_PRACTITIONER:
                    if (showPractitioner) {
                        return (
                            <div style={{ margin: 3, fontWeight: 600 }}>
                                <FaUserNurse size={18} color={themeContext.icon.passive.color} /> {props.actor.display}
                            </div>
                        );
                    } else {
                        return <></>;
                    }

                default:
                    // TODO: add exception for unkown actor.reference
                    return <></>;
            }
        }
    }

    return renderActor(props);
};

export default Participants;
