import { useContext } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { RiLogoutBoxRLine } from 'react-icons/ri';
import { ThemeContext } from 'styled-components';
import { IconDiv } from '../../components/Div/StyledDiv';
import Telephone from '../../components/Telephone/Telephone';
import VolumeIcon from '../../components/Telephone/Volume';
import MyTooltip from '../../components/Tooltip/MyTooltip';
import AppPropsContext from '../../context/appPropsContext';
import { useHandleLogoutWL } from '../../hooks/useHandleLogout';

const NavbarWL = () => {
    const themeContext = useContext(ThemeContext);
    const { apstate } = useContext(AppPropsContext);
    const { handleLogout } = useHandleLogoutWL();

    const brandingSrc = 'data:image/jpg;base64,' + apstate.brandingBytes;

    return (
        <Navbar bg="light" expand="lg" fixed="top">
            <Navbar.Brand style={{ marginTop: '15px' }}>
                <img src={brandingSrc} width="130" className="d-inline-block align-top" alt="Principa Logo" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse className="justify-content-end">
                <Nav>
                    <Nav.Link as={'div'}>
                        <VolumeIcon />
                    </Nav.Link>
                    <Nav.Link as={'div'}>
                        <Telephone />
                    </Nav.Link>
                    <Nav.Link as={'div'}>
                        <IconDiv onClick={handleLogout}>
                            <MyTooltip
                                id="LogoutTooltipWL"
                                text="Abmelden"
                                iconDiv={true}
                                divWidth="35px"
                                marginLeft="0"
                                marginRight="auto"
                                icon={
                                    <RiLogoutBoxRLine
                                        size={35}
                                        color={themeContext.icon.default.color}
                                        style={{ paddingTop: 5 }}
                                    />
                                }
                            ></MyTooltip>
                        </IconDiv>
                    </Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default NavbarWL;
