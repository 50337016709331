import { IAppointmentSummary, ICustomTexts, IImpressum, IRegistrationFields } from '../context/appPropsContext';
import { InitialAppPropsType } from './appPropsContext';
import { ILink } from './loginContext';

type Action = {
    type: 'SETAPPPROPS';
    AppointmentSummary: IAppointmentSummary;
    RegistrationMandFields: IRegistrationFields;
    RegistrationVisibleFields: IRegistrationFields;
    RegistrationReadOnlyFields: IRegistrationFields;
    allowAccountAfterAppointment: boolean;
    allowAccountCreateDirect: boolean;
    allowAppointments: boolean;
    allowAppointmentsWithoutLogin: boolean;
    allowDirectAppointments: boolean;
    allowDirectAppointmentsWithoutLogin: boolean;
    allowLogin: boolean;
    allowVideo: boolean;
    allowVideoWithoutLogin: boolean;
    allowAccountInAnmeldeworkflow: boolean;
    brandingBytes: string;
    brandigType: string;
    extraLinks: Array<ILink>;
    customTexts: ICustomTexts;
    showMobileQR: boolean;
    jitsiDomain: string;
    jitsiOptions: any;
    impressum: IImpressum;
};

export const appPropsReducer = (state: InitialAppPropsType, action: Action) => {
    switch (action.type) {
        case 'SETAPPPROPS':
            return {
                ...state,
                appPropsSet: true,
                AppointmentSummary: {
                    behandler:
                        action.AppointmentSummary.behandler === null
                            ? state.AppointmentSummary.behandler
                            : action.AppointmentSummary.behandler,
                    fach:
                        action.AppointmentSummary.fach === null
                            ? state.AppointmentSummary.fach
                            : action.AppointmentSummary.fach,
                    pflege:
                        action.AppointmentSummary.pflege === null
                            ? state.AppointmentSummary.pflege
                            : action.AppointmentSummary.pflege,
                    raum:
                        action.AppointmentSummary.raum === null
                            ? state.AppointmentSummary.raum
                            : action.AppointmentSummary.raum,
                },
                RegistrationMandFields: {
                    birthDate:
                        action.RegistrationMandFields.birthDate === null
                            ? state.RegistrationMandFields.birthDate
                            : action.RegistrationMandFields.birthDate,
                    city:
                        action.RegistrationMandFields.city === null
                            ? state.RegistrationMandFields.city
                            : action.RegistrationMandFields.city,
                    country:
                        action.RegistrationMandFields.country === null
                            ? state.RegistrationMandFields.country
                            : action.RegistrationMandFields.country,
                    email:
                        action.RegistrationMandFields.email === null
                            ? state.RegistrationMandFields.email
                            : action.RegistrationMandFields.email,
                    firstName:
                        action.RegistrationMandFields.firstName === null
                            ? state.RegistrationMandFields.firstName
                            : action.RegistrationMandFields.firstName,
                    gender:
                        action.RegistrationMandFields.gender === null
                            ? state.RegistrationMandFields.gender
                            : action.RegistrationMandFields.gender,
                    lastName:
                        action.RegistrationMandFields.lastName === null
                            ? state.RegistrationMandFields.lastName
                            : action.RegistrationMandFields.lastName,
                    phone:
                        action.RegistrationMandFields.phone === null
                            ? state.RegistrationMandFields.phone
                            : action.RegistrationMandFields.phone,
                    street:
                        action.RegistrationMandFields.street === null
                            ? state.RegistrationMandFields.street
                            : action.RegistrationMandFields.street,
                    zip:
                        action.RegistrationMandFields.zip === null
                            ? state.RegistrationMandFields.zip
                            : action.RegistrationMandFields.zip,
                    insuranceNr:
                        action.RegistrationMandFields.insuranceNr === null
                            ? state.RegistrationMandFields.insuranceNr
                            : action.RegistrationMandFields.insuranceNr,
                    mobilePhone:
                        action.RegistrationMandFields.mobilePhone === null
                            ? state.RegistrationMandFields.mobilePhone
                            : action.RegistrationMandFields.mobilePhone,
                    academicTitle:
                        action.RegistrationMandFields.academicTitle === null
                            ? state.RegistrationMandFields.academicTitle
                            : action.RegistrationMandFields.academicTitle,
                },
                RegistrationVisibleFields: {
                    birthDate:
                        action.RegistrationVisibleFields.birthDate === null
                            ? state.RegistrationVisibleFields.birthDate
                            : action.RegistrationVisibleFields.birthDate,
                    city:
                        action.RegistrationVisibleFields.city === null
                            ? state.RegistrationVisibleFields.city
                            : action.RegistrationVisibleFields.city,
                    country:
                        action.RegistrationVisibleFields.country === null
                            ? state.RegistrationVisibleFields.country
                            : action.RegistrationVisibleFields.country,
                    email:
                        action.RegistrationVisibleFields.email === null
                            ? state.RegistrationVisibleFields.email
                            : action.RegistrationVisibleFields.email,
                    firstName:
                        action.RegistrationVisibleFields.firstName === null
                            ? state.RegistrationVisibleFields.firstName
                            : action.RegistrationVisibleFields.firstName,
                    gender:
                        action.RegistrationVisibleFields.gender === null
                            ? state.RegistrationVisibleFields.gender
                            : action.RegistrationVisibleFields.gender,
                    lastName:
                        action.RegistrationVisibleFields.lastName === null
                            ? state.RegistrationVisibleFields.lastName
                            : action.RegistrationVisibleFields.lastName,
                    phone:
                        action.RegistrationVisibleFields.phone === null
                            ? state.RegistrationVisibleFields.phone
                            : action.RegistrationVisibleFields.phone,
                    street:
                        action.RegistrationVisibleFields.street === null
                            ? state.RegistrationVisibleFields.street
                            : action.RegistrationVisibleFields.street,
                    zip:
                        action.RegistrationVisibleFields.zip === null
                            ? state.RegistrationVisibleFields.zip
                            : action.RegistrationVisibleFields.zip,
                    insuranceNr:
                        action.RegistrationVisibleFields.insuranceNr === null
                            ? state.RegistrationVisibleFields.insuranceNr
                            : action.RegistrationVisibleFields.insuranceNr,
                    mobilePhone:
                        action.RegistrationVisibleFields.mobilePhone === null
                            ? state.RegistrationVisibleFields.mobilePhone
                            : action.RegistrationVisibleFields.mobilePhone,
                    academicTitle:
                        action.RegistrationVisibleFields.academicTitle === null
                            ? state.RegistrationVisibleFields.academicTitle
                            : action.RegistrationVisibleFields.academicTitle,
                },
                RegistrationReadOnlyFields: {
                    birthDate:
                        action.RegistrationReadOnlyFields.birthDate === null
                            ? state.RegistrationReadOnlyFields.birthDate
                            : action.RegistrationReadOnlyFields.birthDate,
                    city:
                        action.RegistrationReadOnlyFields.city === null
                            ? state.RegistrationReadOnlyFields.city
                            : action.RegistrationReadOnlyFields.city,
                    country:
                        action.RegistrationReadOnlyFields.country === null
                            ? state.RegistrationReadOnlyFields.country
                            : action.RegistrationReadOnlyFields.country,
                    email:
                        action.RegistrationReadOnlyFields.email === null
                            ? state.RegistrationReadOnlyFields.email
                            : action.RegistrationReadOnlyFields.email,
                    firstName:
                        action.RegistrationReadOnlyFields.firstName === null
                            ? state.RegistrationReadOnlyFields.firstName
                            : action.RegistrationReadOnlyFields.firstName,
                    gender:
                        action.RegistrationReadOnlyFields.gender === null
                            ? state.RegistrationReadOnlyFields.gender
                            : action.RegistrationReadOnlyFields.gender,
                    lastName:
                        action.RegistrationReadOnlyFields.lastName === null
                            ? state.RegistrationReadOnlyFields.lastName
                            : action.RegistrationReadOnlyFields.lastName,
                    phone:
                        action.RegistrationReadOnlyFields.phone === null
                            ? state.RegistrationReadOnlyFields.phone
                            : action.RegistrationReadOnlyFields.phone,
                    street:
                        action.RegistrationReadOnlyFields.street === null
                            ? state.RegistrationReadOnlyFields.street
                            : action.RegistrationReadOnlyFields.street,
                    zip:
                        action.RegistrationReadOnlyFields.zip === null
                            ? state.RegistrationReadOnlyFields.zip
                            : action.RegistrationReadOnlyFields.zip,
                    insuranceNr:
                        action.RegistrationReadOnlyFields.insuranceNr === null
                            ? state.RegistrationReadOnlyFields.insuranceNr
                            : action.RegistrationReadOnlyFields.insuranceNr,
                    mobilePhone:
                        action.RegistrationReadOnlyFields.mobilePhone === null
                            ? state.RegistrationReadOnlyFields.mobilePhone
                            : action.RegistrationReadOnlyFields.mobilePhone,
                    academicTitle:
                        action.RegistrationReadOnlyFields.academicTitle === null
                            ? state.RegistrationReadOnlyFields.academicTitle
                            : action.RegistrationReadOnlyFields.academicTitle,
                },
                allowAccountAfterAppointment: action.allowAccountAfterAppointment,
                allowAccountCreateDirect: action.allowAccountCreateDirect,
                allowAppointments: action.allowAppointments,
                allowAppointmentsWithoutLogin: action.allowAppointmentsWithoutLogin,
                allowDirectAppointments: action.allowDirectAppointments,
                allowDirectAppointmentsWithoutLogin: action.allowDirectAppointmentsWithoutLogin,
                allowLogin: action.allowLogin,
                allowVideo: action.allowVideo,
                allowVideoWithoutLogin: action.allowVideoWithoutLogin,
                allowAccountInAnmeldeworkflow: action.allowAccountInAnmeldeworkflow,
                brandingBytes: action.brandingBytes,
                brandigType: action.brandigType,
                extraLinks: action.extraLinks,
                customTexts: action.customTexts,
                showMobileQR: action.showMobileQR,
                jitsiDomain: action.jitsiDomain,
                jitsiOptions: action.jitsiOptions,
                impressum: action.impressum,
            };

        default:
            return state;
    }
};
