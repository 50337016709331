import { useContext, useEffect, useState } from 'react';
import AppPropsContext from '../../context/appPropsContext';
import { getBaseURL } from '../../globals/global';
import { usePersonalData } from '../../hooks/usePersonalData';
import { getQuestionnaireJourney } from '../../services/RestServices';
import CheckInWL from '../CheckIn/CheckInWL';
import Mainscreen from '../Mainscreen/Mainscreen';

export interface INeuerAccountWL {
    type?: string;
    key?: string;
    reason?: string;
    handleFinishClick?(response: any): void;
}

const NeuerAccountPhysLogin = () => {
    const { apstate, apdispatch } = useContext(AppPropsContext);

    const [journeyData, setJourneyData] = useState<any>();
    const [loaded, setLoaded] = useState(false);
    const { resetPersonalDataPhysPat } = usePersonalData();

    const hashParams: INeuerAccountWL = {
        type: 'NewAccount',
        reason: 'ANMELDKARD',
        key: getBaseURL(),
    };

    useEffect(() => {
        const getQuestJourney = async () => {
            const questionnaireJourneyResponse = await getQuestionnaireJourney('ANMELDKARD', '', '');
            if (questionnaireJourneyResponse) {
                /* override appProperties */
                apdispatch({
                    type: 'SETAPPPROPS',
                    ...apstate,
                    allowAccountCreateDirect: false,
                    allowDirectAppointmentsWithoutLogin: false,
                });
                setJourneyData(questionnaireJourneyResponse);
            }
            setLoaded(true);
        };

        // resetPersonalDataPhysPat();
        getQuestJourney();
    }, []);

    const showContent = () => {
        if (loaded) {
            if (journeyData) {
                return (
                    <Mainscreen>
                        <div>
                            <CheckInWL
                                newAccountHashParams={hashParams}
                                journeyResponse={journeyData}
                                useLoginContext={true}
                                isPhysicianLogin={true}
                            />
                        </div>
                    </Mainscreen>
                );
            } else {
                return <></>;
            }
        }

        return null;
    };

    return showContent();
};

export default NeuerAccountPhysLogin;
