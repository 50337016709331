import { makeStyles, Step, StepButton, StepLabel, Stepper } from '@material-ui/core';
import { useEffect, useState } from 'react';
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger';
import Tooltip from 'react-bootstrap/esm/Tooltip';
import { pcpTheme } from '../../globals/theme';
import './Stepper.css';

export enum StepperOrientation {
    Horizontal,
    Vertical,
}

export enum StepperLabelOrientation {
    Right,
    Bottom,
}

export enum StepTyp {
    Normal = 'NORMAL',
    Journey = 'JOURNEY',
}

export interface IStepperCheckInStep {
    id: string;
    name: string;
    typ: StepTyp;
    completed: boolean;
    draft: boolean;
    active: boolean;
    clickable: boolean;
    url?: string;
    // hashParams?: IQuestionnaireWL;
}

export interface IStepperCheckIn {
    steps: IStepperCheckInStep[];
    backgroundColor?: string;
    handleStepClick(stepNumberClicked: number): void;
    stepperOrientation: StepperOrientation;
    labelOrientation: StepperLabelOrientation;
    paddingTop?: number | string;
    paddingBottom?: number | string;
}

const useStyles = makeStyles({
    root: {
        '& .MuiStepIcon-root': { color: 'gray' },
        '& .MuiStepIcon-completed': { color: pcpTheme.step.completed + ' !important' },
        '& .MuiStepIcon-active': { color: pcpTheme.step.active + ' !important' } /*{ color: 'rgb(36, 94, 173)' },*/,
        '& .Mui-disabled .MuiStepIcon-root': { color: '#bab9b9 !important' },
        // '& .MuiStepLabel-alternativeLabel': { marginTop: '10px', marginBottom: '30px', color: 'white', width: '100%' },
        '& .MuiStepLabel-label': { color: 'black', width: '100%', textAlign: 'left' },
        '& .MuiStepLabel-active .MuiStepLabel-label': {
            fontWeight: '530',
        },
        '& .MuiStepConnector-lineVertical': { minHeight: '60px' },
    },
    stepButtonDraft: {
        '& .MuiStepIcon-root': { color: '#0080009c' },
    },
});
// const useStyles = makeStyles({
//     root: {
//         '& .MuiStepIcon-root': { color: 'gray' },
//         '& .MuiStepIcon-completed': { color: pcpTheme.step.completed + ' !important' },
//         '& .MuiStepIcon-active': { color: pcpTheme.step.active + ' !important' } /*{ color: 'rgb(36, 94, 173)' },*/,
//         '& .Mui-disabled .MuiStepIcon-root': { color: '#bab9b9 !important' },
//         '& .MuiStepLabel-alternativeLabel': { marginTop: '10px', marginBottom: '30px', color: 'white', width: '100%' },
//         '& .MuiStepLabel-label': { color: 'white', width: '100%', textAlign: 'left' },
//         '& .MuiStepConnector-lineVertical': { minHeight: '60px' },
//     },
//     stepButtonDraft: {
//         '& .MuiStepIcon-root': { color: '#0080009c' },
//     },
// });

const StepperCheckIn = (props: IStepperCheckIn) => {
    const classes = useStyles();

    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState({});
    const [steps, setSteps] = useState<IStepperCheckInStep[]>([
        {
            id: '',
            name: '',
            typ: StepTyp.Normal,
            completed: false,
            draft: false,
            active: false,
            clickable: false,
            url: '',
        },
    ]);

    // const steps = ['Select campaign settings', 'Create an ad group', 'Create an ad'];

    useEffect(() => {
        const newCompleted = completed;
        let activeIndex = 0;

        if (props.steps) {
            if (props.steps.length > 0) {
                props.steps.forEach((step, index) => {
                    if (step.active) {
                        activeIndex = index;
                    }
                    if (step.completed) {
                        newCompleted[index] = true;
                    }
                });
            }
            setCompleted(newCompleted);
            setActiveStep(activeIndex);

            setSteps(props.steps);
        }
    }, [props.steps]);

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleStep = (step) => () => {
        setActiveStep(step);
        props.handleStepClick(step);
    };

    // const handleComplete = () => {
    //     const newCompleted = completed;
    //     newCompleted[activeStep] = true;
    //     setCompleted(newCompleted);
    // };

    const getTooltipText = (isCompleted: boolean, isDraft: boolean) => {
        let text = 'noch offen';
        if (isCompleted) {
            text = 'abgeschlossen';
        } else if (isDraft) {
            text = 'zwischengespeichert';
        }
        return text;
    };

    const getStepperOrientation = () => {
        if (props.stepperOrientation === StepperOrientation.Vertical) {
            return 'vertical';
        } else {
            return 'horizontal';
        }
    };

    const getStepperLabelOrientation = () => {
        if (props.labelOrientation === StepperLabelOrientation.Right) {
            return false;
        } else {
            return true;
        }
    };

    return (
        <>
            <Stepper
                nonLinear
                activeStep={activeStep}
                orientation={getStepperOrientation()}
                alternativeLabel={getStepperLabelOrientation()}
                style={{
                    backgroundColor: props.backgroundColor ? props.backgroundColor : 'white',
                    width: '100%',
                    paddingRight: '10px',
                    paddingTop: props.paddingTop ? props.paddingTop : '25px',
                    paddingBottom: props.paddingBottom ? props.paddingBottom : '25px',
                }}
                className={classes.root}
            >
                {steps.map((label, index) => (
                    <Step key={label.name} completed={completed[index]}>
                        <StepButton
                            classes={{
                                root: label.draft ? classes.stepButtonDraft : classes.root,
                            }}
                            onClick={handleStep(index)}
                            disabled={!label.clickable}
                        >
                            <OverlayTrigger
                                key={'overlay_' + label.name}
                                placement="bottom"
                                overlay={
                                    <Tooltip id={`tooltip-${label.name}`}>
                                        {getTooltipText(completed[index], label.draft)}
                                    </Tooltip>
                                }
                            >
                                <StepLabel style={{ wordBreak: 'break-word' }}>{label.name}</StepLabel>
                            </OverlayTrigger>
                        </StepButton>
                    </Step>
                ))}
            </Stepper>
            <div>{allStepsCompleted() ? <>{/* <p>Alles erledigt</p> */}</> : <></>}</div>
        </>
    );
};

export default StepperCheckIn;
