import { useContext, useEffect, useState } from 'react';
import BadgesList from '../../components/Badges/BadgesList';
import { LeftDiv } from '../../components/Div/StyledDiv';
import AlertContext from '../../context/alertContext';
import LoginContext, { IDepartments } from '../../context/loginContext';
import TerminierungContext from '../../context/terminierungContext';
import { getParticipants } from '../../services/RestServices';

interface IAppointmentCategoryDepartment {
    departments?: IDepartments[];
    handleDepartmentClick(): void;
}

const AppointmentCategoryDepartment = (props: IAppointmentCategoryDepartment) => {
    const { alertdispatch } = useContext(AlertContext);
    const { dispatch } = useContext(LoginContext);
    const { tmdispatch } = useContext(TerminierungContext);
    const [departments, setDepartments] = useState<IDepartments[]>([]);

    useEffect(() => {
        const getDepartmentMacros = async () => {
            if (props.departments) {
                setDepartments(props.departments);
            } else {
                const possibleMacrosResponse: any = await getParticipants(alertdispatch);
                if (possibleMacrosResponse.departments !== undefined) {
                    setDepartments(possibleMacrosResponse.departments);
                }
            }
        };

        getDepartmentMacros();
    }, []);

    useEffect(() => {
        if (departments && departments.length > 0) {
            dispatch({
                type: 'SETDEPARTMENTS',
                departments: departments,
            });
        }
    }, [departments]);

    const getBadgesList = () => {
        const allBadges: string[] = [];
        departments.forEach((e) => {
            allBadges.push(e.name);
        });

        return allBadges;
    };

    const handleDepartmentClick = (departmentName: string) => {
        const selectedDepartment = departments.find((dep) => dep.name === departmentName);
        if (selectedDepartment) {
            tmdispatch({
                type: 'SELECTDEPARTMENT',
                selectedDepartment: selectedDepartment,
            });
            props.handleDepartmentClick();
        }
    };

    return (
        <LeftDiv marginLeft="100px">
            <h3>Fachrichtung wählen</h3>
            <p>Wählen Sie die gewünschte Fachrichtung aus</p>
            <br />
            <BadgesList badgesList={getBadgesList()} handleBadgeClick={handleDepartmentClick} />
        </LeftDiv>
    );
};

export default AppointmentCategoryDepartment;
