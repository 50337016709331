import { useContext, useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Nav from 'react-bootstrap/esm/Nav';
import { RiAlarmWarningLine } from 'react-icons/ri';
import { ThemeContext } from 'styled-components';
import EnrollCodeBox from '../../components/EnrollCodeBox/EnrollCodeBox';
import ExtraLinks from '../../components/ExtraLinks/ExtraLinks';
import QRCodeForApp from '../../components/QRCode/QRCodeForApp';
import AlertContext from '../../context/alertContext';
import AppPropsContext from '../../context/appPropsContext';
import { useAppProperties } from '../../hooks/useAppProperties';
import { getAppProperties } from '../../services/RestServices';
import { resetShowAlertMessage } from '../../utils/alertHandling';
import { setDataFromAppProperties } from '../../utils/pcpUtils';
import Login from '../Login/Login';
import FullScreenImpressum from '../Mainscreen/FullScreenImpressum';

const Home = () => {
    interface IShowHomeElements {
        showLogin: boolean;
        showEnrollCodeBox: boolean;
        showQRCodeForApp: boolean;
        row_lg: number;
    }

    const { apstate, apdispatch } = useContext(AppPropsContext);
    const { alertdispatch } = useContext(AlertContext);
    const themeContext = useContext(ThemeContext);

    const { getImpressum } = useAppProperties();

    const [error, setError] = useState(false);

    const [homeElements, setHomeElements] = useState<IShowHomeElements>({
        showLogin: false,
        showEnrollCodeBox: false,
        showQRCodeForApp: false,
        row_lg: 0,
    });

    const getProperties = async () => {
        const propRes = await getAppProperties();
        if (propRes) {
            setDataFromAppProperties(propRes, apdispatch);
        } else {
            setError(true);
        }
    };

    useEffect(() => {
        getProperties();
        resetShowAlertMessage(alertdispatch);
    }, []);

    useEffect(() => {
        const homeEle = {
            showLogin: false,
            showEnrollCodeBox: false,
            showQRCodeForApp: false,
            row_lg: 0,
        };
        let row_lg_counter = 0;

        if (apstate.allowLogin) {
            homeEle.showLogin = true;
            row_lg_counter++;
        }
        if (apstate.allowAppointmentsWithoutLogin) {
            homeEle.showEnrollCodeBox = true;
            row_lg_counter++;
        }
        if (apstate.showMobileQR !== false) {
            homeEle.showQRCodeForApp = true;
            row_lg_counter++;
        }

        homeEle.row_lg = row_lg_counter;

        setHomeElements(homeEle);
    }, [apstate]);

    const brandingSrc = 'data:image/jpg;base64,' + apstate.brandingBytes;

    const impressum = getImpressum();

    return (
        <FullScreenImpressum impressum={impressum}>
            {!error ? (
                <div>
                    <Container fluid>
                        <Row style={{ paddingTop: 20, paddingBottom: 20 }}>
                            {apstate.extraLinks ? <Col></Col> : null}
                            <Col>
                                <div style={{ textAlign: 'center' }}>
                                    <Image src={brandingSrc} fluid style={{ paddingTop: 20 }} />
                                </div>
                            </Col>
                            {apstate.extraLinks ? (
                                <Col>
                                    <div style={{ float: 'right' }}>
                                        <Nav>
                                            <ExtraLinks />
                                        </Nav>
                                    </div>
                                </Col>
                            ) : null}
                        </Row>
                        <Row xs={1} sm={1} md={1} lg={homeElements.row_lg} className="mt-5">
                            {homeElements.showLogin ? (
                                <Col>
                                    <Login />
                                </Col>
                            ) : null}
                            {homeElements.showEnrollCodeBox ? (
                                <Col>
                                    <EnrollCodeBox />
                                </Col>
                            ) : null}
                            {homeElements.showQRCodeForApp ? (
                                <Col>
                                    <QRCodeForApp />
                                </Col>
                            ) : null}
                        </Row>
                    </Container>
                </div>
            ) : (
                <div>
                    <Container fluid>
                        <Card
                            style={{
                                borderRadius: 10,
                                borderColor: '#e0e1e1',
                                overflow: 'hidden',
                                margin: 'auto',
                                marginBottom: 10,
                                marginTop: 100,
                                boxShadow: '0 2px 4px 0 #e0e1e1, 0 3px 10px 0 #e0e1e1',
                                width: '50%',
                                height: '350px',
                            }}
                        >
                            <Card.Body>
                                <Alert variant="danger" style={{ width: '100%', height: '100%' }}>
                                    <Row style={{ paddingTop: '10px' }}>
                                        <Col>
                                            <div style={{ textAlign: 'center' }}>
                                                <RiAlarmWarningLine size={75} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col style={{ textAlign: 'center' }}>
                                            <div style={{ paddingTop: '30px' }}>
                                                <b>Die Seite konnte nicht geladen werden!</b>
                                            </div>
                                            <div style={{ paddingTop: '10px' }}>
                                                <b>Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.</b>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row style={{ position: 'absolute', bottom: 10, right: 10 }}>
                                        <Col>
                                            <Button variant="primary" onClick={getProperties}>
                                                Nochmals versuchen
                                            </Button>
                                        </Col>
                                    </Row>
                                </Alert>
                            </Card.Body>
                        </Card>
                    </Container>
                </div>
            )}
        </FullScreenImpressum>
    );
};

export default Home;
