import { useCallback, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import AppPropsContext from '../context/appPropsContext';
import { getAppProperties } from '../services/RestServices';
import { setDataFromAppProperties } from '../utils/pcpUtils';

export const useAppProperties = () => {
    const { apstate, apdispatch } = useContext(AppPropsContext);

    const themeContext = useContext(ThemeContext);

    const getAndSetAppProperties = useCallback(async () => {
        const propRes = await getAppProperties();
        if (propRes) {
            setDataFromAppProperties(propRes, apdispatch);
        } else {
            // setError(true);      //TODO: add error handling
        }
    }, [apdispatch]);

    const getImpressum = () => {
        const impressum = {
            impressumLink: apstate.impressum.impressumLink,
            datenschutzLink: apstate.impressum.datenschutzLink,
            textColor: themeContext.button.secondary.color,
        };
        return impressum;
    };

    return {
        getAndSetAppProperties,
        getImpressum,
    };
};
