import { useContext, useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { JsxElement } from 'typescript';
import LoginContext, { IActivePatients } from '../../context/loginContext';
import { usePatientSwitch } from '../../hooks/usePatientSwitch';
import StyledButton from '../Buttons/StyledButton';

const PatientSwitch = () => {
    const [allPatients, setAllPatients] = useState<JsxElement[]>();

    const { state, dispatch } = useContext(LoginContext);
    const { setNewPatient } = usePatientSwitch();

    useEffect(() => {
        getDropdownItems();
    }, [state.activePatients, state.activePatient]);

    const handlePatSwitch = async (e: any, pat: IActivePatients) => {
        if (e && e.currentTarget) {
            e.preventDefault();
            e.currentTarget.blur();
        }
        setNewPatient(pat);
    };

    const getDropdownItems = () => {
        const allItems: any[] = [];

        if (Array.isArray(state.activePatients) && state.activePatients.length) {
            state.activePatients.forEach((pat: IActivePatients) => {
                allItems.push(
                    <Dropdown.Item key={pat.patientId} onClick={(e) => handlePatSwitch(e, pat)}>
                        {pat.nameFirst} {pat.nameLast}
                    </Dropdown.Item>,
                );
            });
        }

        setAllPatients(allItems);
    };

    return (
        <Dropdown>
            <Dropdown.Toggle as={StyledButton} variant="secondary" margintop="1px" id="dropdown-basic">
                {state.activePatient}
            </Dropdown.Toggle>
            <Dropdown.Menu>{allPatients}</Dropdown.Menu>
        </Dropdown>
    );
};

export default PatientSwitch;
