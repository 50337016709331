import { createContext } from 'react';

export interface IQData {
    entry: IQDataEntry[];
    id: string;
    resourceType: string;
    type: string;
}

export interface IQDataEntry {
    resource: IQResource[];
}

export interface IQResource {
    id: string;
    item: IQMainItem[];
    name: string;
    resourceType: string;
    title: string;
    extension: IExtension[];
    status?: string;
}

export interface IQBody {
    authored: string;
    context: {
        reference: string;
    };
    id: string;
    identifier: {
        system: string;
        value: string;
    };
    item: IQAnswers[];
    meta: {
        versionId: string;
        lastUpdated: string;
    };
    questionnaire: {
        reference: string;
    };
    resourceType: string;
    source: {
        reference: string;
    };
    status: string;
    subject: {
        reference: string;
    };
}

export interface IExtension {
    url: string;
    valueString: string;
    valueBoolean?: boolean;
    valueReference: {
        reference: string;
    };
}

export interface IQMainItem {
    item: IQItem[];
    linkId: string;
    text: string;
    type: string;
}

export interface IAttachment {
    contentType: string;
    data: string;
}

export interface IQItem {
    linkId: string;
    text: string;
    type: string;
    initialDate?: string;
    initialTime?: string;
    initialDateTime?: string;
    initialInteger?: string;
    initialCoding?: IQItemInitialChoiceOption;
    initialAttachment?: IAttachment;
    modifierExtension?: IQItemExtension[];
    option?: IQItemChoiceOption[];
    extension?: IQItemExtension[];
    required?: boolean;
    enableWhen?: IQItemEnableWhen[];
    maxLength?: number;
    readOnly?: boolean;
}

export interface IQItemChoiceOption {
    modifierExtension?: IQOptionExtension[];
    valueCoding: {
        code: string;
        display: string;
        extension?: IQOptionExtension[];
    };
}

export interface IQItemInitialChoiceOption {
    code: string;
    display: string;
}

export interface IQItemExtension {
    url: string;
    valueString: string;
    valueDecimal?: number;
    valueInteger?: number;
    valueBoolean?: boolean;
}

export interface IQOptionExtension {
    url: string;
    valueBoolean?: boolean;
    valueInteger?: number;
}

export interface IQItemEnableWhen {
    hasAnswer: boolean;
    question: string;
    answerCoding: {
        code: string;
    };
    answerBoolean: boolean;
}

export interface IQAnswers {
    linkId: string;
    answer?: any; //IQAnswerValueCoding[] | IQAnswerValueInteger;
}

export interface IQAnswerValueCoding {
    valueCoding: {
        code: string;
        display: string;
        extension?: IQOptionExtension[];
    };
}

export interface IQAnswerValueInteger {
    valueInteger: number;
}

export interface InitialQuestionnaireType {
    qData: IQData;
    selectedQuestionnaire: {
        resource: IQResource;
    };
    selectedAnswers: {
        item: IQAnswers[];
    };
    questionnaireBody: {
        data: IQBody;
    };
    isExternalRequest: boolean;
    externalUserId: string;
    externalOTP: string;
    externalT0: string;
    externalBaseURL: string;
    callingPage: string;
    encounterId: string;
}

export const initialQuestionnaireState = {
    qData: {
        entry: [
            {
                resource: [
                    {
                        id: '',
                        item: [
                            {
                                item: [
                                    {
                                        linkId: '',
                                        text: '',
                                        type: '',
                                    },
                                ],
                                linkId: '',
                                text: '',
                                type: '',
                            },
                        ],
                        name: '',
                        resourceType: '',
                        title: '',
                        extension: [
                            {
                                url: '',
                                valueString: '',
                                valueReference: {
                                    reference: '',
                                },
                            },
                        ],
                    },
                ],
            },
        ],
        id: '',
        resourceType: '',
        type: '',
    },
    selectedQuestionnaire: {
        resource: {
            id: '',
            item: [],
            name: '',
            resourceType: '',
            title: '',
            extension: [
                {
                    url: '',
                    valueString: '',
                    valueReference: {
                        reference: '',
                    },
                },
            ],
        },
    },
    selectedAnswers: {
        item: [],
    },
    questionnaireBody: {
        data: {
            authored: '',
            context: {
                reference: '',
            },
            id: '',
            identifier: {
                system: '',
                value: '',
            },
            item: [],
            meta: {
                versionId: '',
                lastUpdated: '',
            },
            questionnaire: {
                reference: '',
            },
            resourceType: '',
            source: {
                reference: '',
            },
            status: '',
            subject: {
                reference: '',
            },
        },
    },
    isExternalRequest: false,
    externalUserId: '',
    externalOTP: '',
    externalT0: '',
    externalBaseURL: '',
    callingPage: '',
    encounterId: '',
};

const QuestionnaireContext = createContext<{
    qstate: InitialQuestionnaireType;
    qdispatch: React.Dispatch<any>;
}>({
    qstate: initialQuestionnaireState,
    qdispatch: () => null,
});

export default QuestionnaireContext;
