import { useContext, useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import { IconBaseProps } from 'react-icons/lib';
import { Link } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import LoginContext from '../../context/loginContext';

interface ISidebarItem {
    route: string;
    label: string;
    icon: IconBaseProps;
    className?: string;
}

const SidebarItem = (props: ISidebarItem) => {
    const themeContext = useContext(ThemeContext);
    const { state } = useContext(LoginContext);
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        if (state.activeSidebarItem === props.label || state.activeSidebarItem === props.route) {
            setIsActive(true);
        } else {
            setIsActive(false);
        }
    }, [state.activeSidebarItem]);

    return (
        <Nav.Link
            eventKey={props.label}
            className={props.className}
            as={Link}
            to={props.route}
            style={{
                color: themeContext.passiveColor,
                backgroundColor: isActive ? themeContext.sidebar.itemBackground : 'transparent',
                alignItems: 'center',
                borderRadius: '26px',
                padding: '3px',
                marginBottom: '5px',
            }}
        >
            <Container style={{ display: 'block' }}>
                <Row>
                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                        <div style={{ float: 'right', alignItems: 'center' }}>{props.icon}</div>
                    </Col>
                    <Col xs={8} sm={8} md={8} lg={8} xl={8} style={{ alignItems: 'center' }}>
                        {props.label}
                    </Col>
                </Row>
            </Container>
        </Nav.Link>
    );
};

export default SidebarItem;
