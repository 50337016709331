import { moment } from '../../globals/global';
import { IAppointment } from '../../utils/appointmentUtils';

export function startDate(data: IAppointment) {
    const dateStart = moment(data.start);
    const dateEnd = moment(data.end);
    if (dateStart.isSame(dateEnd, 'day')) {
        return dateStart.format(dateStart.isSame(moment(), 'year') ? 'dddd, DoMMM' : 'dddd, DoMMM YYYY');
    } else {
        return (
            dateStart.format(dateStart.isSame(moment(), 'year') ? 'dd, DoMMM' : 'dd, DoMMM YYYY') +
            ' - ' +
            dateEnd.format(dateEnd.isSame(moment(), 'year') ? 'dd, DoMMM' : 'dd, DoMMM YYYY')
        );
    }
}

function startTime(data: IAppointment) {
    const dateStart = moment(data.start);
    const dateEnd = moment(data.end);
    let supressDuration;
    if (data.modifierExtension) {
        for (const ext of data.modifierExtension) {
            if (ext.url == 'http://www.principa.siegele-software.com/suppressDuration' && ext.valueBoolean) {
                supressDuration = true;
            }
        }
    } else {
        supressDuration = data.supressDuration;
    }

    if (dateStart.isSame(dateEnd, 'day')) {
        if (supressDuration) {
            return dateStart.format(`HH:mm`);
        }
        return dateStart.format(`HH:mm - ${dateEnd.format('HH:mm')}`);
    }
}

const AppointmentDate = (props: IAppointment) => {
    return (
        <div>
            <div style={{ fontWeight: 500 }}>{startDate(props)}</div>
            <div style={{ fontWeight: 600, fontSize: 16 }}>{startTime(props)}</div>
        </div>
    );
};

export default AppointmentDate;
