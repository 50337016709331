import Button from 'react-bootstrap/Button';
import styled from 'styled-components';

export interface IButtonProps {
    color: string;
    colorHover: string;
    backgroundColor: string;
    backgroundColorHover: string;
    borderRadius: string;
    borderRadiusTopLeft: string;
    borderRadiusTopRight: string;
    borderRadiusBottomLeft: string;
    borderRadiusBottomRight: string;
    border: string;
    borderColor: string;
    borderColorHover: string;
    boxShadow: string;
    width?: string;
    marginLeft?: string;
}

const buttonProps = (props: any): IButtonProps => {
    /* defaultValues / primary */
    const bp: IButtonProps = {
        color: props.theme.button.primary.color,
        colorHover: props.theme.button.primary.colorHover,
        backgroundColor: props.theme.button.primary.backgroundColor,
        backgroundColorHover: props.theme.button.primary.backgroundColorHover,
        borderRadius: props.theme.button.borderRadius,
        borderRadiusTopLeft: props.theme.button.primary.borderRadiusTopLeft,
        borderRadiusTopRight: props.theme.button.primary.borderRadiusTopRight,
        borderRadiusBottomLeft: props.theme.button.primary.borderRadiusBottomLeft,
        borderRadiusBottomRight: props.theme.button.primary.borderRadiusBottomRight,
        border: props.theme.button.border,
        borderColor: props.theme.button.primary.borderColor,
        borderColorHover: props.theme.button.primary.borderColorHover,
        boxShadow: props.theme.button.boxShadow,
    };

    if (props.variant === 'primary') {
        return bp;
    } else if (props.variant === 'secondary') {
        bp.color = props.theme.button.secondary.color;
        bp.colorHover = props.theme.button.secondary.colorHover;
        bp.backgroundColor = props.theme.button.secondary.backgroundColor;
        bp.backgroundColorHover = props.theme.button.secondary.backgroundColorHover;
        bp.border = props.theme.button.secondary.border;
        bp.borderColor = props.theme.button.secondary.borderColor;
        bp.borderColorHover = props.theme.button.secondary.borderColorHover;
    } else if (props.variant === 'warning') {
        bp.color = props.theme.button.warning.color;
        bp.colorHover = props.theme.button.warning.colorHover;
        bp.backgroundColor = props.theme.button.warning.backgroundColor;
        bp.backgroundColorHover = props.theme.button.warning.backgroundColorHover;
        bp.border = props.theme.button.warning.border;
        bp.borderColor = props.theme.button.warning.borderColor;
        bp.borderColorHover = props.theme.button.warning.borderColorHover;
    } else if (props.variant === 'success') {
        bp.color = props.theme.button.success.color;
        bp.colorHover = props.theme.button.success.colorHover;
        bp.backgroundColor = props.theme.button.success.backgroundColor;
        bp.backgroundColorHover = props.theme.button.success.backgroundColorHover;
        bp.border = props.theme.button.success.border;
        bp.borderColor = props.theme.button.success.borderColor;
        bp.borderColorHover = props.theme.button.success.borderColorHover;
        /*
    } else if (props.variant === 'pill') {
        bp.color = 'black';
        bp.backgroundColor = 'white';
        bp.backgroundColorHover = pcpColors.lightGray;
        bp.borderColor = pcpColors.midGray;
        bp.borderColorHover = pcpColors.lightGray;
        bp.borderRadius = '20px';
        bp.boxShadow = '0 2px 0 0 #e0e1e1, 0 0 2px 0 #e0e1e1';
    } else if (props.variant === 'lowP') {
        bp.backgroundColor = pcpColors.p4;
        bp.borderColor = pcpColors.p4;
        bp.backgroundColorHover = pcpColors.p5;
        bp.borderColorHover = pcpColors.p5;*/
    }

    return bp;
};

const StyledButton = styled(Button)`
    min-width: 100px;
    width: ${(props) => (props.width ? props.width : null)};
    height: ${(props) => (props.height ? props.height : null)};
    margin-left: ${(props) => (props.marginLeft ? props.marginLeft : null)};
    margin-top: ${(props) => (props.marginTop ? props.marginTop : '5px')};
    margin-right: ${(props) => (props.marginRight ? props.marginRight : null)};
    margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : null)};
    padding-right: ${(props) => (props.paddingRight ? props.paddingRight : null)};
    padding-left: ${(props) => (props.paddingLeft ? props.paddingLeft : null)};
    color: ${(props) => (props.color ? props.color : buttonProps(props).color)};
    background-color: ${(props) =>
        props.backgroundColor ? props.backgroundColor : buttonProps(props).backgroundColor};

    border-top-left-radius: ${(props) =>
        props.borderRadiusTopLeft ? props.borderRadiusTopLeft : buttonProps(props).borderRadiusTopLeft};
    border-top-right-radius: ${(props) =>
        props.borderRadiusTopRight ? props.borderRadiusTopRight : buttonProps(props).borderRadiusTopRight};
    border-bottom-left-radius: ${(props) =>
        props.borderRadiusBottomLeft ? props.borderRadiusBottomLeft : buttonProps(props).borderRadiusBottomLeft};
    border-bottom-right-radius: ${(props) =>
        props.borderRadiusBottomRight ? props.borderRadiusBottomRight : buttonProps(props).borderRadiusBottomRight};
    border-radius: ${(props) => (props.borderRadius ? props.borderRadius : buttonProps(props).borderRadius)};
    border: ${(props) => (props.border ? props.border : buttonProps(props).border)};
    border-color: ${(props) => (props.borderColor ? props.borderColor : buttonProps(props).borderColor)};
    box-shadow: ${(props) => (props.boxShadow ? props.boxShadow : buttonProps(props).boxShadow)};
    &:hover {
        color: ${(props) => (props.colorHover ? props.colorHover : buttonProps(props).colorHover)};
        background-color: ${(props) =>
            props.backgroundColorHover ? props.backgroundColorHover : buttonProps(props).backgroundColorHover};
        border-color: ${(props) =>
            props.borderColorHover ? props.borderColorHover : buttonProps(props).borderColorHover};
    }
    &:focus {
        color: ${(props) => (props.colorHover ? props.colorHover : buttonProps(props).colorHover)};
        background-color: ${(props) =>
            props.backgroundColorHover ? props.backgroundColorHover : buttonProps(props).backgroundColorHover};
        border-color: ${(props) =>
            props.borderColorHover ? props.borderColorHover : buttonProps(props).borderColorHover};
    }
    &:hover:focus:active {
        color: ${(props) => (props.colorHover ? props.colorHover : buttonProps(props).colorHover)};
        background-color: ${(props) =>
            props.backgroundColorHover ? props.backgroundColorHover : buttonProps(props).backgroundColorHover};
        border-color: ${(props) =>
            props.borderColorHover ? props.borderColorHover : buttonProps(props).borderColorHover};
    }
    &:active {
        color: ${(props) => (props.colorHover ? props.colorHover : buttonProps(props).colorHover)} !important;
        background-color: ${(props) =>
            props.backgroundColorHover
                ? props.backgroundColorHover
                : buttonProps(props).backgroundColorHover} !important;
        border-color: ${(props) =>
            props.borderColorHover ? props.borderHover : buttonProps(props).borderColorHover} !important;
    }
    &:disabled {
        background-color: #eeeeee;
        color: gray;
        border: solid 1px;
        border-color: #aaaaaa;
    }
`;

export default StyledButton;
