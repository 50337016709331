import moment from 'moment';

export const getBirthdayPrettyFormatedWithAge = (birthdate: string) => {
    if (birthdate !== undefined) {
        const date = moment(birthdate, 'YYYY-MM-DD', true);
        date.locale('de');
        return date.format('DD.MM.YYYY') + ' (' + moment().diff(date, 'years') + ' Jahre)';
    }

    return birthdate;
};

export const isDateToday = (date: string) => {
    const momentDate = moment(date);
    const isToday = momentDate.isSame(moment(), 'day');
    return isToday;
};

export const isDateBeforeToday = (date: string | Date) => {
    const momentDate = moment(date);
    const isBeforeToday = momentDate.isBefore(moment(), 'day');
    return isBeforeToday;
};

/**
 * get the elapsed time for a time with format (HH:mm - HH:mm)
 *
 * @param time
 * @returns
 *      the elapsed time for a time with format (HH:mm - HH:mm)
 */
export const getCalculatedElapsedTimeFromEndFormat = (time: string) => {
    const times = time.split(' - ');
    return moment(times[0], 'HH:mm').fromNow();
};

export const getCurrentDateTime = () => {
    // return moment().format('YYYY-MM-DDTHH:mm:ss');
    return moment().format();
};

export const setTimeOnDate = (date: string, time: string) => {
    const timeParts = time.split(':');
    return moment(date)
        .set({ hour: parseInt(timeParts[0]), minute: parseInt(timeParts[1]) })
        .format();
};

/**
 * get the current time with format (HH:mm)
 */
export const getCurrentTime = () => {
    const time = moment().format('LT');
    return time;
};
