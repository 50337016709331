import { useEffect, useRef, useState } from 'react';
import * as React from 'react';
import Col from 'react-bootstrap/esm/Col';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import SigPad from 'react-signature-canvas';
import { ISigData } from '../../context/sigpadContext';
import StyledButton from '../Buttons/StyledButton';

/* ### Usage for for Mouse Signature without device ###

    use either onSave OR onWriteEnd

   const onSignaturePadSave = (sigData) => {
        setSignatureData(sigData);
    };

    const onSignaturePadWriteEnd = (sigData) => {
        setSignatureData(sigData);
    };

    <SignaturePad onSave={onSignaturePadSave} onWriteEnd={onSignaturePadWriteEnd}/>
    
 */

interface ISignaturePad {
    onSave?: (sigData: ISigData) => void; // callback when click on buttonSave
    onWriteEnd?: (sigData: ISigData) => void; // callback when end of write
    width?: number; // override width
    height?: number; // override height
    margin?: string;
}

const SignaturePad = (props: ISignaturePad) => {
    const signatureRef = useRef<any>(null);
    const [divWidth, setDivWidth] = useState(700);
    const [divHeight, setDivHeight] = useState(300);
    const [signaturePadWidth, setSignaturePadWidth] = useState(690);
    const [signaturePadHeight, setSignaturePadHeight] = useState(240);

    useEffect(() => {
        /* explicity set width and height override all other sizes */
        if (props.width && props.width > 0) {
            setDivWidth(props.width);
            setSignaturePadWidth(props.width - 10);
        }
        if (props.height && props.height > 0) {
            setDivHeight(props.height);
            setSignaturePadHeight(props.height - 60);
        }
    }, [props.width, props.height]);

    const onSignaturePadClear = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (e) {
            e.currentTarget.blur();
        }
        if (signatureRef) {
            signatureRef?.current.clear();
        }
    };

    const onSignaturePadSave = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (e) {
            e.currentTarget.blur();
        }
        if (signatureRef && props.onSave) {
            const sigDataPicture = signatureRef.current.getTrimmedCanvas().toDataURL('image/png');

            // const sigDataPicture = signatureRef.current.toDataURL('image/svg+xml');
            const sigData: ISigData = {
                certId: '',
                pictureData: sigDataPicture,
                rsaSignature: '',
                signData: '',
                signingCert: '',
            };

            props.onSave(sigData);
        }
    };

    const onSignaturePadWriteEnd = () => {
        if (signatureRef && props.onWriteEnd) {
            const sigDataPicture = signatureRef.current.getTrimmedCanvas().toDataURL('image/png');

            // const sigDataPicture = signatureRef.current.toDataURL('image/svg+xml');
            const sigData: ISigData = {
                certId: '',
                pictureData: sigDataPicture,
                rsaSignature: '',
                signData: '',
                signingCert: '',
            };

            props.onWriteEnd(sigData);
        }
    };

    return (
        <div
            style={{
                borderStyle: 'solid',
                width: divWidth,
                height: divHeight,
                padding: 0,
                margin: props.margin ? props.margin : '0',
            }}
        >
            <div>
                <SigPad
                    ref={signatureRef}
                    penColor="black"
                    onEnd={onSignaturePadWriteEnd}
                    canvasProps={{ width: signaturePadWidth, height: signaturePadHeight, className: 'sigCanvas' }}
                />
            </div>
            <Container>
                <Row>
                    <Col>
                        <StyledButton variant="secondary" onClick={(e) => onSignaturePadClear(e)}>
                            Löschen
                        </StyledButton>
                    </Col>
                    {props.onSave !== undefined && (
                        <Col>
                            <StyledButton onClick={(e) => onSignaturePadSave(e)}>Speichern</StyledButton>
                        </Col>
                    )}
                </Row>
            </Container>
        </div>
    );
};

export default SignaturePad;
