import { useContext } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Chat, { MessageIdType } from '../../components/Chat/Chat';
import { ContainerMain } from '../../components/Div/StyledContainer';
import Video from '../../components/Video/Video';
import AppPropsContext from '../../context/appPropsContext';
import TerminierungContext from '../../context/terminierungContext';
import VideochatContext from '../../context/videochatContext';
import { jitsiOptions } from '../../globals/global';
import { useResizedScreenResolution } from '../../hooks/useScreenResolution';
import NavbarWL from '../Navbar/NavbarWL';

interface IVideochatWL {
    checkInVariant?: boolean;
    onVideoHangup?(): void;
}

declare class JitsiMeetExternalAPI {
    constructor(domain: string, options: any);
}

let api: JitsiMeetExternalAPI | null | any;
const VideochatWL = (props: IVideochatWL) => {
    const { vsstate } = useContext(VideochatContext);
    const { tmstate } = useContext(TerminierungContext);
    const { apstate, apdispatch } = useContext(AppPropsContext);

    const includeExternalScript = (url, whenFinished) => {
        let script: any;
        script = document.querySelector(`script[src="${url}"]`);

        const handleScript = (e) => {
            if (e.type === 'load') {
                whenFinished();
            }
        };

        if (!script) {
            script = document.createElement('script');
            script.type = 'application/javascript';
            script.src = url;
            script.async = true;
            document.body.appendChild(script);
            script.addEventListener('load', handleScript);
            script.addEventListener('error', handleScript);
        } else {
            whenFinished();
        }

        script.addEventListener('load', handleScript);
        script.addEventListener('error', handleScript);
    };

    const doJitsi = () => {
        if (apstate.jitsiDomain) {
            return true;
        }
        return false;
    };
    const [getResizedHeight] = useResizedScreenResolution();
    const jitsiContent = () => {
        const style = {
            height: getResizedHeight(75)?.toString() + 'px',
        };
        const url = 'https://' + apstate.jitsiDomain + '/external_api.js';
        const options = apstate.jitsiOptions;
        options.roomName = tmstate.enrollData.enrollCode;
        const displayName = tmstate?.personalData?.firstName + ' ' + tmstate?.personalData?.lastName;
        options.userInfo = {
            displayName: displayName,
        };
        options.configOverwrite = jitsiOptions.configOverwrite;
        includeExternalScript(url, function () {
            const jp = document.getElementById('jitsiParent');
            if (!api && jp && eval('typeof JitsiMeetExternalAPI') === 'function') {
                options.parentNode = document.getElementById('jitsiParent');
                if (!options.parentNode.querySelector('IFRAME')) {
                    api = new JitsiMeetExternalAPI(apstate.jitsiDomain, options);

                    api.addEventListener('readyToClose', () => {
                        if (props.onVideoHangup) {
                            props.onVideoHangup();
                        }
                    });
                }
            } else {
                if (jp === null || jp.querySelector('IFRAME') === null) {
                    api = null;
                }
            }
        });
        return <div id="jitsiParent" style={style}></div>;
    };

    const normalContent = () => {
        return (
            <div style={{ overflowX: 'hidden' }}>
                {props.checkInVariant ? null : <NavbarWL />}
                <ContainerMain fluid>
                    <div id="outerVideo">
                        <h1>Videochat</h1>
                        <Container style={{ zIndex: 900 }}>
                            <Row>
                                {vsstate.remoteStream && (
                                    <Col xs={10} md={10}>
                                        <Video id="remoteVideo" width={'100%'} stream={vsstate.remoteStream}></Video>
                                    </Col>
                                )}
                                {vsstate.localStream && (
                                    <Col xs={2} md={2}>
                                        <Video id="localVideo" width={'100%'} stream={vsstate.localStream}></Video>
                                    </Col>
                                )}
                            </Row>
                        </Container>
                        {/*
                    <ChatWidget />*/}
                        <Chat
                            idType={MessageIdType.EnrollCode}
                            show={true}
                            asWidget={true}
                            showChatInput={true}
                            id={tmstate.enrollData.enrollCode}
                            incomingMessages={[]}
                        />
                    </div>
                </ContainerMain>
            </div>
        );
    };

    const contentToShow = () => {
        if (doJitsi()) {
            return jitsiContent();
        } else {
            return normalContent();
        }
    };

    return <>{contentToShow()}</>;
};

export default VideochatWL;
