import { useContext, useEffect, useRef, useState } from 'react';
import { Form, Image, Row } from 'react-bootstrap';
import Col from 'react-bootstrap/esm/Col';
import Lottie from 'react-lottie';
import { useLocation, useNavigate, useNavigationType } from 'react-router-dom';
import { Paths } from '../../Routes';
import {
    CenterDiv,
    CenterPositionFixedDiv,
    SpaceBetweenDiv,
    VerticalCenterFlexDiv,
} from '../../components/Div/StyledDiv';
import { CenterRow } from '../../components/Rows/StyledRow';
import CheckInContext from '../../context/checkInContext';
import ScanUtils, { BarcodeScanCallback } from '../../hooks/barcodeKeyHandler';
import loadingSplashWait from '../../lotties/splashscreenWait.json';
import { getOTPFromAppID, getURLToCall, interpreteKVParams } from '../../services/RestServices';
import { getQueryParams } from '../../utils/urlUtils';
import { INeuerAccountWL } from '../NeuerAccount/NeuerAccountWL';

import keinTerminImage from '../../images/CheckInSplash_KeinTermin.png';
import terminVorhandenImage from '../../images/CheckInSplash_TerminVorhanden.png';
import willkommenImage from '../../images/CheckInSplash_Willkommen.png';

import { BiBarcodeReader } from 'react-icons/bi';
import { ThemeContext } from 'styled-components';
import ReadCardButton from '../../components/Buttons/ReadCardButton';
import useScreenResolution from '../../hooks/useScreenResolution';

export interface ICheckInSplashscreen {
    wpName?: string;
}

export interface IURLToCallResponse {
    state: string;
    url: string;
    eGK: string;
    kvBarcode: string;
    birthday: string;
    city: string;
    country: string;
    firstName: string;
    gender: string;
    street: string;
    houseNumber: string;
    nameLast: string;
    zip: string;
    insuranceNr: string;
}

const CheckInSplashscreen = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const navtype = useNavigationType();
    const hashParams: ICheckInSplashscreen = getQueryParams(location.search);
    const { checkindispatch } = useContext(CheckInContext);
    const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
    const [scanStartDetected, setScanStartDetected] = useState(false);
    const screenSize = useScreenResolution();
    const themeContext = useContext(ThemeContext);

    const scanInputRef = useRef<HTMLInputElement>(null);

    const handleUrlTextAfterScan = async (scannedText: string) => {
        let urlToCall = '';
        if (scannedText) {
            // scannedText = scanInputRef.current?.value;
            const urlStart = scannedText.indexOf('https://');
            urlToCall = scannedText.substring(urlStart);

            const hashParamsResponse: any = getQueryParams(urlToCall);
            if (hashParamsResponse.appId) {
                const newOTPData = await getOTPFromAppID(hashParamsResponse.appId, hashParamsResponse.secret);
                checkindispatch({
                    type: 'SETCHECKINDATA',
                    urlToCallResponse: {},
                    key: hashParamsResponse.key,
                    reason: 'ANY', // TODO: Irgendwann mal muessen wir das Ding los werden
                    requesttype: newOTPData.type,
                    wpName: hashParams.wpName,
                    userId: newOTPData.userId,
                    t0: newOTPData.t0,
                    otp: newOTPData.otp,
                });

                navigate(
                    Paths.QUESTIONNAIRE_WL +
                        '?reason=ANY' +
                        '&type=' +
                        newOTPData.type +
                        '&wpName=' +
                        hashParams.wpName,
                );
                setShowLoadingSpinner(false);
            } else {
                checkindispatch({
                    type: 'SETCHECKINDATA',
                    urlToCallResponse: {},
                    key: hashParamsResponse.key,
                    reason: hashParamsResponse.reason,
                    requesttype: hashParamsResponse.type,
                    wpName: hashParams.wpName,
                    userId: hashParamsResponse.userId,
                    t0: hashParamsResponse.t0,
                    otp: hashParamsResponse.otp,
                });

                navigate(
                    Paths.QUESTIONNAIRE_WL +
                        '?reason=' +
                        hashParamsResponse.reason +
                        '&type=' +
                        hashParamsResponse.type +
                        '&wpName=' +
                        hashParams.wpName,
                );
                setShowLoadingSpinner(false);
            }
        }
    };

    const barcodeScannerCallback: BarcodeScanCallback = async (txt) => {
        console.log(txt);
        if (txt.indexOf('https://') >= 0) {
            handleUrlTextAfterScan(txt);
        } else {
            if (txt.startsWith('06\t')) {
                const response = await interpreteKVParams(txt);
                if (response && response.url) {
                    // let urlToOpen = response.url;
                    // /* only for testing */
                    // urlToOpen =
                    //     'https://localhost:3000/#/NeuesProfilWL?type=NewAccount&key=https://demo.siegele-software.com&reason=ANMELDKARD&isMobile=false';
                    // window.open(urlToOpen, '_self');
                    const hashParamsResponse: INeuerAccountWL = getQueryParams(response.url);
                    checkindispatch({
                        type: 'SETCHECKINDATA',
                        urlToCallResponse: response,
                        key: hashParamsResponse.key,
                        reason: hashParamsResponse.reason,
                        requesttype: hashParamsResponse.type,
                        wpName: hashParams.wpName,
                    });

                    navigate(
                        Paths.NEUER_ACCOUNT_WL +
                            '?reason=' +
                            hashParamsResponse.reason +
                            '&type=' +
                            hashParamsResponse.type,
                    );
                    setShowLoadingSpinner(false);
                }
            }
        }
    };

    useEffect(() => {
        /*
        let timer: NodeJS.Timeout | undefined = undefined;
        if (scanStartDetected && timer === undefined) {
            timer = setTimeout(() => {
                handleTextAfterScan();
            }, 3000);
        }
        */
        const bc = ScanUtils();
        if (bc) {
            bc.startup(document.getElementById('MainSplashscreen'), '', barcodeScannerCallback);
        }

        return () => {
            /*
            if (timer) {
                clearTimeout(timer);
            }
            */
            if (bc) {
                bc.removeListeners(document.getElementById('MainSplashscreen'));
            }
        };
    }, [scanStartDetected]);

    const lottieSpinnerOptions = {
        loop: true,
        autoplay: true,
        animationData: loadingSplashWait,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    const LottieSplashWait = () => {
        return <Lottie options={lottieSpinnerOptions} height={200} width={200} />;
    };

    const getURLToCallWorkflow = async () => {
        if (hashParams.wpName) {
            const response: IURLToCallResponse = await getURLToCall(hashParams.wpName);
            if (response && response.state === 'active') {
                // let urlToOpen = response.url;
                // /* only for testing */
                // urlToOpen =
                //     'https://localhost:3000/#/NeuesProfilWL?type=NewAccount&key=https://demo.siegele-software.com&reason=ANMELDKARD&isMobile=false';
                // window.open(urlToOpen, '_self');
                const hashParamsResponse: INeuerAccountWL = getQueryParams(response.url);
                checkindispatch({
                    type: 'SETCHECKINDATA',
                    urlToCallResponse: response,
                    key: hashParamsResponse.key,
                    reason: hashParamsResponse.reason,
                    requesttype: hashParamsResponse.type,
                    wpName: hashParams.wpName,
                });

                navigate(
                    Paths.NEUER_ACCOUNT_WL +
                        '?reason=' +
                        hashParamsResponse.reason +
                        '&type=' +
                        hashParamsResponse.type,
                );
                setShowLoadingSpinner(false);
            }
        }
    };

    const handleClickWithCard = () => {
        setShowLoadingSpinner(true);
        getURLToCallWorkflow();
    };

    /*
    const handleScanfieldChange = (e: any) => {
        if (e.target.value) {
            if (!scanStartDetected) {
                setShowLoadingSpinner(true);
                setScanStartDetected(true);
            }
        }
    };
    */

    const getArrowImageWidth = () => {
        if (screenSize.width > 1600) {
            return '50%';
        } else if (screenSize.width > 1000) {
            return '70%';
        } else {
            return '80%';
        }
    };

    return (
        // <VerticalCenterDiv divTop="40%" id="MainSplashscreen">
        //     <CenterDiv>
        //         <CenterDiv marginBottom="100px">
        //             <h1>CheckIn Terminal</h1>
        //             <br />
        //             <p>Sie haben einen Termin? Bitte scannen Sie den Barcode Ihrer Terminbestätigung ein.</p>
        //             <br />
        //             <p>
        //                 Sie haben einen Überweisungsschein mit Barcode? Bitte scannen Sie den Barcode Ihres
        //                 Überweisungsscheins ein.
        //             </p>
        //             <br />
        //             <p>
        //                 Sie haben noch keinen Termin? Bitte stecken Sie ihre Gesundheitskarte in das Kartenlesegerät und
        //                 klicken Sie auf &quot;Karte lesen&quot;.
        //             </p>
        //             {showLoadingSpinner ? <LottieSplashWait /> : null}
        //         </CenterDiv>
        //         <CenterRow>
        //             <Col>
        //                 <StyledButton size="lg" disabled={showLoadingSpinner} onClick={() => handleClickWithCard()}>
        //                     Karte lesen
        //                 </StyledButton>
        //             </Col>
        //             <Col>
        //                 <Form>
        //                     <Form.Group controlId="scanInputField" style={{ marginBottom: '0rem' }}>
        //                         <Form.Control
        //                             ref={scanInputRef}
        //                             autoFocus
        //                             style={{ height: '100px', width: '50%' }}
        //                             type="text"
        //                             placeholder="Hier klicken und dann scannen"
        //                             /* onChange={(e) => handleScanfieldChange(e)} */
        //                         />
        //                     </Form.Group>
        //                 </Form>
        //             </Col>
        //         </CenterRow>
        //     </CenterDiv>
        // </VerticalCenterDiv>
        <>
            <div
                style={{ height: '100vh', width: '100vw', background: '#a3c1e9', overflow: 'hidden' }}
                id="MainSplashscreen"
            >
                <CenterDiv>
                    <CenterDiv marginBottom="100px" marginTop="20px">
                        <Row>
                            <Col></Col>
                            <Col>
                                <Image src={willkommenImage} width={'500px'} />
                            </Col>
                            <Col></Col>
                        </Row>
                        {/* {showLoadingSpinner ? <LottieSplashWait /> : null} */}
                        <Row>
                            <Col>
                                <Image src={terminVorhandenImage} width={getArrowImageWidth()} />
                            </Col>
                            <Col>
                                <Image src={keinTerminImage} width={getArrowImageWidth()} />
                            </Col>
                        </Row>
                    </CenterDiv>
                    <CenterRow>
                        <Col>
                            <SpaceBetweenDiv
                                style={{
                                    height: '100px',
                                    width: screenSize.width > 1000 ? '50%' : '70%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    backgroundColor: 'white',
                                    borderRadius: '100px',
                                    paddingLeft: '20px',
                                    paddingRight: '20px',
                                    boxShadow: themeContext.button.boxShadow,
                                }}
                            >
                                <VerticalCenterFlexDiv>
                                    <BiBarcodeReader size="80px" />
                                </VerticalCenterFlexDiv>
                                <VerticalCenterFlexDiv fontSize="1.5rem" divWidth="100%">
                                    <Form style={{ marginBlockEnd: '0em' }}>
                                        <Form.Group
                                            controlId="scanInputField"
                                            style={{ marginBottom: '0rem', marginBlockEnd: '0em' }}
                                        >
                                            <Form.Control
                                                ref={scanInputRef}
                                                autoFocus
                                                style={{
                                                    height: '70px',
                                                    width: '100%',
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto',
                                                    borderRadius: '100px',
                                                    borderColor: 'transparent',
                                                    textAlign: 'center',
                                                    fontSize: screenSize.width > 1300 ? '1.5rem' : '1rem',
                                                }}
                                                type="text"
                                                placeholder="Code scannen"
                                            />
                                        </Form.Group>
                                    </Form>
                                </VerticalCenterFlexDiv>
                            </SpaceBetweenDiv>
                        </Col>
                        <Col>
                            <ReadCardButton
                                width={screenSize.width > 1000 ? '50%' : '70%'}
                                textSize={screenSize.width > 1300 ? '1.5rem' : '1rem'}
                                disabled={showLoadingSpinner}
                                onClick={handleClickWithCard}
                            ></ReadCardButton>
                        </Col>
                    </CenterRow>
                </CenterDiv>
            </div>
            <CenterPositionFixedDiv>{showLoadingSpinner ? <LottieSplashWait /> : null}</CenterPositionFixedDiv>
        </>
    );
};

export default CheckInSplashscreen;
