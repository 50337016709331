import { lazy, Suspense, useContext } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import AppCharts from './components/Charts/AppCharts/AppCharts';
import IFrame from './components/IFrame/IFrame';
import IFrameFragebogen from './components/IFrame/IFrameFragebogen';
import { LoadingSpinner, LoadingSpinnerInMainscreen } from './components/Loading/Loading';
import Charts from './container/Charts/Charts';
import ChartsActivity from './container/Charts/ChartsActivity/ChartsActivity';
import ChartsBlutzucker from './container/Charts/ChartsBlutzucker/ChartsBlutzucker';
import ChartsFettmasse from './container/Charts/ChartsFettmasse/ChartsFettmasse';
import ChartsFLI from './container/Charts/ChartsFLI/ChartsFLI';
import ChartsGewicht from './container/Charts/ChartsGewicht/ChartsGewicht';
import ChartsSAM from './container/Charts/ChartsSAM/ChartsSAM';
import Error from './container/Error/Error';
import ExecuteRule from './container/ExecuteRule/ExecuteRule';
import Home from './container/Home/Home';
import NeuerAccountPhysLogin from './container/NeuerAccount/NeuerAccountPhysLogin';
import CheckInSplashscreen from './container/Splashscreen/CheckInSplashscreen';
import CheckInSplashscreenWaiting from './container/Splashscreen/CheckInSplashscreenWaiting';
import LoggedOutSplashscreen from './container/Splashscreen/LoggedOutSplashscreen';
import TableVAL from './container/Tables/TableVAL/TableVAL';
import LoginContext from './context/loginContext';

const AppInfo = lazy(() => import('./container/AppInfo/AppInfo'));

const Dashboard = lazy(() => import('./container/Dashboard/Dashboard'));
const Termine = lazy(() => import('./container/Termine/Termine'));
const PatientList = lazy(() => import('./container/PatientList/PatientList'));
const Dokumente = lazy(() => import('./container/Dokumente/Dokumente'));
const Login = lazy(() => import('./container/Login/Login'));

const Fotos = lazy(() => import('./container/Fotos/Fotos'));
const Medikation = lazy(() => import('./container/Medikation/Medikation'));
const Tagesplan = lazy(() => import('./container/Tagesplan/Tagesplan'));
const Journey = lazy(() => import('./container/Journey/Journey'));
const Fragebogen = lazy(() => import('./container/Fragebogen/Fragebogen'));
const FragebogenDetail = lazy(() => import('./container/Fragebogen/FragebogenDetail'));
const Profil = lazy(() => import('./container/Profil/Profil'));

const Videochat = lazy(() => import('./container/Videochat/Videochat'));
const VideochatWL = lazy(() => import('./container/Videochat/VideochatWL'));
const Terminierung = lazy(() => import('./container/Terminierung/Terminierung'));
const TerminierungAlternateDate = lazy(() => import('./container/Terminierung/TerminierungAlternateDate'));
const Terminkategorie = lazy(() => import('./container/Terminkategorie/Terminkategorie'));
const Termincode = lazy(() => import('./container/Termincode/Termincode'));
const Warteraum = lazy(() => import('./container/Warteraum/Warteraum'));
const PersonalInformation = lazy(() => import('./components/User/PersonalInformation'));
const ProfileSafety = lazy(() => import('./components/User/ProfileSafety'));
const ProfileLegalTerms = lazy(() => import('./components/User/LegalTerms'));
const TwoFactorAuthentication = lazy(() => import('./components/TwoFactorAuthentication/TwoFactorAuthentication'));
const NeuerAccount = lazy(() => import('./container/NeuerAccount/NeuerAccount'));
const NeuerAccountWL = lazy(() => import('./container/NeuerAccount/NeuerAccountWL'));
const QRCode4App = lazy(() => import('./components/User/HandyAppQRCode'));
const ResetPassword = lazy(() => import('./components/User/ResetPassword'));
const Mails = lazy(() => import('./container/Mails/Mails'));
const QuestionnaireWL = lazy(() => import('./container/Fragebogen/QuestionnaireWL'));
const TerminierungWL = lazy(() => import('./container/Terminierung/TerminierungWL'));
const Todo = lazy(() => import('./container/Todo/Todo'));
const CheckInWL = lazy(() => import('./container/CheckIn/CheckInWL'));

interface IPaths {
    readonly HOME: string;
    readonly LOGIN: string;
    readonly APPINFO: string;
    readonly DASHBOARD: string;
    readonly TERMINE: string;
    readonly PATLIST: string;
    readonly DOKUMENTE: string;
    readonly FOTOS: string;
    readonly MEDIKATION: string;
    readonly TAGESPLAN: string;
    readonly JOURNEY: string;
    readonly FRAGEBOGEN: string;
    readonly FRAGEBOGEN_DETAIL: string;
    readonly QUESTIONNAIRE_WL: string;
    readonly IFRAME: string;
    readonly FRAGEBOGEN_IFRAME: string;
    readonly VIDEOCHAT: string;
    readonly VIDEOCHAT_WL: string;
    readonly TERMINIERUNG: string;
    readonly TERMINIERUNG_ALTERNATE_DATE: string;
    readonly TERMINIERUNG_WL: string;
    readonly PROFIL: string;
    readonly PROFIL_INFO: string;
    readonly PROFIL_SICHERHEIT: string;
    readonly PROFIL_LEGALTERMS: string;
    readonly ZWEI_FAKTOR_AUTHENTIFIZIERUNG: string;
    readonly TERMINKATEGORIE: string;
    readonly TERMINCODE: string;
    readonly TERMIN_DETAIL: string;
    readonly WARTERAUM: string;
    readonly NEUER_ACCOUNT: string;
    readonly NEUER_ACCOUNT_WL: string;
    readonly NEUER_ACCOUNT_PHYSLOGIN: string;
    readonly AENDERN_ACCOUNT: string;
    readonly QRCODE4APP: string;
    readonly RESET_PASSWORD: string;
    readonly MAILS: string;
    readonly CHARTS: string;
    readonly CHARTS_GEWICHT: string;
    readonly CHARTS_FETTMASSE: string;
    readonly CHARTS_BLUTZUCKER: string;
    readonly CHARTS_FLI: string;
    readonly CHARTS_SAM: string;
    readonly CHARTS_ACTIVITY: string;
    readonly APP_CHARTS: string;
    readonly TABLE_VAL: string;
    readonly EXECUTE_RULE: string;
    readonly TODO: string;
    readonly CHECKIN_WL: string;
    readonly SPLASHSCREEN_CHECKIN: string;
    readonly SPLASHSCREEN_CHECKIN_WAITING: string;
    readonly SPLASHSCREEN_LOGGEDOUT: string;
}

export const Paths: IPaths = {
    HOME: '/',
    LOGIN: '/Login',
    APPINFO: '/AppInfo',
    DASHBOARD: '/Dashboard',
    TERMINE: '/Termine',
    PATLIST: '/PatList',
    DOKUMENTE: '/Dokumente',
    FOTOS: '/Fotos',
    MEDIKATION: '/Medikation',
    TAGESPLAN: '/Tagesplan',
    JOURNEY: '/Journey',
    FRAGEBOGEN: '/Fragebogen',
    FRAGEBOGEN_DETAIL: '/FragebogenDetail',
    QUESTIONNAIRE_WL: '/QuestionnaireWL',
    IFRAME: '/IFrame',
    FRAGEBOGEN_IFRAME: '/FragebogenInfo',
    VIDEOCHAT: '/Videochat',
    VIDEOCHAT_WL: '/VideochatWL',
    TERMINIERUNG: '/Terminierung',
    TERMINIERUNG_ALTERNATE_DATE: '/TerminierungAlternateDate',
    TERMINIERUNG_WL: '/TerminierungWL',
    PROFIL: '/Profil',
    PROFIL_INFO: '/ProfilInfo',
    PROFIL_SICHERHEIT: '/ProfilSicherheit',
    PROFIL_LEGALTERMS: '/ProfilBestimmungen',
    ZWEI_FAKTOR_AUTHENTIFIZIERUNG: '/2FA',
    TERMINKATEGORIE: '/Terminkategorie',
    TERMINCODE: '/Termincode',
    TERMIN_DETAIL: 'TerminDetails',
    WARTERAUM: '/Warteraum',
    NEUER_ACCOUNT: '/NeuesProfil',
    NEUER_ACCOUNT_WL: '/NeuesProfilWL',
    NEUER_ACCOUNT_PHYSLOGIN: '/NeuesProfilPhysLogin',
    AENDERN_ACCOUNT: '/ProfilAendern',
    QRCODE4APP: '/AppCode',
    RESET_PASSWORD: '/PasswortZuruecksetzen',
    MAILS: '/Mails',
    CHARTS: '/Charts',
    CHARTS_GEWICHT: '/ChartsGewicht',
    CHARTS_FETTMASSE: '/ChartsFettmasse',
    CHARTS_BLUTZUCKER: '/ChartsBlutzucker',
    CHARTS_FLI: '/ChartsFLI',
    CHARTS_SAM: '/ChartsSAM',
    CHARTS_ACTIVITY: '/ChartsActivity',
    APP_CHARTS: '/AppCharts',
    TABLE_VAL: '/TableVal',
    EXECUTE_RULE: '/ExecuteRule',
    TODO: '/Todo',
    CHECKIN_WL: '/CheckInWL',
    SPLASHSCREEN_CHECKIN: '/CheckInSplashscreen',
    SPLASHSCREEN_CHECKIN_WAITING: '/CheckInSplashscreenWaiting',
    SPLASHSCREEN_LOGGEDOUT: '/LoggedOutSplashscreen',
};

const PublicRoute = ({ children }) => {
    return <Suspense fallback={<LoadingSpinner />}>{children}</Suspense>;
};

const PrivateRoute = ({ children }) => {
    const { state } = useContext(LoginContext);
    const navigate = useNavigate();
    // const { handleLogout } = useHandleLogout();

    if (state.sessionId !== '' && state.userId !== '') {
        return <Suspense fallback={<LoadingSpinnerInMainscreen />}>{children}</Suspense>;
    } else {
        // history.push('/');
        navigate(Paths.HOME);
        return (
            <Suspense fallback={<LoadingSpinnerInMainscreen />}>
                <Home />
            </Suspense>
        );
    }
};

function AppRoutes() {
    return (
        <Routes>
            <Route
                path={Paths.HOME}
                element={
                    <PublicRoute>
                        <Home />
                    </PublicRoute>
                }
            ></Route>
            <Route
                path={Paths.LOGIN}
                element={
                    <PublicRoute>
                        <Login />
                    </PublicRoute>
                }
            ></Route>
            <Route
                path="/AppInfo"
                element={
                    <PublicRoute>
                        <AppInfo />
                    </PublicRoute>
                }
            ></Route>
            <Route
                path={Paths.TERMINKATEGORIE}
                element={
                    <PublicRoute>
                        <Terminkategorie />
                    </PublicRoute>
                }
            ></Route>
            <Route
                path={Paths.TERMINIERUNG_WL}
                element={
                    <PublicRoute>
                        <TerminierungWL />
                    </PublicRoute>
                }
            ></Route>
            <Route
                path={Paths.DASHBOARD}
                element={
                    <PublicRoute>
                        <Dashboard />
                    </PublicRoute>
                }
            ></Route>
            <Route
                path={Paths.TERMINCODE}
                element={
                    <PublicRoute>
                        <Termincode />
                    </PublicRoute>
                }
            ></Route>
            <Route
                path={Paths.VIDEOCHAT_WL}
                element={
                    <PublicRoute>
                        <VideochatWL />
                    </PublicRoute>
                }
            ></Route>
            <Route
                path={Paths.WARTERAUM}
                element={
                    <PublicRoute>
                        <Warteraum />
                    </PublicRoute>
                }
            ></Route>
            <Route
                path={Paths.NEUER_ACCOUNT}
                element={
                    <PublicRoute>
                        <NeuerAccount />
                    </PublicRoute>
                }
            ></Route>
            <Route
                path={Paths.NEUER_ACCOUNT_WL}
                element={
                    <PublicRoute>
                        <NeuerAccountWL />
                    </PublicRoute>
                }
            ></Route>
            <Route
                path={Paths.RESET_PASSWORD}
                element={
                    <PublicRoute>
                        <ResetPassword />
                    </PublicRoute>
                }
            ></Route>
            <Route
                path={Paths.MAILS}
                element={
                    <PublicRoute>
                        <Mails />
                    </PublicRoute>
                }
            ></Route>
            <Route
                path={Paths.APP_CHARTS}
                element={
                    <PublicRoute>
                        <AppCharts />
                    </PublicRoute>
                }
            ></Route>
            <Route
                path={Paths.QUESTIONNAIRE_WL}
                element={
                    <PublicRoute>
                        <QuestionnaireWL />
                    </PublicRoute>
                }
            ></Route>
            <Route
                path={Paths.CHECKIN_WL}
                element={
                    <PublicRoute>
                        <CheckInWL useLoginContext={false} />
                    </PublicRoute>
                }
            ></Route>
            <Route
                path={Paths.SPLASHSCREEN_CHECKIN}
                element={
                    <PublicRoute>
                        <CheckInSplashscreen />
                    </PublicRoute>
                }
            ></Route>
            <Route
                path={Paths.SPLASHSCREEN_CHECKIN_WAITING}
                element={
                    <PublicRoute>
                        <CheckInSplashscreenWaiting />
                    </PublicRoute>
                }
            ></Route>
            <Route
                path={Paths.SPLASHSCREEN_LOGGEDOUT}
                element={
                    <PublicRoute>
                        <LoggedOutSplashscreen />
                    </PublicRoute>
                }
            ></Route>
            <Route
                path={Paths.EXECUTE_RULE}
                element={
                    <PublicRoute>
                        <ExecuteRule />
                    </PublicRoute>
                }
            ></Route>

            <Route
                path={Paths.TERMINE}
                element={
                    <PrivateRoute>
                        <Termine />
                    </PrivateRoute>
                }
            ></Route>
            <Route
                path={Paths.PATLIST}
                element={
                    <PrivateRoute>
                        <PatientList />
                    </PrivateRoute>
                }
            ></Route>
            <Route
                path={Paths.DOKUMENTE}
                element={
                    <PrivateRoute>
                        <Dokumente />
                    </PrivateRoute>
                }
            ></Route>
            <Route
                path={Paths.FOTOS}
                element={
                    <PrivateRoute>
                        <Fotos />
                    </PrivateRoute>
                }
            ></Route>
            <Route
                path={Paths.MEDIKATION}
                element={
                    <PrivateRoute>
                        <Medikation />
                    </PrivateRoute>
                }
            ></Route>
            <Route
                path={Paths.TAGESPLAN}
                element={
                    <PrivateRoute>
                        <Tagesplan />
                    </PrivateRoute>
                }
            ></Route>
            <Route
                path={Paths.JOURNEY}
                element={
                    <PrivateRoute>
                        <Journey />
                    </PrivateRoute>
                }
            ></Route>
            <Route
                path={Paths.FRAGEBOGEN}
                element={
                    <PrivateRoute>
                        <Fragebogen />
                    </PrivateRoute>
                }
            ></Route>
            <Route
                path={Paths.FRAGEBOGEN_DETAIL}
                element={
                    <PrivateRoute>
                        <FragebogenDetail />
                    </PrivateRoute>
                }
            ></Route>
            <Route
                path={Paths.IFRAME}
                element={
                    <PrivateRoute>
                        <IFrame />
                    </PrivateRoute>
                }
            ></Route>
            <Route
                path={Paths.FRAGEBOGEN_IFRAME}
                element={
                    <PrivateRoute>
                        <IFrameFragebogen />
                    </PrivateRoute>
                }
            ></Route>
            <Route
                path={Paths.VIDEOCHAT}
                element={
                    <PrivateRoute>
                        <Videochat />
                    </PrivateRoute>
                }
            ></Route>
            <Route
                path={Paths.TERMINIERUNG}
                element={
                    <PrivateRoute>
                        <Terminierung />
                    </PrivateRoute>
                }
            ></Route>
            <Route
                path={Paths.NEUER_ACCOUNT_PHYSLOGIN}
                element={
                    <PrivateRoute>
                        <NeuerAccountPhysLogin />
                    </PrivateRoute>
                }
            ></Route>
            <Route
                path={Paths.TERMINIERUNG_ALTERNATE_DATE}
                element={
                    <PrivateRoute>
                        <TerminierungAlternateDate />
                    </PrivateRoute>
                }
            ></Route>
            <Route
                path={Paths.PROFIL}
                element={
                    <PrivateRoute>
                        <Profil />
                    </PrivateRoute>
                }
            ></Route>
            <Route
                path={Paths.PROFIL_INFO}
                element={
                    <PrivateRoute>
                        <PersonalInformation />
                    </PrivateRoute>
                }
            ></Route>
            <Route
                path={Paths.PROFIL_SICHERHEIT}
                element={
                    <PrivateRoute>
                        <ProfileSafety />
                    </PrivateRoute>
                }
            ></Route>
            <Route
                path={Paths.PROFIL_LEGALTERMS}
                element={
                    <PrivateRoute>
                        <ProfileLegalTerms />
                    </PrivateRoute>
                }
            ></Route>
            <Route
                path={Paths.ZWEI_FAKTOR_AUTHENTIFIZIERUNG}
                element={
                    <PrivateRoute>
                        <TwoFactorAuthentication />
                    </PrivateRoute>
                }
            ></Route>
            <Route
                path={Paths.QRCODE4APP}
                element={
                    <PrivateRoute>
                        <QRCode4App />
                    </PrivateRoute>
                }
            ></Route>
            <Route
                path={Paths.AENDERN_ACCOUNT}
                element={
                    <PrivateRoute>
                        <NeuerAccount />
                    </PrivateRoute>
                }
            ></Route>
            <Route
                path={Paths.TODO}
                element={
                    <PrivateRoute>
                        <Todo />
                    </PrivateRoute>
                }
            ></Route>

            <Route
                path={Paths.CHARTS}
                element={
                    <PrivateRoute>
                        <Charts />
                    </PrivateRoute>
                }
            ></Route>
            <Route
                path={Paths.CHARTS_GEWICHT}
                element={
                    <PrivateRoute>
                        <ChartsGewicht />
                    </PrivateRoute>
                }
            ></Route>
            <Route
                path={Paths.CHARTS_FETTMASSE}
                element={
                    <PrivateRoute>
                        <ChartsFettmasse />
                    </PrivateRoute>
                }
            ></Route>
            <Route
                path={Paths.CHARTS_BLUTZUCKER}
                element={
                    <PrivateRoute>
                        <ChartsBlutzucker />
                    </PrivateRoute>
                }
            ></Route>
            <Route
                path={Paths.CHARTS_FLI}
                element={
                    <PrivateRoute>
                        <ChartsFLI />
                    </PrivateRoute>
                }
            ></Route>
            <Route
                path={Paths.CHARTS_SAM}
                element={
                    <PrivateRoute>
                        <ChartsSAM />
                    </PrivateRoute>
                }
            ></Route>
            <Route
                path={Paths.CHARTS_ACTIVITY}
                element={
                    <PrivateRoute>
                        <ChartsActivity />
                    </PrivateRoute>
                }
            ></Route>
            <Route
                path={Paths.TABLE_VAL}
                element={
                    <PrivateRoute>
                        <TableVAL />
                    </PrivateRoute>
                }
            ></Route>

            <Route
                path={Paths.TABLE_VAL}
                element={
                    <PrivateRoute>
                        <TableVAL />
                    </PrivateRoute>
                }
            ></Route>

            <Route path="*" element={<Error />}></Route>
        </Routes>
    );
}

export default AppRoutes;
