import { useCallback, useContext } from 'react';
import { IAuthData } from '../container/CheckIn/CheckInWL';
import LoginContext from '../context/loginContext';
import TerminierungContext from '../context/terminierungContext';
import { getFullOTPAuthorization } from '../utils/pcpUtils';

export const useAuth = () => {
    const { dispatch } = useContext(LoginContext);
    const { tmdispatch } = useContext(TerminierungContext);

    const setCheckInAuth = useCallback(
        (authData: IAuthData) => {
            const auth = getFullOTPAuthorization('PCP', authData.otp, authData.userId, authData.t0);
            dispatch({
                type: 'SETCHECKINAUTH',
                userId: authData.userId,
                activeUserId: authData.userId,
                sessionId: auth,
            });
            tmdispatch({
                type: 'SETWITHCHECKIN',
                withCheckIn: true,
            });
        },
        [dispatch],
    );

    return {
        setCheckInAuth,
    };
};
