import produce from 'immer';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Paths } from '../../Routes';
import AlertContext, { AlertOnHide, AlertType } from '../../context/alertContext';
import CheckInContext from '../../context/checkInContext';
import LoginContext from '../../context/loginContext';
import TerminierungContext from '../../context/terminierungContext';
import { useAppointmentSelection } from '../../hooks/useAppointmentSelection';
import { usePersonalData } from '../../hooks/usePersonalData';
import { IEnrollData, enroll } from '../../services/RestServices';
import { bookAppointment } from '../../services/fhir/FHIRAppointment';
import { showAlertMessage } from '../../utils/alertHandling';
import { APPOINTMENT_POSTPONE_REASON } from '../../utils/appointmentUtils';
import StyledButton from '../Buttons/StyledButton';
import { isTimeslotDateSet } from '../Calendar/TimeSlots';
import StyledCol from '../Cols/StyledCol';
import { StickyButtonRowBottomDiv } from '../Div/StyledDiv';
import CodeInputModal, { ICodeInputModalShow } from '../Modals/CodeInputModal/CodeInputModal';
import { StyledRowNoMargin } from '../Rows/StyledRow';
import Booking from './Booking';

interface IBookingAppointment {
    reason: string;
    handleBookingClick(response: any, currentActiveStep: number): void;
    handleCancelClick(currentActiveStep: number): void;
    currentActiveStep: number;
    reasonGroupText?: string;
    useLoginContext: boolean;
    isPhysicianLogin?: boolean;
}

interface IBookingButtons {
    handleBooking(): void;
    handleCancel(): void;
}

const BookingButtons = (props: IBookingButtons) => {
    const handleCancel = () => {
        props.handleCancel();
    };
    const handleBooking = () => {
        props.handleBooking();
    };

    return (
        <StickyButtonRowBottomDiv marginTop="30px">
            <StyledRowNoMargin>
                <StyledCol paddingLeft="0px" paddingRight="0px" textAlign="right">
                    <StyledButton variant="secondary" onClick={() => handleCancel()}>
                        Abbrechen
                    </StyledButton>
                    <StyledButton marginLeft="100px" onClick={() => handleBooking()}>
                        Jetzt buchen
                    </StyledButton>
                </StyledCol>
            </StyledRowNoMargin>
        </StickyButtonRowBottomDiv>
    );
};

const BookingAppointment = (props: IBookingAppointment) => {
    const { getEnrollData, getEnrollDataPhysPat } = usePersonalData();
    const { state } = useContext(LoginContext);
    const { checkinstate } = useContext(CheckInContext);
    const { tmstate } = useContext(TerminierungContext);
    const { alertdispatch } = useContext(AlertContext);
    const { getSelectedTimeslot, getSelectedResource, isEarlierAppointmentPossible } = useAppointmentSelection();
    const [bookingCode, setBookingCode] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        if (bookingCode && bookingCode.length > 0) {
            innerBookAppointment();
        }
    }, [bookingCode]);

    const [codeModalShow, setCodeModalShow] = useState<ICodeInputModalShow>({
        show: false,
        modalTitle: '',
        modalText: '',
    });

    const shouldCreateAccount = () => {
        if (tmstate.personalData.username.length > 0 && tmstate.personalData.password.length > 0) {
            return true;
        }
        return false;
    };

    const getEnrollDataForBooking = async (isPhysicianLogin: boolean) => {
        let enrollData: IEnrollData;
        if (isPhysicianLogin) {
            enrollData = await getEnrollDataPhysPat();
        } else {
            enrollData = await getEnrollData();
        }

        if (bookingCode && bookingCode.length > 0) {
            enrollData.bookingCode = bookingCode;
        }

        if (tmstate.cancelNewAppointmentData.reason === APPOINTMENT_POSTPONE_REASON) {
            enrollData.makroId = tmstate.enrollData.fhir.serviceType[0].coding[0].extension[0].valueInteger.toString();
        }

        if (checkinstate.urlToCallResponse.eGK && checkinstate.urlToCallResponse.eGK.length > 0) {
            enrollData.eGK = checkinstate.urlToCallResponse.eGK;
        }
        if (checkinstate.urlToCallResponse.kvBarcode && checkinstate.urlToCallResponse.kvBarcode.length > 0) {
            enrollData.kvBarcode = checkinstate.urlToCallResponse.kvBarcode;
        }
        if (checkinstate.urlToCallResponse.kvBarcode && checkinstate.urlToCallResponse.kvBarcode.length > 0) {
            enrollData.kvBarcode = checkinstate.urlToCallResponse.kvBarcode;
        }

        return enrollData;
    };

    const innerBookAppointment = async () => {
        if (props.isPhysicianLogin) {
            const enrollData: IEnrollData = await getEnrollDataForBooking(true);
            setBookingCode('');

            const response = await enroll(enrollData, props.reason);

            if (!response || response.error) {
                if (response?.error.includes('MISSINGBC:')) {
                    const text = response.error.slice('MISSINGBC:'.length, response.error.length);
                    setCodeModalShow({
                        show: true,
                        modalTitle: 'Buchungscode',
                        modalText: text,
                    });
                } else {
                    showAlertMessage({
                        alertTitle: 'Terminbuchung',
                        alertTxt: response?.error ? response.error : 'Fehler in der Terminbuchung',
                        alertType: AlertType.error,
                        onHide: AlertOnHide.onlyClose,
                        alertdispatch: alertdispatch,
                    });
                }
            } else {
                props.handleBookingClick(response, props.currentActiveStep);
            }
        } else if (props.useLoginContext) {
            let selectedResource;
            let selectedResource_2;
            let multiAppointment = false;

            const timeslot_1 = getSelectedTimeslot(1);
            const resource_1 = getSelectedResource(1);
            if (isTimeslotDateSet(timeslot_1)) {
                selectedResource = produce(resource_1, (draft: any) => {
                    draft.start = timeslot_1?.start;
                    draft.end = timeslot_1?.end;
                });
            }

            const timeslot_2 = getSelectedTimeslot(2);
            const resource_2 = getSelectedResource(2);
            if (isTimeslotDateSet(timeslot_2)) {
                selectedResource_2 = produce(resource_2, (draft: any) => {
                    draft.start = timeslot_2?.start;
                    draft.end = timeslot_2?.end;
                });
                multiAppointment = true;
            }
            const response = await bookAppointment(
                state.sessionId,
                selectedResource,
                tmstate.remark,
                tmstate.earlierAppointmentWanted,
            );
            if (!response || response.error) {
                if (response?.error.includes('MISSINGBC:')) {
                    const text = response.error.slice('MISSINGBC:'.length, response.error.length);
                    setCodeModalShow({
                        show: true,
                        modalTitle: 'Buchungscode',
                        modalText: text,
                    });
                } else {
                    showAlertMessage({
                        alertTitle: 'Terminbuchung',
                        alertTxt: response?.error ? response.error : 'Fehler in der Terminbuchung',
                        alertType: AlertType.error,
                        onHide: AlertOnHide.onlyClose,
                        alertdispatch: alertdispatch,
                    });
                }
            } else {
                navigate(Paths.TERMINE);
            }
        } else {
            const enrollData: IEnrollData = await getEnrollDataForBooking(false);
            setBookingCode('');

            const response = await enroll(enrollData, props.reason);

            if (!response || response.error) {
                if (response?.error.includes('MISSINGBC:')) {
                    const text = response.error.slice('MISSINGBC:'.length, response.error.length);
                    setCodeModalShow({
                        show: true,
                        modalTitle: 'Buchungscode',
                        modalText: text,
                    });
                } else {
                    showAlertMessage({
                        alertTitle: 'Terminbuchung',
                        alertTxt: response?.error ? response.error : 'Fehler in der Terminbuchung',
                        alertType: AlertType.error,
                        onHide: AlertOnHide.onlyClose,
                        alertdispatch: alertdispatch,
                    });
                }
            } else {
                props.handleBookingClick(response, props.currentActiveStep);
            }
        }
    };

    const handleBookingClick = () => {
        innerBookAppointment();
    };

    const handleCancelClick = () => {
        props.handleCancelClick(props.currentActiveStep);
    };

    const handleCloseCodeInputModal = () => {
        setCodeModalShow({
            show: false,
            modalTitle: '',
            modalText: '',
        });
    };

    const handleSubmitCodeInputModal = (code: string) => {
        setBookingCode(code);
        setCodeModalShow({
            show: false,
            modalTitle: '',
            modalText: '',
        });
    };

    return (
        <>
            <Booking
                reasonGroupText={props.reasonGroupText}
                showEarlierAppointmentPossible={isEarlierAppointmentPossible()}
            />
            <BookingButtons handleBooking={handleBookingClick} handleCancel={handleCancelClick} />
            <CodeInputModal
                show={codeModalShow.show}
                modalText={codeModalShow.modalText}
                modalTitle={codeModalShow.modalTitle}
                onHide={() => handleCloseCodeInputModal()}
                onSubmit={handleSubmitCodeInputModal}
            />
        </>
    );
};

export default BookingAppointment;
