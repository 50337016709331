import { IURLToCallResponse } from '../container/Splashscreen/CheckInSplashscreen';
import { InitialCheckInType } from './checkInContext';

type Action =
    | {
          type: 'SETCHECKINDATA';
          urlToCallResponse: IURLToCallResponse;
          key: string;
          reason: string;
          requesttype: string;
          wpName: string;
          userId?: string;
          t0?: string;
          otp?: string;
      }
    | {
          type: 'RESETALL';
      };

export const checkinReducer = (state: InitialCheckInType, action: Action) => {
    switch (action.type) {
        case 'SETCHECKINDATA':
            return {
                ...state,
                urlToCallResponse: {
                    state: action.urlToCallResponse.state,
                    url: action.urlToCallResponse.url,
                    eGK: action.urlToCallResponse.eGK,
                    kvBarcode: action.urlToCallResponse.kvBarcode,
                    birthday: action.urlToCallResponse.birthday,
                    city: action.urlToCallResponse.city,
                    country: action.urlToCallResponse.country,
                    firstName: action.urlToCallResponse.firstName,
                    gender: action.urlToCallResponse.gender,
                    street: action.urlToCallResponse.street,
                    houseNumber: action.urlToCallResponse.houseNumber,
                    nameLast: action.urlToCallResponse.nameLast,
                    zip: action.urlToCallResponse.zip,
                    insuranceNr: action.urlToCallResponse.insuranceNr,
                },
                key: action.key,
                reason: action.reason,
                requesttype: action.type,
                wpName: action.wpName,
                userId: action.userId ? action.userId : '',
                t0: action.t0 ? action.t0 : '',
                otp: action.otp ? action.otp : '',
            };
        case 'RESETALL':
            return {
                urlToCallResponse: {
                    state: '',
                    url: '',
                    eGK: '',
                    kvBarcode: '',
                    birthday: '',
                    city: '',
                    country: '',
                    firstName: '',
                    gender: '',
                    street: '',
                    houseNumber: '',
                    nameLast: '',
                    zip: '',
                    insuranceNr: '',
                },
                key: '',
                reason: '',
                requesttype: '',
                wpName: '',
                userId: '',
                t0: '',
                otp: '',
            };

        default:
            return state;
    }
};
