import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import TabContainer from 'react-bootstrap/TabContainer';
import { FiPlusCircle } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import PastAppointments from '../../components/Appointments/PastAppointments';
import UpcomingAppointments from '../../components/Appointments/UpcomingAppointments';
import { MainHeaderButtonCol, MainHeaderCol } from '../../components/Cols/StyledCol';
import { StickyHeaderTabsDiv, StickyHeaderTitleDiv } from '../../components/Div/StyledDiv';
import { MainH1Title } from '../../components/Header/Header';
import AskNewAppointmentModal from '../../components/Modals/CancelAppointmentModal/AskNewAppointmentModal';
import RefreshButton from '../../components/RefreshButton/RefreshButton';
import { MainHeaderRow } from '../../components/Rows/StyledRow';
import StyledNavButton from '../../components/Tabs/StyledNavButton';
import { StyledTabContent, StyledTabRow } from '../../components/Tabs/StyledTab';
import MyTooltip from '../../components/Tooltip/MyTooltip';
import AlertContext from '../../context/alertContext';
import LoginContext from '../../context/loginContext';
import TerminierungContext from '../../context/terminierungContext';
import useScreenResolution from '../../hooks/useScreenResolution';
import { useSecurityItems } from '../../hooks/useSecurityItems';
import { Paths } from '../../Routes';
import { cancelAppointment } from '../../services/fhir/FHIRAppointment';
import { APPOINTMENT_POSTPONE_REASON, getIdentifierId, getTabHeight } from '../../utils/appointmentUtils';
import Mainscreen from '../Mainscreen/Mainscreen';
import { StepperTerminierungAlternateDateContent } from '../Terminierung/TerminierungAlternateDate';

export interface IDisplayAppointments {
    refreshAppointments: boolean;
    resetRefresh: any;
    checkInVariant?: boolean;
}

export interface ITermineContent {
    checkInVariant?: boolean;
    paddingLeft?: string;
    paddingRight?: string;
    handleAppointmentsCancelClick?(): void;
    handleAppointmentsFinishClick?(): void;
}

export const TermineContent = (props: ITermineContent) => {
    const navigate = useNavigate();
    const themeContext = useContext(ThemeContext);
    const [key, setKey] = useState('bevorstehende');
    const screenSize = useScreenResolution();
    const iPadProPaddinig = screenSize.width === 1024 ? '45px' : null;
    const tabHeight = getTabHeight(screenSize.width);
    const [refreshAppointments, setRefreshAppointments] = useState(false);

    const { setActiveSidebarItem } = useSecurityItems();

    const isSmallScreen = screenSize.width < 500 ? true : false;

    const [askNewAppModalShow, setAskNewAppModalShow] = React.useState(false);

    const [goToTerminierungAlternateDate, setGoToTerminierungAlternateDate] = React.useState(false);

    const { tmstate, tmdispatch } = useContext(TerminierungContext);
    const { state } = useContext(LoginContext);
    const { alertdispatch } = useContext(AlertContext);

    useEffect(() => {
        setActiveSidebarItem(Paths.TERMINE);
        setAskNewAppModalShow(false);

        return () => {
            setGoToTerminierungAlternateDate(false);
        };
    }, []);

    useEffect(() => {
        if (tmstate.cancelNewAppointmentData.showModal === true && tmstate.cancelNewAppointmentData.data) {
            setAskNewAppModalShow(true);
        } else if (
            tmstate.cancelNewAppointmentData.showModal === false &&
            tmstate.cancelNewAppointmentData.reason === APPOINTMENT_POSTPONE_REASON
        ) {
            askNewAppHandleSubmit();
        }
    }, [
        tmstate.cancelNewAppointmentData.showModal === true,
        tmstate.cancelNewAppointmentData.reason === APPOINTMENT_POSTPONE_REASON,
    ]);

    useEffect(() => {
        setRefreshAppointments(true);
    }, [state.activePatient]);

    const handleRefreshClick = () => {
        setRefreshAppointments(true);
    };

    const resetRefresh = () => {
        setRefreshAppointments(false);
    };

    const askNewAppHandleHide = () => {
        setAskNewAppModalShow(false);
        tmdispatch({
            type: 'RESETCANCELNEWAPPOINTMENTDATA',
        });
    };

    const askNewAppHandleSubmit = () => {
        setAskNewAppModalShow(false);
        tmdispatch({
            type: 'DONTSHOWCANCELNEWAPPOINTMENTDATA',
        });
        if (props.checkInVariant) {
            setGoToTerminierungAlternateDate(true);
        } else {
            navigate(Paths.TERMINIERUNG_ALTERNATE_DATE);
        }
        // setGoToTerminierungAlternateDate(true);
    };

    const cancelOtherAppointment = async () => {
        // console.log(tmstate);
        const appId = getIdentifierId(tmstate.cancelNewAppointmentData.data.contained[0].identifier);
        const reason = tmstate.cancelNewAppointmentData.reason;
        await cancelAppointment(state.sessionId, appId, reason, alertdispatch);
        // console.log(cancelRes);
        setAskNewAppModalShow(false);
        tmdispatch({
            type: 'RESETCANCELNEWAPPOINTMENTDATA',
        });
    };

    /**
     * relevant in checkInVariant when we have no history.push
     */
    const handleAppointmentCancelClick = () => {
        setGoToTerminierungAlternateDate(false);
    };

    /**
     * relevant in checkInVariant when we have no history.push
     */
    const handleAppointmentFinishClick = () => {
        setGoToTerminierungAlternateDate(false);
    };

    if (goToTerminierungAlternateDate) {
        return (
            <StepperTerminierungAlternateDateContent
                checkInVariant={props.checkInVariant}
                handleAppointmentsCancelClick={handleAppointmentCancelClick}
                handleAppointmentsFinishClick={handleAppointmentFinishClick}
            />
        );
    } else {
        return (
            <div
                style={{
                    paddingLeft: props.paddingLeft ? props.paddingLeft : '0px',
                    paddingRight: props.paddingRight ? props.paddingRight : '0px',
                }}
            >
                <StickyHeaderTitleDiv>
                    <MainHeaderRow noGutters>
                        {!isSmallScreen && <MainHeaderCol></MainHeaderCol>}
                        <MainHeaderCol>
                            <MainH1Title style={{ fontSize: isSmallScreen ? '1.5rem' : '' }}>Termine</MainH1Title>
                        </MainHeaderCol>
                        <MainHeaderButtonCol>
                            {!props.checkInVariant && (
                                <Nav.Item>
                                    <Nav.Link data-name="newappointment" onClick={() => navigate(Paths.TERMINIERUNG)}>
                                        <MyTooltip
                                            id="MakeAppointmentTooltip"
                                            text="Neuer Termin"
                                            iconDiv={true}
                                            divWidth="auto"
                                            marginLeft="auto"
                                            marginRight="0"
                                            icon={
                                                <FiPlusCircle
                                                    size={themeContext.icon.size}
                                                    color={themeContext.header.main.buttonColor}
                                                    strokeWidth={themeContext.icon.strokeWidth}
                                                />
                                            }
                                        ></MyTooltip>
                                    </Nav.Link>
                                </Nav.Item>
                            )}
                            <RefreshButton
                                buttonColor={themeContext.header.main.buttonColor}
                                iconSize={themeContext.icon.size}
                                strokeWidth={themeContext.icon.strokeWidth}
                                handleClick={handleRefreshClick}
                            />
                        </MainHeaderButtonCol>
                    </MainHeaderRow>
                </StickyHeaderTitleDiv>
                <div>
                    <TabContainer
                        defaultActiveKey="bevorstehende"
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k: any) => setKey(k)}
                    >
                        <StickyHeaderTabsDiv height={tabHeight}>
                            <MainHeaderRow noGutters>
                                <Col></Col>
                                <Col>
                                    <StyledTabRow
                                        xs={1}
                                        sm={1}
                                        md={1}
                                        lg={2}
                                        xl={2}
                                        style={{ paddingRight: iPadProPaddinig }}
                                    >
                                        <Col>
                                            <Nav.Item>
                                                <StyledNavButton
                                                    variant="horizontal"
                                                    eventKey="bevorstehende"
                                                    activekey={key}
                                                >
                                                    Aktuell
                                                </StyledNavButton>
                                            </Nav.Item>
                                        </Col>
                                        <Col>
                                            <Nav.Item>
                                                <StyledNavButton
                                                    variant="horizontal"
                                                    eventKey="vorherige"
                                                    activekey={key}
                                                >
                                                    Abgeschlossen
                                                </StyledNavButton>
                                            </Nav.Item>
                                        </Col>
                                    </StyledTabRow>
                                </Col>
                                <Col></Col>
                            </MainHeaderRow>
                        </StickyHeaderTabsDiv>
                        <div>
                            <Row>
                                <Col>
                                    <StyledTabContent>
                                        <Tab.Pane eventKey="bevorstehende">
                                            <UpcomingAppointments
                                                refreshAppointments={refreshAppointments}
                                                resetRefresh={resetRefresh}
                                                checkInVariant={props.checkInVariant}
                                            />
                                        </Tab.Pane>
                                    </StyledTabContent>
                                    <StyledTabContent>
                                        <Tab.Pane eventKey="vorherige">
                                            <PastAppointments
                                                refreshAppointments={refreshAppointments}
                                                resetRefresh={resetRefresh}
                                                checkInVariant={props.checkInVariant}
                                            />
                                        </Tab.Pane>
                                    </StyledTabContent>
                                </Col>
                            </Row>
                        </div>
                    </TabContainer>
                </div>
                <Container>
                    <AskNewAppointmentModal
                        show={askNewAppModalShow}
                        onHide={askNewAppHandleHide}
                        onRenewAppointment={askNewAppHandleSubmit}
                        onCancelOtherAppointment={cancelOtherAppointment}
                    />
                </Container>
            </div>
        );
    }
};

const Termine = () => {
    return (
        <Mainscreen>
            <Container fluid>
                <Row>
                    <Col>
                        <TermineContent />
                    </Col>
                </Row>
            </Container>
        </Mainscreen>
    );
};

export default Termine;
