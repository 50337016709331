import { createContext } from 'react';
import { IURLToCallResponse } from '../container/Splashscreen/CheckInSplashscreen';

export type InitialCheckInType = {
    urlToCallResponse: IURLToCallResponse;
    key: string;
    reason: string;
    requesttype: string;
    wpName: string;
    userId?: string;
    t0?: string;
    otp?: string;
};

export const initialCheckInState = {
    urlToCallResponse: {
        state: '',
        url: '',
        eGK: '',
        kvBarcode: '',
        birthday: '',
        city: '',
        country: '',
        firstName: '',
        gender: '',
        street: '',
        houseNumber: '',
        nameLast: '',
        zip: '',
        insuranceNr: '',
    },
    key: '',
    reason: '',
    requesttype: '',
    wpName: '',
    userId: '',
    t0: '',
    otp: '',
};

const CheckInContext = createContext<{
    checkinstate: InitialCheckInType;
    checkindispatch: React.Dispatch<any>;
}>({
    checkinstate: initialCheckInState,
    checkindispatch: () => null,
});

export default CheckInContext;
