import { useContext, useRef } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { RiLogoutBoxRLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import EncounterTitle from '../../components/Buttons/EncounterTitle';
import { IconDiv } from '../../components/Div/StyledDiv';
import ExtraLinks from '../../components/ExtraLinks/ExtraLinks';
import PatientSwitch from '../../components/ProfileSwitch/PatientSwitch';
import ProfileSwitch from '../../components/ProfileSwitch/ProfileSwitch';
import Avatar from '../../components/SidebarItems/Avatar';
import Telephone from '../../components/Telephone/Telephone';
import MyTooltip from '../../components/Tooltip/MyTooltip';
import AppPropsContext from '../../context/appPropsContext';
import LoginContext from '../../context/loginContext';
import MyThemeContext from '../../context/themeContext';
import useHandleLogout from '../../hooks/useHandleLogout';
import useIntersection from '../../hooks/useIntersection';
import useNavbar from '../../hooks/useNavbar';
import Sidebar from '../Sidebar/Sidebar';
import { StyledNavbar } from './StyledNavbar';

const NavbarMenu = () => {
    const themeContext = useContext(ThemeContext);
    const { apstate } = useContext(AppPropsContext);
    const { state } = useContext(LoginContext);
    const { themestate, themedispatch } = useContext(MyThemeContext);
    const { handleLogout } = useHandleLogout();

    const toggleButtonRef = useRef<HTMLButtonElement>(null);

    const [showProfileSwitch, showPatientSwitch] = useNavbar();

    const inViewport = useIntersection(toggleButtonRef, '0px');

    if (inViewport) {
        if (!themestate.navbarCollapsed) {
            themedispatch({
                type: 'SETNAVBARCOLLAPSED',
                navbarCollapsed: true,
            });
        }
    } else {
        if (themestate.navbarCollapsed) {
            themedispatch({
                type: 'SETNAVBARCOLLAPSED',
                navbarCollapsed: false,
            });
        }
    }

    const brandingSrc = 'data:image/jpg;base64,' + apstate.brandingBytes;

    return (
        <StyledNavbar expand="lg" fixed="top" style={{ paddingTop: '0px' }}>
            <Navbar.Brand style={{ marginTop: '15px' }}>
                <img src={brandingSrc} width="130" className="d-inline-block align-top" alt="React Bootstrap logo" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" ref={toggleButtonRef} />
            <Navbar.Collapse className="justify-content-end" style={{ alignItems: 'center' }}>
                <Nav style={{ alignItems: 'center', maxHeight: '300px' }} navbarScroll>
                    <ExtraLinks />
                    <Nav.Link>
                        <Telephone />
                    </Nav.Link>
                    {/* TODO: activate when messages are developed in Principa
                    <Nav.Link>
                        <MessageInboxIcon newMessagesNumber={1} />
                    </Nav.Link> */}
                    {showProfileSwitch && (
                        <Nav.Link>
                            <ProfileSwitch />
                        </Nav.Link>
                    )}
                    {showPatientSwitch && (
                        <Nav.Link>
                            <PatientSwitch />
                        </Nav.Link>
                    )}
                    {state.encounterId ? <EncounterTitle /> : null}
                    <Nav.Link as={Link} to={'/Profil'}>
                        <Avatar />
                    </Nav.Link>
                    <Nav.Link>
                        <IconDiv onClick={handleLogout}>
                            <MyTooltip
                                id="LogoutTooltip"
                                text="Abmelden"
                                iconDiv={true}
                                divWidth="35px"
                                marginLeft="0"
                                marginRight="auto"
                                icon={
                                    <RiLogoutBoxRLine
                                        size={themeContext.icon.size}
                                        color={themeContext.icon.default.color}
                                        style={{ marginTop: 2 }}
                                    />
                                }
                            ></MyTooltip>
                        </IconDiv>
                    </Nav.Link>
                    {themestate.navbarCollapsed && (
                        <Sidebar
                            backgroundImage={themeContext.sidebar.backgroundImage}
                            paddingTop="10px"
                            paddingLeft="10px"
                            paddingRight="10px"
                        />
                    )}
                </Nav>
            </Navbar.Collapse>
        </StyledNavbar>
    );
};

export default NavbarMenu;
