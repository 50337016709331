import { useContext, useEffect, useState } from 'react';
import * as React from 'react';
import Col from 'react-bootstrap/esm/Col';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import SigPadContext, { ISignaturePadDevice } from '../../context/sigpadContext';
import StyledButton from '../Buttons/StyledButton';
import { ParagraphFontSize, StyledParagraph } from '../StyledParagraph/StyledParagraph';

interface ISignaturePadSignotec {
    width: number;
    height: number;
    margin?: string;
    deviceData: ISignaturePadDevice;
    onClear: (canvasRef: any) => void; // callback when click on button Clear
}

const SignaturePadSignotec = (props: ISignaturePadSignotec) => {
    const { sigpaddispatch } = useContext(SigPadContext);

    const [divWidth, setDivWidth] = useState(700);
    const [divHeight, setDivHeight] = useState(300);
    const [signaturePadWidth, setSignaturePadWidth] = useState(690);
    const [signaturePadHeight, setSignaturePadHeight] = useState(240);

    const canvasSignotec = React.useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        sigpaddispatch({
            type: 'SETCANVAS',
            canvasRef: canvasSignotec,
        });
    }, []);

    useEffect(() => {
        /* explicity set width and height override all other sizes */
        if (props.width && props.width > 0) {
            setDivWidth(props.width);
            setSignaturePadWidth(props.width - 10);
        }
        if (props.height && props.height > 0) {
            setDivHeight(props.height);
            setSignaturePadHeight(props.height - 120);
        }
    }, [props.width, props.height]);

    const onSignaturePadClear = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (e) {
            e.currentTarget.blur();
        }
        props.onClear(canvasSignotec);
    };

    return (
        <div
            id="SignaturePadSignotecOuterDiv"
            style={{
                borderStyle: 'solid',
                width: divWidth,
                height: divHeight,
                padding: 0,
                margin: props.margin ? props.margin : '0',
                zIndex: 100,
            }}
        >
            <div>
                <StyledParagraph fontSize={ParagraphFontSize.Small}>
                    <b>deviceName: </b>
                    {props.deviceData.deviceName}
                    <hr></hr>
                </StyledParagraph>
            </div>
            {/* <StyledParagraph fontSize={ParagraphFontSize.Small}>
                <b>firmwareVersion: </b>
                {props.deviceData.firmwareVersion}
            </StyledParagraph>
            <StyledParagraph fontSize={ParagraphFontSize.Small}>
                <b>serialNumber: </b>
                {props.deviceData.serialNumber}
            </StyledParagraph>
            <StyledParagraph fontSize={ParagraphFontSize.Small}>
                <b>canStoreEncryptionKey: </b>
                {props.deviceData.canStoreEncryptKey}
            </StyledParagraph>
            <StyledParagraph fontSize={ParagraphFontSize.Small}>
                <b>supportsRSA: </b>
                {props.deviceData.supportsRSA}
            </StyledParagraph>
            <StyledParagraph fontSize={ParagraphFontSize.Small}>
                <b>padConnectionType: </b>
                {props.deviceData.padConnectionType}
            </StyledParagraph>
            <StyledParagraph fontSize={ParagraphFontSize.Small}>
                <b>padIndex: </b>
                {props.deviceData.padIndex}
            </StyledParagraph> */}
            <div id="canvasdiv">
                <canvas ref={canvasSignotec} height={signaturePadHeight} width={signaturePadWidth}>
                    Your browser does not support the HTML canvas tag.
                </canvas>
            </div>
            <Container>
                <Row>
                    <Col>
                        <StyledButton variant="secondary" onClick={(e) => onSignaturePadClear(e)}>
                            Löschen
                        </StyledButton>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default SignaturePadSignotec;
