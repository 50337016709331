import { createContext } from 'react';
import { IPlannedStudies } from '../services/fhir/FHIRPatientResource';
import { IParticipant } from '../utils/appointmentUtils';
import { IDepartmentReasons, IDepartments } from './loginContext';

export interface ITimeslot {
    start: Date;
    end: Date;
    supressDuration?: boolean;
}

export interface ITimeslotReplacedRoom {
    reference: string;
    display: string;
}

export interface IAvailableTimeslot {
    timeslot: ITimeslot;
    replacedRoom: ITimeslotReplacedRoom;
}

export interface IAvailableTimeslots {
    appointmentNr: number;
    availableTimeslot: IAvailableTimeslot[];
    supressDuration: boolean;
}

export interface ISelectedTimeslots {
    appointmentNr: number;
    selectedTimeslot: ITimeslot;
}

export interface ISelectedResources {
    appointmentNr: number;
    resource: any;
}

export interface ISelectedPhysician {
    lines?: string[];
    id: number;
    name: string;
    pict?: string;
}

export interface IPersonalData {
    insuranceNr: string;
    firstName: string;
    lastName: string;
    birthday: string;
    gender: string;
    email: string;
    telephone: string;
    street: string;
    housenr: string;
    city: string;
    zip: string;
    country: string;
    mobilePhone: string;
    academicTitle: string;
    eGK: string;
    kvBarcode: string;
    state: string;
    profilePhoto: string;
    plannedStudies: IPlannedStudies[];
    username: string;
    password: string;
}

export type InitialTerminierungType = {
    withLogin: boolean;
    withCheckIn: boolean;
    selectedCategory: {
        name: string;
        id: number;
        countAPKs: number;
        oneMacro: boolean;
        macroCode: string;
        macroType: string;
    };
    selectedDepartment: IDepartments;
    selectedReason: IDepartmentReasons;
    selectedInsurance: string;
    selectedPhysician: ISelectedPhysician;
    selectedAppointmentType: string;
    availableTimeslots: IAvailableTimeslots[];
    // availableTimeslots_2: IAvailableTimeslot[];
    selectedTimeslots: ISelectedTimeslots[];
    timeslotClicked: boolean;
    // selectedTimeslot_2: ITimeslot;
    selectedResources: ISelectedResources[];
    // selectedResource_2: any;
    workOnAppointmentNr: number;
    personalData: IPersonalData;
    personalDataPhysPat: IPersonalData;
    remark: string;
    earlierAppointmentWanted: boolean;
    enrollData: {
        ambId: string;
        enrollCode: string;
        maId: string;
        patNameFirst: string;
        patNameLast: string;
        scheduleTime: string;
        fhir: {
            participant: IParticipant[];
            resourceType: string;
            start: Date;
            end: Date;
            status: string;
            serviceType: any[];
        };
        waitNumber?: string;
        requestFromDirectLink: boolean | undefined;
        remark?: string;
        kvBarcode?: string;
        isToday?: boolean | undefined;
    };
    changePersonalData: boolean;
    canceledAppointment: boolean;
    cancelNewAppointmentData: {
        showModal: boolean;
        data: any;
        reason: string;
    };
    renewAppointment: boolean;
};

export const initialTerminierungState = {
    withLogin: false,
    withCheckIn: false,
    selectedCategory: {
        name: '',
        id: 0,
        countAPKs: 1,
        oneMacro: false,
        macroCode: '',
        macroType: '',
    },
    selectedDepartment: {
        name: '',
        reasons: [],
    },
    selectedReason: {
        makroCode: '',
        makroId: 0,
        makroName: '',
        pFilter: '',
        aFilter: '',
        reasonCode: '',
        reasonId: 0,
        reasonName: '',
    },
    selectedPhysician: {
        lines: [],
        id: 0,
        name: '',
        pict: '',
    },
    selectedInsurance: '',
    selectedAppointmentType: '',
    availableTimeslots: [],
    // availableTimeslots_2: [],
    selectedTimeslots: [],
    timeslotClicked: false,
    // selectedTimeslot_2: {
    //     start: new Date(0),
    //     end: new Date(0),
    // },
    selectedResources: [],
    // selectedResource_2: '',
    workOnAppointmentNr: 1,
    personalData: {
        insuranceNr: '',
        firstName: '',
        lastName: '',
        birthday: '',
        gender: '',
        email: '',
        telephone: '',
        street: '',
        housenr: '',
        city: '',
        zip: '',
        country: '',
        mobilePhone: '',
        academicTitle: '',
        eGK: '',
        kvBarcode: '',
        state: '',
        profilePhoto: '',
        plannedStudies: [],
        username: '',
        password: '',
    },
    personalDataPhysPat: {
        insuranceNr: '',
        firstName: '',
        lastName: '',
        birthday: '',
        gender: '',
        email: '',
        telephone: '',
        street: '',
        housenr: '',
        city: '',
        zip: '',
        country: '',
        eGK: '',
        mobilePhone: '',
        academicTitle: '',
        kvBarcode: '',
        state: '',
        profilePhoto: '',
        plannedStudies: [],
        username: '',
        password: '',
    },
    remark: '',
    earlierAppointmentWanted: false,
    enrollData: {
        ambId: '',
        enrollCode: '',
        maId: '',
        patNameFirst: '',
        patNameLast: '',
        scheduleTime: '',
        fhir: {
            participant: [],
            resourceType: '',
            start: new Date(0),
            end: new Date(0),
            status: '',
            serviceType: [],
        },
        waitNumber: '',
        requestFromDirectLink: undefined,
        remark: '',
        isToday: undefined,
    },
    changePersonalData: false,
    canceledAppointment: false,
    cancelNewAppointmentData: {
        showModal: false,
        data: '',
        reason: '',
    },
    renewAppointment: false,
};

const TerminierungContext = createContext<{
    tmstate: InitialTerminierungType;
    tmdispatch: React.Dispatch<any>;
}>({
    tmstate: initialTerminierungState,
    tmdispatch: () => null,
});

export default TerminierungContext;
