import * as React from 'react';
import { useContext, useRef, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import { IoIosArrowDropdownCircle, IoIosArrowDropupCircle } from 'react-icons/io';
import { ThemeContext } from 'styled-components';
import LoginContext from '../../../context/loginContext';
import StyledButton from '../../Buttons/StyledButton';
import StyledCol from '../../Cols/StyledCol';
import { StyledParagraph } from '../../StyledParagraph/StyledParagraph';
import { StyledModal } from '../StyledModal/StyledModal';

interface IConsigModal {
    show: boolean;
    onHide: () => void;
    onSubmit: () => void;
    onDeny: () => void;
    checkbox1: () => void;
    checkbox2: () => void;
    checkConsignation1: boolean;
    checkConsignation2: boolean;
    sessionId: string;
    userId: string;
    notGiven: boolean;
}

const ConsignationModal = (props: IConsigModal) => {
    const themeContext = useContext(ThemeContext);

    const [activeId, setActiveId] = useState('0');
    const { state } = useContext(LoginContext);
    const [termsHTMLBottom, setTermsHTMLBottom] = useState(false);
    const [privacyHTMLBottom, setPrivacyHTMLBottom] = useState(false);

    const submitButtonRef = useRef<HTMLButtonElement>(null);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        submitButtonRef?.current?.setAttribute('disabled', 'disabled');

        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        {
            props.onSubmit();
        }
    };

    function toggleActive(id: string) {
        if (activeId !== id) {
            setActiveId(id);
        } else {
            setActiveId('');
        }
    }

    const getIcon = (id: string) => {
        return activeId === id ? <IoIosArrowDropupCircle size={30} /> : <IoIosArrowDropdownCircle size={30} />;
    };

    const termsHTMLScrollHandling = (e: any) => {
        let bottom = false;
        const scrollHeight = e.target.scrollHeight;
        const scrollTop = e.target.scrollTop;
        const clientHeight = e.target.clientHeight;

        // console.log('scrollHeight: ', scrollHeight);
        // console.log('scrollTop: ', scrollTop);
        // console.log('clientHeight: ', clientHeight);

        const diff = scrollHeight - scrollTop;
        // console.log('diff: ', diff);

        if (diff - 1 <= clientHeight) {
            bottom = true;
        }

        if (bottom) {
            setTermsHTMLBottom(true);
        }
    };

    const privacyHTMLScrollHandling = (e: any) => {
        let bottom = false;
        const scrollHeight = e.target.scrollHeight;
        const scrollTop = e.target.scrollTop;
        const clientHeight = e.target.clientHeight;

        // console.log('scrollHeight: ', scrollHeight);
        // console.log('scrollTop: ', scrollTop);
        // console.log('clientHeight: ', clientHeight);

        const diff = scrollHeight - scrollTop;
        // console.log('diff: ', diff);

        if (diff - 1 <= clientHeight) {
            bottom = true;
        }

        if (bottom) {
            setPrivacyHTMLBottom(true);
        }
    };

    const scrollInfoMessage = () => {
        if (!termsHTMLBottom || !privacyHTMLBottom) {
            return (
                <>
                    <Row>
                        <StyledParagraph color={themeContext.font.alertColor}>
                            Lesen Sie die Einwilligungs- und Datenschutzerklärung vollständig bis zum Schluss durch. Am
                            Textende haben Sie die Möglichkeit, die Zustimmung zu aktivieren.
                        </StyledParagraph>
                    </Row>
                </>
            );
        }

        return null;
    };

    return (
        <StyledModal show={props.show} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">Einwilligungs- und Datenschutzerklärung</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.notGiven ? (
                    <div>
                        <Alert variant="danger">
                            <p>
                                Beim letzten Anmeldungsversuch wurde die Einwilligungs- und Datenschutzerklärung
                                abgelehnt.
                            </p>
                            <p>
                                Um sich anmelden zu können, muss der Einwilligungs- und Datenschutzerklärung zugestimmt
                                werden.
                            </p>
                        </Alert>
                    </div>
                ) : (
                    <></>
                )}
                <Accordion defaultActiveKey={activeId}>
                    <Card>
                        <Card.Header style={{ backgroundColor: themeContext.mainColor, textAlign: 'center' }}>
                            <Accordion.Toggle
                                onClick={() => toggleActive('0')}
                                as={Button}
                                variant="link"
                                eventKey="0"
                                style={{ color: 'white' }}
                            >
                                <div style={{ margin: 'auto', width: '100%' }}>
                                    Einwilligungserklärung {getIcon('0')}
                                </div>
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body
                                id="termsHTMLCardBody"
                                style={{ height: '400px', overflow: 'auto' }}
                                onScroll={termsHTMLScrollHandling}
                            >
                                {/*
                                <iframe
                                    src={'data:text/html;charset=utf-8,' + state.termsHTML}
                                    width="100%"
                                    height="100%"
                                    name="iFrame"
                                    title="Einwilligungserklärung"
                                ></iframe>
                                */}
                                <div id="termsHTMLDiv" dangerouslySetInnerHTML={{ __html: state.termsHTML }} />
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header style={{ backgroundColor: themeContext.mainColor, textAlign: 'center' }}>
                            <Accordion.Toggle
                                onClick={() => toggleActive('1')}
                                as={Button}
                                variant="link"
                                eventKey="1"
                                style={{ color: 'white' }}
                            >
                                Datenschutzerklärung {getIcon('1')}
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="1">
                            <Card.Body
                                id="privacyHTMLCardBody"
                                style={{ height: '400px', overflow: 'auto' }}
                                onScroll={privacyHTMLScrollHandling}
                            >
                                {/*
                                <iframe
                                    src={'data:text/html;charset=utf-8,' + state.privacyHTML}
                                    width="100%"
                                    height="100%"
                                    name="iFrame"
                                    title="Datenschutzerklärung"
                                ></iframe>
                                */}
                                <div id="privacyHTMLDiv" dangerouslySetInnerHTML={{ __html: state.privacyHTML }} />
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
                <Row style={{ paddingTop: 20 }}>
                    <Col>
                        <Form noValidate onSubmit={handleSubmit}>
                            {scrollInfoMessage()}
                            <Row>
                                <Col>
                                    <div key="checkbox1" className="mb-3">
                                        <Form.Check type="checkbox" id="check-1">
                                            <Form.Check.Input
                                                type="checkbox"
                                                disabled={!termsHTMLBottom || !privacyHTMLBottom}
                                                isValid
                                                checked={props.checkConsignation1}
                                                onChange={props.checkbox1}
                                            />
                                            <Form.Check.Label>
                                                <div style={{ color: 'black' }}>
                                                    <b>
                                                        Ich gebe vorstehende Einwilligungserklärung freiwillig ab und
                                                        erkläre mich ausdrücklich mit dem Inhalt einverstanden.
                                                    </b>
                                                </div>
                                            </Form.Check.Label>
                                        </Form.Check>
                                    </div>
                                    <div key="checkbox2" className="mb-3">
                                        <Form.Check type="checkbox" id="check-2">
                                            <Form.Check.Input
                                                type="checkbox"
                                                disabled={!termsHTMLBottom || !privacyHTMLBottom}
                                                isValid
                                                checked={props.checkConsignation2}
                                                onChange={props.checkbox2}
                                            />
                                            <Form.Check.Label>
                                                <div style={{ color: 'black' }}>
                                                    <b>
                                                        Ich habe die Datenschutzerklärung zur Kenntnis genommen und
                                                        stimme dieser zu.
                                                    </b>
                                                </div>
                                            </Form.Check.Label>
                                        </Form.Check>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <StyledCol textAlign="left">
                                    <StyledButton variant="secondary" onClick={props.onHide}>
                                        Abbrechen
                                    </StyledButton>
                                </StyledCol>
                                <div style={{ float: 'left' }}>
                                    <Col>
                                        <StyledButton variant="primary" onClick={props.onDeny}>
                                            Ablehnen
                                        </StyledButton>
                                    </Col>
                                </div>
                                <div style={{ float: 'right' }}>
                                    <Col>
                                        <fieldset disabled={!props.checkConsignation1 || !props.checkConsignation2}>
                                            <StyledButton ref={submitButtonRef} type="submit">
                                                Ich stimme zu
                                            </StyledButton>
                                        </fieldset>
                                    </Col>
                                </div>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Modal.Body>
        </StyledModal>
    );
};

export default ConsignationModal;
