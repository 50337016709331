import FormLabel from 'react-bootstrap/FormLabel';
import styled from 'styled-components';

interface IForm {
    marginBottom?: string;
    color?: string;
}

export const StyledFormLabel = styled(FormLabel)<IForm>`
    margin-bottom: ${(prop) => (prop.marginBottom ? prop.marginBottom : '1rem')};
`;

export const StyledFormLabelForInput = styled(FormLabel)<IForm>`
    margin-bottom: 0.3rem;
    font-size: 0.85rem;
    color: ${(prop) => (prop.Color ? prop.Color : 'rgb(116, 118, 130)')};
`;
