import { useContext } from 'react';
import { Container } from 'react-bootstrap';
import Card from 'react-bootstrap/esm/Card';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import { Paths } from '../../Routes';
import useScreenResolution from '../../hooks/useScreenResolution';
import StyledButton from '../Buttons/StyledButton';
import { StyledMainCardTitle, StyledProfileCard } from '../Cards/StyledCard';
import { LeftDiv, RightDiv, VerticalCenterDiv } from '../Div/StyledDiv';
const EnrollCodeBox = () => {
    const navigate = useNavigate();
    const themeContext = useContext(ThemeContext);
    const screenSize = useScreenResolution();

    const getMarginRightLeft = () => {
        if (screenSize.width > 1700) {
            return '50px';
        } else if (screenSize.width > 1300) {
            return '20px';
        } else {
            return '10px';
        }
    };

    return (
        <Row style={{ height: '100%' }}>
            <Col style={{ display: 'flex' }}>
                <StyledProfileCard>
                    <Card.Body>
                        <StyledMainCardTitle>Ihre Terminplanung ohne Account</StyledMainCardTitle>
                        <Container style={{ marginTop: '100px', textAlign: 'center' }}>
                            <Row
                                style={{
                                    display: 'flex',
                                    textAlign: 'center',
                                    marginLeft: getMarginRightLeft(),
                                    marginRight: getMarginRightLeft(),
                                }}
                            >
                                <Col>
                                    <LeftDiv>
                                        <FaRegCalendarAlt size={68} color={themeContext.icon.passive.color} />
                                    </LeftDiv>
                                </Col>
                                <Col>
                                    <RightDiv>
                                        <VerticalCenterDiv>
                                            <StyledButton
                                                variant="primary"
                                                onClick={() =>
                                                    navigate(
                                                        Paths.NEUER_ACCOUNT_WL + '?reason=ANMELDKARD&type=NewAccount',
                                                    )
                                                }
                                            >
                                                Neuen Termin anfordern
                                            </StyledButton>
                                        </VerticalCenterDiv>
                                    </RightDiv>
                                </Col>
                            </Row>
                        </Container>
                    </Card.Body>
                </StyledProfileCard>
            </Col>
        </Row>
    );
    /* ALTE VARIANTE 
    return (
        <Row style={{ height: '100%' }}>
            <Col style={{ display: 'flex' }}>
                <StyledProfileCard>
                    <Card.Body>
                        <StyledMainCardTitle>Sie haben einen Termincode?</StyledMainCardTitle>
                        <Row style={{ marginTop: '75px', display: 'flex', textAlign: 'center' }}>
                            <Col style={{ marginTop: '0px', textAlign: 'left' }}>
                                <EnrollCodeInput numberOfInputFields={12} />
                            </Col>
                            <Col style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                <FaRegCalendarAlt size={68} color={themeContext.icon.passive.color} />
                            </Col>
                        </Row>
                    </Card.Body>
                    <Card.Footer>
                        <div style={{ float: 'right' }}>
                            <StyledButton variant="primary" onClick={() => navigate(Paths.TERMINIERUNG_WL)}>
                                Neuen Termin anfordern
                            </StyledButton>
                        </div>
                    </Card.Footer>
                </StyledProfileCard>
            </Col>
        </Row>
    );
    */
};

export default EnrollCodeBox;
