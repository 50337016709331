import { appointmentStatus } from '../globals/global';

export const getGenderEN = (gender: string) => {
    switch (gender) {
        case 'Männlich':
            return 'male';
        case 'Weiblich':
            return 'female';
        case 'Divers':
            return 'divers';
        case 'Unbekannt':
            return 'unknown';
        case '':
            return 'unknown';
        default:
            return gender;
    }
};

export const getGenderDE = (gender: string) => {
    switch (gender) {
        case 'male':
            return 'Männlich';
        case 'female':
            return 'Weiblich';
        case 'divers':
            return 'Divers';
        case 'unknown':
            return 'Unbekannt';
        case '':
            return '';
        default:
            return gender;
    }
};

export const getCountryEN = (country: string) => {
    switch (country) {
        case 'Deutschland':
            return 'Germany';
        case 'Österreich':
            return 'Austria';
        case 'Schweiz':
            return 'Switzerland';
        default:
            return '';
    }
};

export const getCountryDE = (country: string) => {
    switch (country) {
        case 'Germany':
            return 'Deutschland';
        case 'Austria':
            return 'Österreich';
        case 'Switzerland':
            return 'Schweiz';
        default:
            return '';
    }
};

export const getCountryLongName = (countryShortName: string) => {
    switch (countryShortName) {
        case 'D':
            return 'Deutschland';
        case 'DE':
            return 'Deutschland';
        case 'A':
            return 'Österreich';
        case 'AT':
            return 'Österreich';
        case 'CH':
            return 'Schweiz';
        default:
            return '';
    }
};

export const translateStatus = (status: string) => {
    switch (status) {
        case 'cancelled':
            return 'abgesagt';
        case 'pending':
            return 'geplant o. Uhrzeit';
        case 'booked':
            return 'geplant';
        case 'arrived':
            return 'aktuell';
        case 'fulfilled':
            return 'abgeschlossen';
        case appointmentStatus.enteredInError:
            return 'unvollständig';
        default:
            break;
    }
};

/**
 * format date from yyyy-MM-dd or yyyy.MM.dd to dd.MM.yyyy
 *
 * @param date
 *      the date as string
 * @returns
 *      the date with new format
 */
export const getGermanDateFormatFromString = (date: string) => {
    const day = date.slice(8);
    const month = date.slice(5, -3);
    const year = date.slice(0, -6);
    return day + '.' + month + '.' + year;
};

export const pattern =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z0-9]{2,}))$/;

export const isEmailValid = (email: string) => {
    return pattern.test(String(email).toLowerCase());
};

export const getBasicAuthBase64Encoded = (basicauth: string) => {
    const base64encodedStr = btoa(basicauth);
    const basicAuthBase64 = 'Basic ' + base64encodedStr;
    return basicAuthBase64;
};

export const getDataFromAppProperties = (propRes: any) => {
    const appProps = {
        AppointmentSummary: {
            behandler:
                propRes.AppointmentSummary?.behandler !== undefined ? propRes.AppointmentSummary?.behandler : true,
            fach: propRes.AppointmentSummary?.fach !== undefined ? propRes.AppointmentSummary?.fach : true,
            pflege: propRes.AppointmentSummary?.pflege !== undefined ? propRes.AppointmentSummary?.pflege : true,
            raum: propRes.AppointmentSummary?.raum !== undefined ? propRes.AppointmentSummary?.raum : true,
        },
        RegistrationMandFields: {
            birthDate: propRes.RegistrationMandFields?.birthDate ? propRes.RegistrationMandFields.birthDate : null,
            city: propRes.RegistrationMandFields?.city ? propRes.RegistrationMandFields.city : null,
            country: propRes.RegistrationMandFields?.country ? propRes.RegistrationMandFields.country : null,
            email: propRes.RegistrationMandFields?.email ? propRes.RegistrationMandFields.email : null,
            firstName: propRes.RegistrationMandFields?.firstName ? propRes.RegistrationMandFields.firstName : null,
            gender: propRes.RegistrationMandFields?.gender ? propRes.RegistrationMandFields.gender : null,
            lastName: propRes.RegistrationMandFields?.lastName ? propRes.RegistrationMandFields.lastName : null,
            phone: propRes.RegistrationMandFields?.phone ? propRes.RegistrationMandFields.phone : null,
            street: propRes.RegistrationMandFields?.street ? propRes.RegistrationMandFields.street : null,
            zip: propRes.RegistrationMandFields?.zip ? propRes.RegistrationMandFields.zip : null,
            insuranceNr: propRes.RegistrationMandFields?.insuranceNr
                ? propRes.RegistrationMandFields.insuranceNr
                : null,
            mobilePhone: propRes.RegistrationMandFields?.mobilePhone
                ? propRes.RegistrationMandFields.mobilePhone
                : null,
            academicTitle: propRes.RegistrationMandFields?.academicTitle
                ? propRes.RegistrationMandFields.academicTitle
                : null,
        },
        RegistrationVisibleFields: {
            birthDate: propRes.RegistrationVisibleFields?.birthDate
                ? propRes.RegistrationVisibleFields.birthDate
                : null,
            city: propRes.RegistrationVisibleFields?.city ? propRes.RegistrationVisibleFields.city : null,
            country: propRes.RegistrationVisibleFields?.country ? propRes.RegistrationVisibleFields.country : null,
            email: propRes.RegistrationVisibleFields?.email ? propRes.RegistrationVisibleFields.email : null,
            firstName: propRes.RegistrationVisibleFields?.firstName
                ? propRes.RegistrationVisibleFields.firstName
                : null,
            gender: propRes.RegistrationVisibleFields?.gender ? propRes.RegistrationVisibleFields.gender : null,
            lastName: propRes.RegistrationVisibleFields?.lastName ? propRes.RegistrationVisibleFields.lastName : null,
            phone: propRes.RegistrationVisibleFields?.phone ? propRes.RegistrationVisibleFields.phone : null,
            street: propRes.RegistrationVisibleFields?.street ? propRes.RegistrationVisibleFields.street : null,
            zip: propRes.RegistrationVisibleFields?.zip ? propRes.RegistrationVisibleFields.zip : null,
            insuranceNr: propRes.RegistrationVisibleFields?.insuranceNr
                ? propRes.RegistrationVisibleFields.insuranceNr
                : null,
            mobilePhone: propRes.RegistrationVisibleFields?.mobilePhone
                ? propRes.RegistrationVisibleFields.mobilePhone
                : null,
            academicTitle: propRes.RegistrationVisibleFields?.academicTitle
                ? propRes.RegistrationVisibleFields.academicTitle
                : null,
        },
        RegistrationReadOnlyFields: {
            birthDate: propRes.RegistrationReadOnlyFields?.birthDate
                ? propRes.RegistrationReadOnlyFields.birthDate
                : null,
            city: propRes.RegistrationReadOnlyFields?.city ? propRes.RegistrationReadOnlyFields.city : null,
            country: propRes.RegistrationReadOnlyFields?.country ? propRes.RegistrationReadOnlyFields.country : null,
            email: propRes.RegistrationReadOnlyFields?.email ? propRes.RegistrationReadOnlyFields.email : null,
            firstName: propRes.RegistrationReadOnlyFields?.firstName
                ? propRes.RegistrationReadOnlyFields.firstName
                : null,
            gender: propRes.RegistrationReadOnlyFields?.gender ? propRes.RegistrationReadOnlyFields.gender : null,
            lastName: propRes.RegistrationReadOnlyFields?.lastName ? propRes.RegistrationReadOnlyFields.lastName : null,
            phone: propRes.RegistrationReadOnlyFields?.phone ? propRes.RegistrationReadOnlyFields.phone : null,
            street: propRes.RegistrationReadOnlyFields?.street ? propRes.RegistrationReadOnlyFields.street : null,
            zip: propRes.RegistrationReadOnlyFields?.zip ? propRes.RegistrationReadOnlyFields.zip : null,
            insuranceNr: propRes.RegistrationReadOnlyFields?.insuranceNr
                ? propRes.RegistrationReadOnlyFields.insuranceNr
                : null,
            mobilePhone: propRes.RegistrationReadOnlyFields?.mobilePhone
                ? propRes.RegistrationReadOnlyFields.mobilePhone
                : null,
            academicTitle: propRes.RegistrationReadOnlyFields?.academicTitle
                ? propRes.RegistrationReadOnlyFields.academicTitle
                : null,
        },
        allowAccountAfterAppointment: propRes.allowAccountAfterAppointment,
        allowAccountCreateDirect: propRes.allowAccountCreateDirect,
        allowAppointments: propRes.allowAppointments,
        allowAppointmentsWithoutLogin: propRes.allowAppointmentsWithoutLogin,
        allowDirectAppointments: propRes.allowDirectAppointments,
        allowDirectAppointmentsWithoutLogin: propRes.allowDirectAppointmentsWithoutLogin,
        allowLogin: propRes.allowLogin,
        allowVideo: propRes.allowVideo,
        allowVideoWithoutLogin: propRes.allowVideoWithoutLogin,
        allowAccountInAnmeldeworkflow: propRes.allowAccountInAnmeldeworkflow,
        brandingBytes: propRes.brandingBytes,
        brandigType: propRes.brandigType,
        extraLinks: propRes.extraLinks,
        customTexts: propRes.customTexts,
        showMobileQR: propRes.showMobileQR,
        jitsiDomain: propRes.jitsiDomain,
        jitsiOptions: propRes.jitsiOptions,
        impressum: propRes.impressum,
    };

    return appProps;
};

export const setDataFromAppProperties = (propRes: any, apdispatch: any) => {
    const appProps = getDataFromAppProperties(propRes);

    apdispatch({
        type: 'SETAPPPROPS',
        ...appProps,
    });
};

export const getFullOTPAuthorization = (prefix: string, otp: string, userId: string | number, t0: string) => {
    let _userId = userId;
    if (typeof userId === 'number') {
        _userId = userId.toString();
    }

    let _prefix = '';
    if (prefix.length > 0) {
        _prefix = prefix + ' ';
    }

    const otpAuth = _prefix + otp + '&userId=' + _userId + '&t0=' + t0;
    return otpAuth;
};
